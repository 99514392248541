import firebase from 'firebase/app';

import { Profile } from './Profile';

export interface Request extends Profile {
  id: string;
  requestMessage: string;
  createdAt: firebase.firestore.FieldValue;
}

export class Request extends Profile {
  constructor(object?: Partial<Request>) {
    super(object);

    if (object?.id) {
      this.id = object.id;
    }

    this.requestMessage = object?.requestMessage || '';
    this.createdAt =
      object?.createdAt || firebase.firestore.FieldValue.serverTimestamp();
  }
}
