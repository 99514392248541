import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AppRoute, RoutePath } from 'modules/navigation';

interface OwnProps {
  isLoggedIn: boolean;
  pathname: string;
}

type Props = OwnProps & AppRoute;

export const ACLRoute: React.FC<Props> = ({
  isLoggedIn,
  accessibilityLevel,
  pathname,
  ...rest
}) => {
  const shouldRedirectToDashboard = () =>
    isLoggedIn &&
    (pathname === RoutePath.Unauthorized ||
      accessibilityLevel === 'anonymous' ||
      pathname === '/admin');

  // Redirect user to permission error page if anonymous user tries to access authenticated route
  if (!isLoggedIn && accessibilityLevel === 'authenticated') {
    return <Redirect to={RoutePath.Unauthorized} />;
  }

  // Redirect logged user to his dashboard if he is on unauthorized or anonymous route
  if (shouldRedirectToDashboard()) {
    return <Redirect to={RoutePath.Dashboard} />;
  }

  return <Route {...rest} />;
};
