import { css } from '@emotion/core';

import { colors, unit, fontFamily, globalRadial } from './vars.styles';
import { typescale } from './typescale.styles';
import { color } from './mixins.styles';
import { mqFrom } from './responsive.styles';
import { wrappers } from './wrapper.styles';

const heroBg = css`
  background: ${colors.tertiary};
`;

const heroWrapper = css`
  ${mqFrom.Xxlrg} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
`;

const heroImg = css`
  width: 100%;
  padding: ${unit * 11}px 0;
  display: flex;
  justify-content: center;

  svg {
    max-width: 654px;
  }
`;

const heroText = css`
  h2 {
    ${typescale.xlarge}
    color: ${color(colors.text, 0.9)};
    font-family: ${fontFamily.serif};
    margin-bottom: ${unit * 4}px;
  }

  p {
    color: ${color(colors.text, 0.9)};
    opacity: 0.7;
    margin-bottom: ${unit * 8}px;
    ${typescale.base}

    ${mqFrom.Xxlrg} {
      width: 376px;
    }
  }

  a {
    margin-bottom: ${unit * 10 + 2}px;
  }
`;

const fundShadow = css`
  box-shadow: 0px 0 50px 0 rgba(0, 0, 0, 0.1);
`;

const fundWrapper = css`
  max-height: 118px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  ${mqFrom.Sml} {
    padding-bottom: 0;
    margin-bottom: 20px;
  }

  p,
  a {
    display: none;
  }

  ${mqFrom.Xlrg} {
    justify-content: space-between;

    p {
      display: block;
    }

    a {
      display: inline-block;
    }
  }
`;

const fundImg = css`
  flex: 1;
  display: flex;
  justify-content: center;
  background: white;

  img {
    image-rendering: -webkit-optimize-contrast;
    width: 90%;
    max-width: 440px;
    height: 100%;
    transform: scale3d(1.2, 1.2, 1);

    ${mqFrom.Sml} {
      width: 100%;
    }
  }
`;

const fundText = css`
  text-align: left;
  background-color: ${colors.bg};

  ${mqFrom.Sml} {
    text-align: center;
  }

  ${mqFrom.Xlrg} {
    display: none;
  }
`;

const fundTextAnchor = css`
  transition: color 0.15s ease-out;
  color: ${color(colors.primary, 0.9)};

  &:hover {
    color: ${color(colors.primary, 0.6)};
  }
`;

const fundResponsibility = css`
  ${typescale.tiny}

  text-align: center;
  margin-top: 8px;
`;

const supplyWrapper = css`
  background-color: ${colors.bg};
  padding-bottom: 0;
`;

const supplyTitle = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${unit * 9}px;
  margin-top: ${unit * 18}px;

  h3 {
    ${typescale.large}
    font-family: ${fontFamily.serif};
    color: ${color(colors.text, 0.9)};
  }
`;

const supplyLink = css`
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${color(colors.primary, 0.9)};
  transition: color 0.15s ease-out;

  &:hover {
    color: ${color(colors.primary, 0.6)};
  }
`;

const supplyLinkTop = css`
  ${supplyLink}
  display: none;

  ${mqFrom.Xlrg} {
    display: inline-block;
  }
`;

const supplyLinkBottom = css`
  ${supplyLink}

  ${mqFrom.Xlrg} {
    display: none;
  }
`;

const supplyUl = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, minmax(100px, 160px));
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin: 0;
  padding: 0;
  margin-bottom: ${unit * 5}px;

  ${mqFrom.Sml} {
    grid-template-rows: repeat(2, minmax(100px, 200px));
  }

  ${mqFrom.Med} {
    grid-template-rows: repeat(2, minmax(100px, 300px));
  }

  a {
    width: 100%;
    height: 100%;
  }

  ${mqFrom.Xlrg} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: minmax(100px, 250px);
  }
`;

const supplyLi = css`
  position: relative;
  height: 100%;
  width: 100%;

  img, div {
    width: 100%;
    height: 73%;
    border-radius: ${globalRadial * 2 + 2}px;
    object-fit: cover;
    margin-bottom: ${unit * 3}px;
    background: ${colors.tertiary};

    ${mqFrom.Med} {
      height: 80%;
    }

    ${mqFrom.Xlrg} {
      height: 85%;
    }
  }

  p {
    ${typescale.base}
    font-family: ${fontFamily.serif};
    color: ${color(colors.text, 0.9)};
    opacity: 0.8;
  }

  span {
    transition: transform 0.15s ease-out;
  }

  &:hover {
    span {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
`;

const supplySkeleton = css`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span:first-of-type {
    height: 73%;
    border-radius: ${globalRadial * 2 + 2}px;
    margin-bottom: ${unit * 3}px;

    ${mqFrom.Med} {
      height: 80%;
    }

    ${mqFrom.Xlrg} {
      height: 85%;
    }
  }
`;

const newsBg = css`
  padding-bottom: ${unit * 8}px;

  ${mqFrom.Lrg} {
    padding-bottom: 0;
  }
`;

const newsLinkTop = css`
  ${supplyLink}

  display: none;

  ${mqFrom.Lrg} {
    display: inline-block;
  }
`;

const newsLinkBottom = css`
  ${supplyLink}

  ${mqFrom.Lrg} {
    display: none;
  }
`;

const newsTitle = css`
  ${supplyTitle}
`;

const newsHeroWrapper = css`
  position: relative;

  ${mqFrom.Lrg} {
    ${wrappers.normal}
    height: 480px;
    margin-bottom: ${unit * 25}px;
  }

  & > span {
    width: 100%;
    height: 90%;
    border-radius: 10px 10px 0px 0px;

    & > span {
      height: 300px;
    }

    ${mqFrom.Lrg} {
      width: 50%;
      height: 90%;
      position: absolute;
      top: 50%;
      border-radius: 10px;
      left: 0;
      z-index: 2;
      transform: translateY(-50%);
      margin-left: ${unit * 8}px;

      & > span {
        height: 100%;
      }
    }
  }
`;

const newsHeroImg = css`
  ${mqFrom.Lrg} {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
  }

  div,
  img {
    width: 100%;
    height: auto;
    min-height: 284px;
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
    background: ${colors.tertiary};

    ${mqFrom.Lrg} {
      width: 50%;
      height: auto;
      max-height: 388px;
      position: absolute;
      top: 50%;
      border-radius: 10px;
      left: 0;
      z-index: 2;
      transform: translateY(-50%);
      margin-left: ${unit * 8}px;
    }
  }
`;

const newsHeroInfo = css`
  background: ${colors.secondary};
  padding: ${unit * 7}px ${unit * 5}px ${unit * 25}px ${unit * 5}px;
  /* margin-top: -4px; */

  ${mqFrom.Lrg} {
    padding: ${unit * 20}px ${unit * 25}px ${unit * 25}px ${unit * 50}px;
    position: absolute;
    border-radius: 10px;
    right: 0;
    height: 100%;
    width: 56%;
    margin-right: ${unit * 8}px;
  }
`;

const newsItemDate = css`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: ${unit * 3}px;
  color: ${color(colors.text, 0.9)};
  opacity: 0.6;
`;

const newsItemTitle = css`
  ${typescale.medium}
  font-family: ${fontFamily.serif};
  margin-bottom: ${unit * 5}px;
  color: ${color(colors.text, 0.9)};
`;

const newsItemDescription = css`
  font-size: 14px;
  line-height: 20px;
  max-height: 168px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${color(colors.text, 0.9)};
  opacity: 0.7;
  margin-bottom: ${unit * 6}px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const newsCardsWrapper = css`
  ${wrappers.threeItemsGrid}
  margin-top: -60px;
  z-index: 5;
  position: relative;

  ${mqFrom.Med} {
    grid-template-columns: repeat(3, 1fr);
  }

  a {
    width: 100%;
  }
`;

const newsCard = css`
  margin-bottom: ${unit * 7}px;
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;
    border-radius: ${globalRadial * 2 + 2}px;
    object-fit: cover;
    margin-bottom: ${unit * 3}px;
    background: ${colors.tertiary};
  }

  & > span {
    height: 212px;
  }

  ${mqFrom.Med} {
    img {
      height: 212px;
    }
  }
`;

const newsCardTitle = css`
  ${newsItemTitle}

  margin-bottom: ${unit * 2}px;
`;

const newsCardDate = css`
  ${newsItemDate}

  margin-bottom: ${unit * 7}px;
`;

const announcementBg = css`
  background: ${colors.tertiary};

  ${mqFrom.Med} {
    margin-top: 200px;
  }
`;

const announcementWrapper = css`
  ${wrappers.normal}
  background: ${colors.tertiary};
  padding-top: ${unit * 8}px;
  padding-bottom: ${unit * 8}px;

  ${mqFrom.Med} {
    ${wrappers.narrow}
    padding-top: ${unit * 21}px;
    position: relative;
    top: -176px;
    border-radius: 20px;
  }
`;

const announcementTitle = css`
  ${supplyTitle}

  margin-bottom: ${unit * 13}px;
  margin-top: 0;
`;

const announcementCardsWrapper = css`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  z-index: 5;
  margin-top: 0;
  padding: 0;

  a,
  & > div {
    width: 100%;
  }

  ${mqFrom.Med} {
    justify-content: space-between;

    a,
    & > div {
      width: 376px;
      width: 46%;
    }
  }
`;

const announcementCard = css`
  ${newsCard}
`;

const announcementLinkTop = css`
  ${supplyLink}
  display: none;

  ${mqFrom.Med} {
    display: inline-block;
  }
`;

const announcementLinkBottom = css`
  ${supplyLink}

  ${mqFrom.Med} {
    display: none;
  }
`;

const announcementCardDate = css`
  ${newsCardDate}
  ${typescale.base}
  opacity: 0.7;
`;

const aboutBg = css`
  background-color: #fee0a6;

  ${mqFrom.Med} {
    margin-top: -176px;
  }
`;

const aboutWrapper = css`
  padding-top: ${unit * 15}px;
  padding-bottom: ${unit * 15}px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  h3 {
    ${typescale.large}
    font-family: ${fontFamily.serif};
    margin-bottom: ${unit * 5}px;
  }

  p {
    ${typescale.base}
    color: ${color(colors.text, 0.9)};
    opacity: 0.7;
    margin-bottom: ${unit * 5}px;
  }

  img {
    display: none;
    margin-right: 10%;
    background: ${colors.tertiary};

    ${mqFrom.Lrg} {
      display: inline-block;
    }
  }
`;

const aboutInfo = css`
  width: 100%;

  ${mqFrom.Lrg} {
    width: 376px;
  }
`;

export const home = {
  heroBg,
  heroWrapper,
  heroImg,
  heroText,
  fundShadow,
  fundWrapper,
  fundImg,
  fundText,
  fundTextAnchor,
  fundResponsibility,
  supplyWrapper,
  supplyTitle,
  supplyLinkTop,
  supplyLinkBottom,
  supplyUl,
  supplyLi,
  supplySkeleton,
  newsBg,
  newsLinkTop,
  newsLinkBottom,
  newsTitle,
  newsHeroWrapper,
  newsHeroImg,
  newsHeroInfo,
  newsItemDate,
  newsItemTitle,
  newsItemDescription,
  newsCardsWrapper,
  newsCard,
  newsCardTitle,
  newsCardDate,
  announcementBg,
  announcementWrapper,
  announcementTitle,
  announcementCardsWrapper,
  announcementCard,
  announcementLinkTop,
  announcementLinkBottom,
  announcementCardDate,
  aboutBg,
  aboutWrapper,
  aboutInfo,
};
