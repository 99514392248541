import { createAction, ActionUnion } from 'modules/redux-store';
import { News } from 'models';
import { PublishedFilter } from 'consts';

import { NewsTypes } from './types';

const AddActions = {
  addRequest: () => createAction(NewsTypes.AddRequest),
  addNews: (news: News[]) => createAction(NewsTypes.AddNews, { news }),
  addError: (error: string) => createAction(NewsTypes.AddError, { error }),
  addPublishFilter: (filter: PublishedFilter) =>
    createAction(NewsTypes.AddPublishFilter, { filter }),
};

export const NewsActions = {
  ...AddActions,
};

export type NewsActions = ActionUnion<typeof NewsActions>;
