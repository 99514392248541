import { css } from '@emotion/core';

import { typescale } from './typescale.styles';

const publicWrapper = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const main = css`
  flex: 1;
  position: relative;
  overflow: hidden;
  min-height: 60vh;
`;

const notFound = css`
  ${typescale.xlarge}
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const layout = {
  publicWrapper,
  main,
  notFound,
};
