/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { nav, sidebar } from 'styles';
import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { AuthThunks } from 'modules/authentication';

import { RoutePath, authenticatedRoutes } from '../consts';

export const SideNavigation: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { push } = useHistory();
  const { user } = useSelector((state: ApplicationState) => state.auth);

  return (
    <aside css={sidebar.wrapper}>
      <div>
        <div css={sidebar.branding}>
          <NavLink css={nav.logo} to={RoutePath.HomePage}>
            <img
              src={require('assets/images/socijalna-burza-logo.svg')}
              alt="socijalna burza logo"
            />
            <p>Socijalna burza</p>
          </NavLink>
        </div>

        <nav css={sidebar.navWrapper}>
          <ul css={sidebar.navList}>
            {user &&
              authenticatedRoutes
                .filter(route => !route.key.includes('details'))
                .map(route => (
                  <li key={route.path} css={sidebar.navListItem}>
                    <NavLink to={route.path} css={sidebar.navLink}>
                      {route.title}
                    </NavLink>
                  </li>
                ))}
          </ul>
        </nav>
      </div>

      <div>
        <button type="button" css={sidebar.logout} onClick={handleLogout}>
          Odjava
        </button>
      </div>
    </aside>
  );

  function handleLogout() {
    push(RoutePath.HomePage);
    dispatch(AuthThunks.logout());
  }
};
