/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { home } from 'styles';
import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { RoutePath } from 'modules/navigation';
import { FormError, EmptyListState, ItemList } from 'components';

import { AnnouncementsThunks } from '../redux';

export const HomePageAnnouncements: React.FC = () => {
  const { announcements, announcementsAreChanging, error } = useSelector(
    (state: ApplicationState) => state.announcements,
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(AnnouncementsThunks.getPublishedAnnouncements());
  }, [dispatch]);

  return (
    <div css={home.announcementBg}>
      <div css={home.announcementWrapper}>
        <div css={home.announcementTitle}>
          <h3>Najave</h3>

          {!announcementsAreChanging && (
            <Link css={home.announcementLinkTop} to={RoutePath.Announcements}>
              Pogledaj sve
            </Link>
          )}
        </div>

        <FormError error={error} />

        <EmptyListState
          title="Trenutno nema najava"
          data={announcements}
          isLoading={announcementsAreChanging}
        />

        <ItemList
          items={announcements.slice(0, 4)}
          path={RoutePath.Announcements}
          listCss={home.announcementCardsWrapper}
          itemCss={home.announcementCard}
          dateCss={home.announcementCardDate}
          placeholderItems={4}
          isLoading={announcementsAreChanging}
        />

        {!announcementsAreChanging && (
          <Link
            css={[home.announcementLinkBottom]}
            to={RoutePath.Announcements}
          >
            Pogledaj sve
          </Link>
        )}
      </div>
    </div>
  );
};
