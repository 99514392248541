import createCachedSelector from 're-reselect';

import { ApplicationState } from 'modules/redux-store';
import { Category } from 'models';
import { getSearchQuery, applySearchParams } from 'modules/search';

const donations = (state: ApplicationState) => state.donations.donations;
const donationFilters = (state: ApplicationState) => state.donations.filters;
const donationPublishedFilter = (state: ApplicationState) =>
  state.donations.publishedFilter;

const getDonationsByFilter = createCachedSelector(
  donations,
  donationFilters,
  (donations, filters) => {
    if (!filters.length) return donations;
    return donations.filter(donation => filters.includes(donation.category));
  },
)((_donations, filters: Category[]) => (filters ? filters.join('-') : 'None'));

const getDonationsByFiltersAndQuery = createCachedSelector(
  donations,
  donationFilters,
  donationPublishedFilter,
  getSearchQuery,
  (donations, filters, publishFilter, query) => {
    return donations
      .filter(({ name }) => applySearchParams([name], query))
      .filter(
        donation =>
          (publishFilter === 'All' && donation) ||
          (publishFilter === 'Published' && donation.published) ||
          (publishFilter === 'Draft' && !donation.published),
      )
      .filter(donation =>
        filters.length ? filters.includes(donation.category) : donation,
      );
  },
)(
  (_donations, filters: Category[], publishFilter, query) =>
    `${query}_${publishFilter}_${filters ? filters.join('-') : 'None'}`,
);

export const DonationsSelectors = {
  getDonationsByFilter,
  getDonationsByFiltersAndQuery,
};
