/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { wrappers, content, form, about, spacers } from 'styles';
import { FormHeader, Helmet } from 'components';

export const About: React.FC = () => {
  return (
    <section>
      <Helmet
        title="Socijalna burza - O projektu"
        description="Kako je nastao i koji je cilj projekta 'Socijalna burza'? Pročitajte sve o projektu te provedbi istog."
      />

      <div css={[wrappers.normal, content.padding]}>
        <FormHeader title="O projektu" />

        <div css={about.topWrapper}>
          <img
            src={require('assets/images/o_projektu2.png')}
            alt="O projektu - fondovi"
            css={about.partnersImg}
          />

          <div>
            <div css={about.infoRow}>
              <div css={about.infoItem}>
                <p css={about.infoLabel}>Naziv projekta</p>
                <p css={about.infoText}>
                  Prema sigurnijoj socijalnoj budućnosti UP.02.1.2.01.0039
                </p>
              </div>
              <div css={about.infoItem}>
                <p css={about.infoLabel}>Nositelj</p>
                <p css={about.infoText}>Prijatelji sv. Martina</p>
              </div>
            </div>

            <div css={about.infoRow}>
              <div css={about.infoItem}>
                <p css={about.infoLabel}>Partner</p>
                <p css={about.infoText}>Grad Beli Manastir</p>
              </div>
              <div css={about.infoItem}>
                <p css={about.infoLabel}>Trajanje</p>
                <p css={about.infoText}>6.2.2020. do 6.2.2023.</p>
              </div>
            </div>

            <div css={about.infoRow}>
              <div css={about.infoItem}>
                <p css={about.infoLabel}>Vrijednost</p>
                <p css={about.infoText}>1.336.840,00 kn</p>
              </div>
              <div css={about.infoItem}>
                <p css={about.infoLabel}>Cilj</p>
                <p css={about.infoText}>
                  Unaprjeđenje socijalnih usluga na području Belog Manastira
                </p>
              </div>
            </div>

            <div css={about.infoRow}>
              <div css={about.infoItem}>
                <p css={about.infoLabel}>
                  Kontakt osoba za informacije o projektu
                </p>
                <p css={about.infoText}>Laura Blagus Tenjeri</p>
                <p css={about.infoText}>tel. 097 698 2901</p>
              </div>
            </div>

            <img
              css={about.fundsImg}
              src={require('assets/images/fondovi.png')}
              alt="Fondovi EU"
            />
            <p css={about.fundsText}>
              <a
                href="https://www.esf.hr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.esf.hr
              </a>{' '}
              Projekt je sufinancirala Europska unija iz Europskog socijalnog
              fonda.
            </p>
          </div>
        </div>
      </div>

      <div css={about.bg}>
        <div css={[about.purpose]}>
          <p css={about.label}>Svrha, opravdanost i glavne djelatnosti</p>
          <div css={form.flexFields}>
            <div>
              <p css={about.bigText}>
                Prema posljednjem popisu stanovništva području Belog Manastira
                živi velik broj ljudi distribucijski i relacijski deprivirano.
                Od ukupnog broja stanovnika 2011. godine 20,5% (2066) živjelo je
                s teškoćama u obavljanju svakodnevnih aktivnosti; 9,5% (959) kao
                primarni prihod imalo je socijalne naknade ili povremenu pomoć
                drugih te čak 33,2% (3346) živjelo je bez ikakvih prihoda.
              </p>

              <p css={[about.normalText, spacers.top.large]}>
                Kao posebno ranjive skupine ističu se djeca, mladi i stari
                kojima materijalne poteškoće ne utječu samo na osiguravanje
                osnovnih životnih potrepština, već i ukupnu kvalitetu života, a
                posebno na razinama obavljanja svakodnevnih aktivnosti,
                korištenja postojeće, ali teško im dostupne pomoći drugih
                građana te međusobnog druženja i ostvarenja slobodnog vremena.
                Sve te poteškoće nisu samo osobni problem depriviranih, već
                višedimenzionalno negativno utječu na ukupnu koheziju Belog
                Manastira i hrvatskog društva u cjelini. Premda je riječ o vrlo
                kompleksnim uzorcima moralne, kulturalne, socijalne, ekonomske i
                političke prirode koje nije moguće otkloniti jednom mjerom niti
                jednim projektom ipak upravo ta kompleksnost omogućuje da svaki
                društveni čimbenik sa svoje strane doprinese njihovom
                otklanjanju ili barem ublažavanju nastalih posljedica.
              </p>
            </div>

            <div css={about.expectedResults}>
              <p css={about.bigText}>Očekivani rezultati projekta</p>
              <div css={about.normalText}>
                <ul>
                  <li>
                    organizirana i provedena javna kampanja za podizanje
                    svijesti o promicanju građanske solidarnosti
                  </li>
                  <li>objavljeno 18 dvomjesečnih biltena</li>
                  <li>
                    nabavljeno kombi vozilo za potrebe provođenja projekta
                  </li>
                  <li>
                    održane kreativne radionice, okrugli stolovi i edukacijski
                    kampovi
                  </li>
                  <li>tiskana slikovnica o svetom Martinu</li>
                  <li>
                    usavršavanje volontera u stjecanju novih znanja i vještina
                  </li>
                  <li>
                    uspostavljena burza volontera, rabljenog namještaja, odjeće
                    i medicinskih pomagala putem nove web stranice
                  </li>
                  <li>organizirana besplatna praonica rublja</li>
                  <li>adaptiran prostor udruge za potrebe projekta</li>
                  <li>
                    provedeno istraživanje o mogućnostima i potrebama za
                    razvojem socijalnih usluga te tiskana znanstvena publikacija
                    kao rezultat provedenog istraživanja
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div css={about.activitiesWrapper}>
        <div>
          <p css={about.activitiesBigText}>
            Naša Udruga želi ovim projektom u okviru svojih kompetencija
            doprinijeti otklanjanju uzorka i ublažavanju nastalih i budućih
            posljedica kroz slijedeće djelatnosti:
          </p>
          <img
            src={require('assets/images/o_projektu3.png')}
            alt="O projektu - djelatnosti"
            css={about.activitiesImg}
          />
        </div>

        <div css={about.olWrapper}>
          <ol css={about.ol}>
            <li>
              <div>
                <p css={about.olTitle}>Virtualna socijalna burza</p>
                <p css={about.olDesc}>
                  Dostupna materijalna pomoć ponuđena od strane materijalno
                  stabilnih sugrađana mora nerijetko, premda je potrebna za
                  materijalno ugrožene, od strane naše Udruge biti odbijena, jer
                  ne posjedujemo potrebnu infrastrukturu za reklamiranje,
                  preuzimanje, skladištenje i distribuciju te ćemo u tu svrhu
                  izraditi virtualnu web burzu namještaja, odjeće i medicinskih
                  pomagala.
                </p>
              </div>
            </li>

            <li>
              <div>
                <p css={about.olTitle}>Virtualna burza volontera</p>
                <p css={about.olDesc}>
                  Svako unaprjeđenje socijalnih usluga zahtjeva uključivanje i
                  šire zajednice čiji članovi ne samo da trebaju pod utjecajem
                  javne promidžbe povremeno drugima pomoći, već iznad svega biti
                  solidarno osviještena zajednica, od strane svakog svoga člana
                  trajno čvrsto odlučna htjeti dobro drugoga kao da je vlastito,
                  a za čije ostvarenje smatramo nužno potrebnim nastavak,
                  proširenje i nadogradnju naše dosadašnje javne kampanje
                  promicanja građanske solidarnosti i stvaranje trajne
                  infrastrukture poput virtualne web burze volontera koja će
                  zainteresiranima osigurati prostor za uključivanje u pomoć
                  potrebitima.
                </p>
              </div>
            </li>

            <li>
              <div>
                <p css={about.olTitle}>Kreativno slobodno vrijeme</p>
                <p css={about.olDesc}>
                  Dobro osmišljeno, stručno vođeno i besplatno dostupno
                  provođenje slobodnog vremena za djecu i stare u kojemu će
                  jednakopravno sudjelovati i materijalno osigurani i siromašni,
                  djeca, stari i mladi jest ozbiljan socijalni zahtjev na koji
                  ovim projektom želimo odgovoriti kroz kreativne radionice i
                  kampove kako bi doprinijeli razvoju vršnjačkog, socijalnog i
                  međugeneracijskog uvažavanja, poštivanja, međusobne skrbi i
                  suradnje.
                </p>
              </div>
            </li>

            <li>
              <div>
                <p css={about.olTitle}>Besplatna praonica rublja</p>
                <p css={about.olDesc}>
                  Materijalne poteškoće velikog broja građana s područja Grada
                  su ozbiljna prepreka u osiguranju osnovnih higijenskih uvjeta
                  a posljedično po njima zdravstveno i socijalno te ćemo zbog
                  toga pokrenuti besplatnu praonicu rublja za sve materijalno
                  ugrožene građane.
                </p>
              </div>
            </li>

            <li>
              <div>
                <p css={about.olTitle}>
                  Istraživanje o socijalnim uslugama na području Belog Manastira
                </p>
                <p css={about.olDesc}>
                  Promjenjivost i kompleksnost društvenih prilika zahtjeva
                  egzaktno provjerljivo i trajno analiziranje postojećeg stanja
                  kako bi se postojeće socijalne usluge mogle prikladno
                  prilagoditi i osmisliti nove, a zbog čega je potrebno
                  znanstveno istražiti postojeće stanje mogućnosti i ograničenja
                  pružanja socijalnih usluga i izraditi plan za njihovo
                  provođenje u budućnosti te tako pozitivno doprinijeti razvoju
                  održive izgradnje ukupnog socijalnog kapitala Grada.
                </p>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </section>
  );
};
