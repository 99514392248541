import { css } from '@emotion/core';

import { unit, colors } from './vars.styles';
import { color } from './mixins.styles';
import { typescale } from './typescale.styles';
import { mqFrom } from './responsive.styles';
import { keyFrames } from './kf.styles';

const wrapper = css`
  ${mqFrom.Lrg} {
    display: flex;
    justify-content: flex-start;
  }
`;

const info = css`
  margin-bottom: ${unit * 2}px;
  color: ${color(colors.text, 0.9)};
  font-size: 14px;
  line-height: 20px;
  animation: ${keyFrames.appear} 0.5s ease-out;

  p:last-of-type {
    margin-top: ${unit * 4}px;

    ${mqFrom.Lrg} {
      margin-bottom: ${unit * 20}px;
    }
  }
`;

const infoLarge = css`
  ${typescale.medium}
  opacity: 1;
`;

const infoSmall = css`
  opacity: 0.7;
`;

const coverImg = css`
  width: 100%;
  max-width: 386px;
  margin-bottom: ${unit * 4}px;
  margin-top: ${unit * 4}px;
`;

const formWrapper = css`
  width: 100%;
  animation: ${keyFrames.appear2} 1s ease-out;

  ${mqFrom.Lrg} {
    max-width: 582px;
    margin-left: 140px;
  }
`;

export const contact = {
  wrapper,
  info,
  infoLarge,
  infoSmall,
  coverImg,
  formWrapper,
};
