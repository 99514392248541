import { color } from './mixins.styles';

/**
 * Color variables
 */
export const colors = {
  primary: '#FA2C33',
  secondary: '#FDC468',
  tertiary: '#FFF4E0',
  dark: '#0D0D0D',
  warning: '#FA2C33',
  gray: color('#E4E4E4', 0.5),
  text: '#0D0D0D',
  bg: '#FFFFFF',
};

/**
 * Project base line height and font size
 */
export const baseLineHeight = 28;
export const baseFontSize = 16;

export const fontSize = {
  tiny: 11,
  small: 12,
  base: baseFontSize,
  medium: 20,
  large: 25,
  xlarge: 31,
  xxlarge: 39,
  xxxlarge: 49,
  huge: 61,
};

export const lineHeight = {
  tiny: 16,
  small: 20,
  base: baseLineHeight,
  medium: 28,
  large: 36,
  xlarge: 40,
  xxlarge: 48,
  xxxlarge: 56,
  huge: 76,
};

/**
 * Type weights
 */
export const fontWeights = {
  weightThin: 100,
  weightLight: 300,
  weightRegular: 400,
  weightMedium: 500,
  weightBold: 700,
  weightBlack: 900,
};

/**
 * Font families
 */

export const fontFamily = {
  sans:
    "'PTSans', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto','Oxygen-Sans', 'Ubuntu', 'Cantarell', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

  serif: "'Merriweather', Georgia, Times, serif",
};

/**
 * Units and spacers
 */
export const unit = 4;
export const spacer = unit * 4;
export const globalRadial = 4;
