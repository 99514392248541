import firebase from 'firebase/app';

import { Profile } from './Profile';
import { Category } from './Category';

export interface Donation extends Profile {
  id: string;
  name: string;
  category: Category;
  description: string;
  published: boolean;
  photoUrls: string[];
  heroImageIndex: number;
  createdAt: firebase.firestore.FieldValue;
  dateOfPublishing: firebase.firestore.FieldValue;
}

export class Donation extends Profile {
  constructor(object?: Partial<Donation>) {
    super(object);

    if (object?.id) {
      this.id = object.id;
    }

    this.name = object?.name || '';
    this.category = object?.category || 'Odjeća';
    this.description = object?.description || '';
    this.photoUrls = object?.photoUrls || [];
    this.published = object?.published || false;
    this.heroImageIndex = object?.heroImageIndex || 0;
    this.createdAt =
      object?.createdAt || firebase.firestore.FieldValue.serverTimestamp();
    this.dateOfPublishing = firebase.firestore.FieldValue.serverTimestamp();
  }
}
