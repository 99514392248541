/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useRef } from 'react';
import { FormContext } from 'react-hook-form';

import { Request } from 'models';
import {
  ValidatedInput,
  FormSubmit,
  FormCheckbox,
  FormHeader,
  CompletedFormMessage,
  Helmet,
} from 'components';
import { form, wrappers, demand } from 'styles';
import { useFormLogic } from 'hooks';
import { EmailRegExp, PhoneRegExp } from 'consts';

export const Demand: React.FC = () => {
  const alertEl = useRef<HTMLDivElement>(null);
  const {
    methods,
    saveRequest,
    formCompleted,
    formIsLoading,
    formError,
    handleReturnToForm,
  } = useFormLogic<Request>(undefined, undefined, alertEl.current);

  return (
    <section css={[demand.wrapper, wrappers.normal]}>
      <Helmet
        title="Socijalna burza - Potražnja donacija i volontera"
        description="Potrebna vam je donacija ili pomoć volontera? Zahtjev možete poslati putem obrasca."
      />

      {!formCompleted && (
        <FormContext {...methods}>
          <form
            onSubmit={methods.handleSubmit(saveRequest)}
            css={demand.formWrapper}
          >
            <FormHeader title="Zatraži donaciju" />

            <div css={form.flexFields}>
              <ValidatedInput
                label="Ime"
                placeholder="Vaše ime"
                name="firstName"
                validationObject={{ required: true }}
              />

              <ValidatedInput
                label="Prezime"
                placeholder="Vaše prezime"
                name="lastName"
                validationObject={{ required: true }}
              />
            </div>

            <div css={form.flexFields}>
              <ValidatedInput
                label="E-mail"
                placeholder="Vaša e-mail adresa"
                name="email"
                errMsg="Krivi format e-mail adrese"
                validationObject={{ pattern: EmailRegExp }}
              />

              <ValidatedInput
                label="Broj telefona"
                placeholder="Vaš broj telefona"
                name="phone"
                errMsg="Krivi format telefonskog broja"
                validationObject={{ required: true, pattern: PhoneRegExp }}
              />
            </div>

            <ValidatedInput
              textarea
              label="Upit"
              placeholder="Upišite upit"
              name="requestMessage"
              validationObject={{ required: true }}
            />

            <FormCheckbox name="policyAccepted" required />

            <FormSubmit value="Zatraži" stateIsChanging={formIsLoading} />
          </form>
        </FormContext>
      )}

      {formCompleted && (
        <CompletedFormMessage
          title="Hvala Vam na upitu. Vašu molbu ćemo pregledati i povratno se javiti
        kako bi dogovorili donaciju zatraženog predmeta."
          btnText="Zatraži novu donaciju"
          handleReturn={handleReturnToForm}
          formError={formError}
          ref={alertEl}
        />
      )}

      <img
        src={require('assets/images/zatrazi_donaciju_vector.svg')}
        alt="zatraži donaciju"
        css={demand.coverImg}
      />
    </section>
  );
};
