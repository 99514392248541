/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { FormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { withId } from 'utils';
import { useFormLogic, useModal } from 'hooks';
import { Contact, PageHeaderAction } from 'models';
import { RoutePath } from 'modules/navigation';
import { ContactsThunks } from 'modules/contacts/redux';
import { wrappers, content, form } from 'styles';
import { PageHeader, Loading, TextField, FormCheckbox } from 'components';
import { Modal } from 'components/Modal';

export const DashboardContactDetails: React.FC = () => {
  const { id } = useParams();
  const { contacts, contactsAreChanging } = useSelector(
    (state: ApplicationState) => state.contacts,
  );
  const selectedContact = contacts.find(withId(id));
  const dispatch = useDispatch<AppDispatch>();
  const { methods, deleteContact } = useFormLogic<Contact>(
    RoutePath.DashboardContacts,
  );
  const { isShowing, toggle, entityId, entityTitle } = useModal(
    selectedContact?.title,
  );

  useEffect(() => {
    dispatch(ContactsThunks.getAllContacts());
  }, [dispatch]);

  useEffect(() => {
    methods.register('id');
    methods.register('createdAt');

    methods.setValue('id', selectedContact?.id || id);
    methods.setValue('createdAt', selectedContact?.createdAt);
  }, [methods, selectedContact, id]);

  return (
    <section css={[wrappers.normal, content.area]}>
      <PageHeader
        title="Detalji potražnje"
        backAction={new PageHeaderAction('Natrag', RoutePath.DashboardContacts)}
        actions={[new PageHeaderAction('Obriši kontakt', toggle)]}
      />
      <FormContext {...methods}>
        <Loading isLoading={contactsAreChanging}>
          <div css={form.flexFields}>
            <TextField
              label="Ime"
              name="firstName"
              text={selectedContact?.firstName}
            />

            <TextField
              label="Prezime"
              name="lastName"
              text={selectedContact?.lastName}
            />
          </div>

          <div css={form.flexFields}>
            <TextField
              label="E-mail"
              name="email"
              text={selectedContact?.email}
            />

            <TextField
              label="Broj telefona"
              name="phone"
              text={selectedContact?.phone}
            />
          </div>

          <TextField
            label="Naslov"
            name="title"
            text={selectedContact?.title}
          />

          <TextField
            label="Upit"
            name="message"
            text={selectedContact?.message}
          />

          <FormCheckbox
            name="policyAccepted"
            disabled
            checked={selectedContact?.policyAccepted}
          >
            <span>Prihvaćena politika privatnosti</span>
          </FormCheckbox>
        </Loading>
      </FormContext>

      <Modal
        isShowing={isShowing}
        hide={toggle}
        onConfirm={deleteContact}
        entityId={entityId}
        entityTitle={entityTitle}
      />
    </section>
  );
};
