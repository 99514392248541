/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';

import { Announcement, News } from 'models';
import { home } from 'styles';
import { getHeroImage, slugify } from 'utils';

interface Props {
  items: (News | Announcement)[];
  listCss?: SerializedStyles | SerializedStyles[];
  itemCss?: SerializedStyles | SerializedStyles[];
  titleCss?: SerializedStyles | SerializedStyles[];
  dateCss?: SerializedStyles | SerializedStyles[];
  descriptionCss?: SerializedStyles | SerializedStyles[];
  path: string;
  placeholderItems: number;
  isLoading?: boolean;
}

export const ItemList: React.FC<Props> = ({
  items,
  listCss,
  itemCss,
  titleCss,
  dateCss,
  descriptionCss,
  path,
  placeholderItems,
  isLoading,
}) => {
  if (!isLoading && !items.length) {
    return null;
  }

  return (
    <ul css={listCss}>
      {!items.length &&
        Array(placeholderItems)
          .fill(1)
          .map((_, key) => (
            <div key={key} css={itemCss}>
              <Skeleton height="212px" />

              <p css={titleCss || home.newsCardTitle}>
                <Skeleton />
              </p>
              <p css={dateCss || home.newsCardDate}>
                <Skeleton />
              </p>
              {descriptionCss && (
                <p css={descriptionCss || home.newsItemDescription}>
                  <Skeleton />
                </p>
              )}
            </div>
          ))}

      {items.length > 0 &&
        items.map(item => (
          <Link key={item.id} to={`${path}/${slugify(item.title)}/${item.id}`}>
            <div css={itemCss}>
              <img
                src={getHeroImage(item.photoUrls, item.heroImageIndex)}
                alt="main"
              />
              <p css={titleCss || home.newsCardTitle}>{item.title}</p>
              <p css={dateCss || home.newsCardDate}>
                {item.dateOfPublishing &&
                  format(
                    (item.dateOfPublishing as firebase.firestore.Timestamp).toDate(),
                    'dd.LL.yyyy',
                  )}
              </p>
              {descriptionCss && (
                <p css={descriptionCss || home.newsItemDescription}>
                  {item.description}
                </p>
              )}
            </div>
          </Link>
        ))}
    </ul>
  );
};
