/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { RoutePath } from 'modules/navigation';
import { PublicLayout } from 'components';
import { wrappers, content, buttons, spacers } from 'styles';

export const Unauthorized: React.FC = () => {
  return (
    <PublicLayout>
      <div css={[wrappers.normal, content.padding]}>
        <h2>
          Ne posjedujete autorizacijska prava za pristupanje navedenoj stranici
        </h2>

        <div>
          <Link
            css={[buttons.primary.large, spacers.top.xLarge]}
            to={RoutePath.Login}
          >
            Prijava
          </Link>
        </div>

        <Link css={buttons.container} to={RoutePath.HomePage}>
          Nazad na naslovnicu
        </Link>
      </div>
    </PublicLayout>
  );
};
