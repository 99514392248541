import { Donation, Category } from 'models';
import { PublishedFilter } from 'consts';

import { DonationsActions } from './actions';
import { DonationsTypes } from './types';

export interface DonationsState {
  donationsAreChanging: boolean;
  error?: string;
  donations: Donation[];
  filters: Category[];
  publishedFilter: PublishedFilter;
}

const INITIAL_STATE: DonationsState = {
  donationsAreChanging: true,
  error: undefined,
  donations: [],
  filters: [],
  publishedFilter: 'All',
};

export default (
  state: DonationsState = INITIAL_STATE,
  action: DonationsActions,
) => {
  switch (action.type) {
    case DonationsTypes.AddRequest:
      return {
        ...state,
        donationsAreChanging: true,
        error: undefined,
      };

    case DonationsTypes.AddDonations:
      return {
        ...state,
        donationsAreChanging: false,
        error: undefined,
        donations: action.payload.donations,
      };

    case DonationsTypes.AddError:
      return {
        ...state,
        donationsAreChanging: false,
        error: action.payload.error,
      };

    case DonationsTypes.AddFilters:
      return {
        ...state,
        filters: action.payload.filters,
      };

    case DonationsTypes.AddPublishedFilter:
      return {
        ...state,
        publishedFilter: action.payload.filter,
      };

    default:
      return state || INITIAL_STATE;
  }
};
