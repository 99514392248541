/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/core';
import React, { useEffect } from 'react';
import { ErrorMessage, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { fields, inputs, nav } from 'styles';
import { RoutePath } from 'modules/navigation';

interface Props {
  name: string;
  checked?: boolean;
  errMsg?: string;
  disabled?: boolean;
  required?: boolean;
  style?: SerializedStyles | SerializedStyles[];
}

export const FormCheckbox: React.FC<Props> = ({
  name,
  checked,
  errMsg,
  disabled,
  required,
  children,
  style,
}) => {
  const { register, errors, setValue, watch, formState } = useFormContext();
  const checkboxValue = watch(name);

  useEffect(() => {
    setValue(name, checked);
  }, [setValue, name, checked]);

  return (
    <label css={style || fields.checkbox} htmlFor={name}>
      <input
        type="checkbox"
        name={name}
        id={name}
        defaultChecked={checked}
        ref={required ? register({ required: required }) : register}
        css={inputs.inputCheckbox}
        disabled={disabled}
      />

      <img
        src={
          checkboxValue
            ? require('assets/images/check-checked.svg')
            : require('assets/images/check-unchecked.svg')
        }
        alt="politika privatnosti"
        css={fields.checkboxImg}
      />
      {children ? (
        children
      ) : (
        <span>
          Pristajem na upotrebu osobnih podataka u svrhu kontaktiranja prilikom
          kreiranja ponude i potražnje donacija. Više o upotrebi osobnih
          podataka i zaštiti istih možete pročitati na{' '}
          <Link to={RoutePath.PrivacyPolicy} css={nav.text}>
            Politika privatnosti.
          </Link>
        </span>
      )}

      {formState.isSubmitted && (
        <ErrorMessage
          errors={errors || {}}
          name={name}
          message={errMsg || 'Obavezno polje'}
          as={<p css={fields.errorMsg} />}
        />
      )}
    </label>
  );
};
