import { css } from '@emotion/core';

import { unit, fontFamily, fontWeights, colors } from './vars.styles';
import { typescale } from './typescale.styles';
import { color } from './mixins.styles';
import { mqFrom } from './responsive.styles';
import { alert } from './alert.styles';
import { keyFrames as kf } from './kf.styles';

const wrapper = css`
  max-width: 582px;
`;

const flexFields = css`
  ${mqFrom.Med} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & > div {
      width: 47%;
    }
  }
`;

const header = css`
  ${typescale.large}
  font-family: ${fontFamily.serif};
  font-weight: ${fontWeights.weightBold};
  color: ${color(colors.text, 0.9)};
  margin-bottom: ${unit * 12}px;
`;

const disabled = css`
  cursor: not-allowed;
  opacity: 0.4;
`;

const complete = css`
  ${alert.success}
  ${typescale.base}
  color: ${color(colors.text, 0.9)};
  animation: ${kf.showUp} 1s ease-out;
  height: fit-content;

  p {
    margin-bottom: ${unit * 4}px;
  }

  ${mqFrom.Sml} {
    ${typescale.large}
  }
`;

const error = css`
  ${complete}
  ${alert.danger}
  height: fit-content;
`;

export const form = {
  wrapper,
  flexFields,
  header,
  disabled,
  complete,
  error,
};
