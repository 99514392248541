import firebase from 'firebase/app';

import { Donation } from './Donation';
import { Profile } from './Profile';

export interface Reservation extends Profile {
  id: string;
  donation: Donation;
  createdAt: firebase.firestore.FieldValue;
}

export class Reservation extends Profile {
  constructor(object?: Partial<Reservation>) {
    super(object);

    if (object?.id) {
      this.id = object.id;
    }

    this.donation = object?.donation || { ...new Donation() };
    this.createdAt =
      object?.createdAt || firebase.firestore.FieldValue.serverTimestamp();
  }
}
