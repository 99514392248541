import { css } from '@emotion/core';

import {
  colors,
  unit,
  globalRadial,
  fontFamily,
  fontWeights,
} from './vars.styles';
import { color } from './mixins.styles';
import { typescale } from './typescale.styles';
import { mqFrom } from './responsive.styles';

const navBg = css`
  position: relative;
  background-color: ${colors.tertiary};
`;

const navBgLight = css`
  position: relative;
  background-color: #fff9ef;
`;

const wrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
`;

const positionFixed = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  visibility: visible;
  z-index: 10;
  opacity: 1;
  transition: opacity 0.3s, visibility 0.3s;
`;

const hide = css`
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, z-index 0.3s;
  z-index: -1;
`;

const logo = css`
  display: inline-flex;
  font-family: ${fontFamily.serif};
  ${typescale.base}
  color: ${color(colors.text, 0.9)};
  z-index: 10;

  img {
    display: none;
  }

  ${mqFrom.Xxlrg} {
    color: ${colors.primary};

    img {
      display: block;
      width: 24px;
      height: 24px;
    }

    p {
      text-transform: none;
      padding-left: ${unit * 2}px;
    }
  }
`;

const list = css`
  display: none;
  padding: 0;

  ${mqFrom.Xxlrg} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 11px;
  }
`;

const listItem = css`
  outline: none;
  user-select: none;
  padding-left: ${unit * 7}px;
  ${typescale.tiny}
`;

const link = css`
  position: relative;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  z-index: 1;

  color: ${color(colors.text, 0.7)};

  transition: color 0.2s ease-out;

  &:hover,
  &:focus {
    color: ${color(colors.text, 0.9)};
  }

  &[aria-current] {
    color: ${color(colors.text, 0.9)};

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 60%;
      left: 0%;

      height: 8px;
      border-radius: ${globalRadial}px;
      background-color: ${color(colors.secondary, 0.8)};
      width: 100%;
      z-index: -1;
    }
  }
`;

const cta = css`
  ${typescale.small}

  background: ${colors.secondary};
  border-radius: ${globalRadial * 2}px;
  padding: ${unit * 3}px ${unit * 5}px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${color(colors.text, 0.9)};
  font-weight: bold;
  transition: color 0.2s ease-out;

  &:hover {
    color: ${color(colors.text, 0.7)};
  }
`;

const hamburger = css`
  position: fixed;
  display: inline-block;
  cursor: pointer;
  top: 26px;
  right: 20px;
  z-index: 30;

  ${mqFrom.Xxlrg} {
    display: none;
  }
`;

const hamburgerMenu = css`
  background-color: ${colors.tertiary};
  position: fixed;
  top: 72px;
  left: 0;
  width: 100vw;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 20;
  margin: 0;
  visibility: visible;
  opacity: 1;
  height: calc(100vh - 72px);
`;

const burgerListItem = css`
  height: 70px;
  ${typescale.small}
  border-bottom: 1px solid ${color(colors.dark, 0.2)};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`;

const bar = css`
  width: 16px;
  height: 3px;
  background-color: ${colors.primary};
  margin-bottom: 3px;
  transition: 0.4s;
`;

const changeBar = css`
  width: 8px;
`;

const text = css`
  text-decoration: underline;
  color: ${color(colors.text, 0.9)};
  transition: color 0.15s ease-out;

  &:hover {
    color: ${color(colors.text, 0.6)};
  }
`;

const footerBg = css`
  background-color: ${colors.tertiary};
  min-height: 360px;
`;

const footerWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: ${unit * 7}px;
  padding-bottom: ${unit * 16}px;

  ${mqFrom.Med} {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  ${mqFrom.Lrg} {
    height: 100%;
    padding-top: ${unit * 22}px;
    padding-bottom: ${unit * 15}px;
  }
`;

const footerCol = css`
  ${typescale.base}

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &:last-child {
    max-width: 363px;

    ${mqFrom.Med} {
      margin-left: 50%;
      margin-top: -64px;
    }
    ${mqFrom.Lrg} {
      margin-left: 0;
      margin-top: 0;
    }
  }
`;

const footerLink = css`
  color: ${colors.primary};
  opacity: 0.9;
  margin-bottom: ${unit * 2}px;
  ${typescale.base}
  transition: opacity 0.15s ease-out;

  ${mqFrom.Lrg} {
    margin-bottom: ${unit * 3}px;
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    opacity: 0.6;
  }
`;

const footerCompany = css`
  padding-bottom: ${unit * 8}px;

  p:first-of-type {
    font-weight: ${fontWeights.weightBold};
  }
`;

const logoBlend = css`
  width: 68px;
  mix-blend-mode: multiply;
  margin-bottom: ${unit * 9}px;
`;

const footerFbDesktop = css`
  display: none;
  margin-top: 79px;
  ${typescale.tiny}
  transition: opacity 0.15s ease-out;

  &:hover {
    opacity: 0.6;
  }

  img {
    padding-left: ${unit * 2}px;
    width: 30px;
    height: 30px;
  }

  ${mqFrom.Lrg} {
    display: flex;
    align-items: center;
  }
`;

const footerFbMobile = css`
  margin-top: ${unit * 9}px;
  margin-bottom: ${unit * 13}px;
  display: flex;
  align-items: center;
  ${typescale.base}

  img {
    padding-left: ${unit * 2}px;
    width: 42px;
    height: 42px;
  }

  ${mqFrom.Lrg} {
    display: none;
  }
`;

const fund = css`
  max-width: 100%;
  margin-bottom: ${unit * 2}px;
  image-rendering: -webkit-optimize-contrast;
`;

const footerFundText = css`
  font-size: 14px;
  line-height: 20px;
  color: ${color(colors.text, 0.7)};

  a {
    transition: opacity 0.15s ease-out;

    &:hover {
      opacity: 0.6;
    }
  }
`;

const footerYear = css`
  margin-top: 84px;
`;

export const nav = {
  navBg,
  navBgLight,
  wrapper,
  positionFixed,
  hide,
  logo,
  list,
  listItem,
  link,
  cta,
  hamburger,
  hamburgerMenu,
  burgerListItem,
  bar,
  changeBar,
  text,
  footerBg,
  footerWrapper,
  footerCol,
  footerCompany,
  logoBlend,
  footerLink,
  footerFbDesktop,
  footerFbMobile,
  fund,
  footerFundText,
  footerYear,
};
