import { FirestoreService, StorageService } from 'modules/firebase';
import { Collection } from 'consts';
import { News } from 'models';

const Database = FirestoreService<News>(Collection.News);

const Storage = StorageService(Collection.News);

export const NewsService = {
  Database,
  Storage,
};
