/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormContext } from 'react-hook-form';

import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { withId } from 'utils';
import { RoutePath } from 'modules/navigation';
import { useFormLogic, useModal } from 'hooks';
import { Request, PageHeaderAction } from 'models';
import { RequestsThunks } from 'modules/requests';
import { PageHeader, Loading, TextField, FormCheckbox } from 'components';
import { wrappers, content, admin } from 'styles';
import { Modal } from 'components/Modal';

export const DashboardDemandDetails: React.FC = () => {
  const { id } = useParams();
  const { requests, requestsAreChanging } = useSelector(
    (state: ApplicationState) => state.requests,
  );
  const selectedRequest = requests.find(withId(id));
  const dispatch = useDispatch<AppDispatch>();
  const { methods, deleteRequest } = useFormLogic<Request>(
    RoutePath.DashboardDemand,
  );
  const { isShowing, toggle, entityId, entityTitle } = useModal(
    `zahtjev od ${selectedRequest?.firstName} ${selectedRequest?.lastName}`,
  );

  useEffect(() => {
    dispatch(RequestsThunks.getAllRequests());
  }, [dispatch]);

  useEffect(() => {
    methods.register('id');
    methods.register('createdAt');

    methods.setValue('id', selectedRequest?.id);
    methods.setValue('createdAt', selectedRequest?.createdAt);
  }, [methods, selectedRequest]);

  return (
    <section css={[wrappers.normal, content.area]}>
      <PageHeader
        title="Detalji potražnje"
        backAction={new PageHeaderAction('Natrag', RoutePath.DashboardDemand)}
        actions={[new PageHeaderAction('Obriši zahtjev', toggle)]}
      />
      <FormContext {...methods}>
        <Loading isLoading={requestsAreChanging}>
          <div css={admin.textRow}>
            <TextField
              label="Ime"
              name="firstName"
              text={selectedRequest?.firstName}
            />

            <TextField
              label="Prezime"
              name="lastName"
              text={selectedRequest?.lastName}
            />
          </div>

          <div css={admin.textRow}>
            <TextField
              label="E-mail"
              name="email"
              text={selectedRequest?.email}
            />

            <TextField
              label="Broj telefona"
              name="phone"
              text={selectedRequest?.phone}
            />
          </div>

          <TextField
            label="Upit"
            name="requestMessage"
            text={selectedRequest?.requestMessage}
          />

          <FormCheckbox
            name="policyAccepted"
            disabled
            checked={selectedRequest?.policyAccepted}
          >
            <span>Prihvaćena politika privatnosti</span>
          </FormCheckbox>
        </Loading>
      </FormContext>

      <Modal
        isShowing={isShowing}
        hide={toggle}
        onConfirm={deleteRequest}
        entityId={entityId}
        entityTitle={entityTitle}
      />
    </section>
  );
};
