/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';

import { home, wrappers, spacers } from 'styles';

export const HomeFund: React.FC = () => (
  <Fragment>
    <div css={home.fundShadow}>
      <div css={[wrappers.normal, home.fundWrapper]}>
        <div>
          <p>
            Projekt je sufinancirala Europska unija iz{' '}
            <a
              href="https://www.esf.hr/"
              target="_blank"
              rel="noopener noreferrer"
              css={home.fundTextAnchor}
            >
              Europskog socijalnog fonda.
            </a>
          </p>

          <p>
            Projekt "Prema sigurnijoj socijalnoj budućnosti" UP.02.1.2.01.0039
          </p>
          <a
            href="http://www.strukturnifondovi.hr/"
            target="_blank"
            rel="noopener noreferrer"
            css={[home.fundTextAnchor, spacers.right.xLarge]}
          >
            www.strukturnifondovi.hr
          </a>
          <a
            href="http://www.esf.hr/"
            target="_blank"
            rel="noopener noreferrer"
            css={home.fundTextAnchor}
          >
            www.esf.hr
          </a>
        </div>

        <div css={home.fundImg}>
          <img
            src={require('assets/images/fondovi.png')}
            alt="Europski fondovi"
          />
        </div>
      </div>
    </div>

    <div css={[wrappers.normal, home.fundText]}>
      <p>
        Projekt je sufinancirala Europska unija iz{' '}
        <a
          css={home.fundTextAnchor}
          href="https://www.esf.hr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Europskog socijalnog fonda.{' '}
        </a>
        Projekt "Prema sigurnijoj socijalnoj budućnosti" UP.02.1.2.01.0039
      </p>
      <a
        href="http://www.strukturnifondovi.hr/"
        target="_blank"
        rel="noopener noreferrer"
        css={[home.fundTextAnchor, spacers.right.xLarge]}
      >
        www.strukturnifondovi.hr
      </a>
      <a
        href="http://www.esf.hr/"
        target="_blank"
        rel="noopener noreferrer"
        css={home.fundTextAnchor}
      >
        www.esf.hr
      </a>
    </div>

    <div css={[wrappers.normal, home.fundResponsibility]}>
      <p>
        Sadržaj ove mrežne stranice isključiva je odgovornost Prijatelja svetog
        Martina
      </p>
    </div>
  </Fragment>
);
