import 'firebase/auth';

import { User } from 'firebase';

import { FirebaseService } from './FirebaseService';

enum AuthPersistence {
  Local = 'local',
  Session = 'session',
  None = 'none',
}

export function AuthService() {
  const auth = FirebaseService.Instance().auth();

  function initAuthStateListener(
    onSuccess: (user: User) => void,
    onError: (error?: string) => void,
  ) {
    return auth.onAuthStateChanged(user => {
      if (!user) {
        return onError();
      }

      return onSuccess(user);
    });
  }

  async function loginWithEmailAndPasswordAsync(
    email: string,
    password: string,
    rememberMe: boolean,
  ) {
    return auth
      .setPersistence(
        rememberMe ? AuthPersistence.Local : AuthPersistence.Session,
      )
      .then(() =>
        auth
          .signInWithEmailAndPassword(email, password)
          .then(() => undefined)
          .catch((error: firebase.FirebaseError) => error.message),
      );
  }

  async function logoutAsync() {
    return auth
      .signOut()
      .then(() => undefined)
      .catch((error: firebase.FirebaseError) => error.message);
  }

  return {
    initAuthStateListener,
    loginWithEmailAndPasswordAsync,
    logoutAsync,
  };
}
