import createCachedSelector from 're-reselect';

import { ApplicationState } from 'modules/redux-store';
import { getSearchQuery, applySearchParams } from 'modules/search';

const requests = (state: ApplicationState) => state.requests.requests;

const getRequestsByQuery = createCachedSelector(
  requests,
  getSearchQuery,
  (requests, query) => {
    return requests.filter(({ firstName, lastName, email }) =>
      applySearchParams([firstName, lastName, email], query),
    );
  },
)((_requests, query) => `${query}`);

export const RequestsSelectors = {
  getRequestsByQuery,
};
