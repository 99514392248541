import { css } from '@emotion/core';

import { colors, unit, fontFamily } from './vars.styles';
import { color } from './mixins.styles';
import { wrappers } from './wrapper.styles';
import { mqFrom } from './responsive.styles';
import { typescale } from './typescale.styles';

const bg = css`
  background: ${color(colors.secondary, 0.2)};
`;

const topWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mqFrom.Xlrg} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const partnersImg = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  height: auto;
  margin-bottom: ${unit * 5}px;
`;

const infoRow = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${mqFrom.Sml} {
    flex-direction: row;

    div:first-of-type {
      margin-right: ${unit * 9}px;
    }
  }
`;

const infoItem = css`
  width: 273px;
  color: ${color(colors.text, 0.9)};
  margin-bottom: ${unit * 10}px;
`;

const infoLabel = css`
  ${typescale.tiny}
  opacity: 0.5;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: ${unit}px;
`;

const infoText = css`
  ${typescale.base}
  font-family: ${fontFamily.serif};
`;

const fundsImg = css`
  width: 100%;
  height: auto;

  ${mqFrom.Sml} {
    max-width: 582px;
    max-height: 140px;
  }
`;

const fundsText = css`
  width: 100%;
  text-align: center;
  opacity: 0.7;
  margin-top: ${unit * 4}px;

  a {
    color: ${colors.primary};
    transition: opacity 0.15s ease-out;

    &:hover {
      opacity: 0.6;
    }
  }
`;

const label = css`
  color: ${color(colors.text, 0.4)};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  ${typescale.tiny}
  margin-bottom: ${unit * 5}px;
`;

const normalText = css`
  color: ${color(colors.text, 0.6)};
  ${typescale.small}

  ${mqFrom.Med} {
    ${typescale.base}
  }

  ul {
    padding: 0;
  }

  li {
    margin-bottom: ${unit * 2}px;
  }
`;

const bigText = css`
  color: ${color(colors.text, 0.9)};
  ${typescale.medium}
  margin-bottom: ${unit * 5}px;

  ${mqFrom.Med} {
    margin-bottom: 0;
    ${typescale.large}
  }
`;

const expectedResults = css`
  margin-top: ${unit * 5}px;

  ${mqFrom.Med} {
    margin-top: 0;
  }
`;

const purpose = css`
  ${wrappers.normal}
  padding-top: ${unit * 15}px;
  padding-bottom: ${unit * 15}px;

  ${mqFrom.Lrg} {
    padding-top: ${unit * 25}px;
    padding-bottom: ${unit * 25}px;
  }
`;

const activitiesWrapper = css`
  ${wrappers.normal}
  display: flex;
  flex-direction: column;
  padding: ${unit * 10}px 0;

  ${mqFrom.Lrg} {
    flex-direction: row;
    justify-content: space-between;
    padding: ${unit * 25}px 0;
  }
`;

const activitiesImg = css`
  width: 100%;
  height: auto;
  max-width: 479px;
  ${wrappers.normal}
`;

const activitiesBigText = css`
  ${bigText}
  ${wrappers.normal}
  margin-bottom: ${unit * 5}px;

  ${mqFrom.Lrg} {
    margin-bottom: ${unit * 15}px;
  }
`;

const olWrapper = css`
  ${wrappers.normal}

  ${mqFrom.Lrg} {
    margin-left: ${unit * 22}px;
    width: 125%;
  }
`;

const ol = css`
  padding: 0;
  counter-reset: section;

  li {
    display: flex;
    margin-bottom: ${unit * 10}px;
  }

  li:last-child {
    margin-bottom: 0;
  }

  li::before {
    counter-increment: section;
    content: counter(section);
    margin-right: ${unit * 4}px;
    padding: 8px 14px;
    height: 36px;
    width: 36px;
    min-width: 36px;
    color: ${color(colors.text, 0.6)};
    border: 1px solid ${color(colors.text, 0.2)};
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const olTitle = css`
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  color: ${color(colors.text, 0.9)};
  margin-bottom: ${unit * 4}px;
`;

const olDesc = css`
  font-size: 14px;
  line-height: 20px;
  color: ${color(colors.text, 0.7)};
`;

export const about = {
  bg,
  topWrapper,
  partnersImg,
  infoRow,
  infoItem,
  infoLabel,
  infoText,
  fundsImg,
  fundsText,
  label,
  normalText,
  bigText,
  expectedResults,
  activitiesImg,
  purpose,
  activitiesWrapper,
  activitiesBigText,
  olWrapper,
  ol,
  olTitle,
  olDesc,
};
