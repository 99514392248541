import { css } from '@emotion/core';

import { typescale } from './typescale.styles';
import { color } from './mixins.styles';
import { colors, fontFamily, unit } from './vars.styles';
import { wrappers } from './wrapper.styles';
import { content } from './content.styles';
import { mqFrom } from './responsive.styles';

const wrapper = css`
  ${wrappers.narrow}
  ${content.padding}
`;

const listTitle = css`
  ${typescale.base}
  font-family: ${fontFamily.serif};
  margin: ${unit * 8}px 0;
  display: inline;
  color: ${color(colors.text, 0.9)};

  ${mqFrom.Sml} {
    ${typescale.medium}
  }
`;

const listDescription = css`
  ${typescale.small}
  display: block;
  color: ${color(colors.text, 0.7)};

  ${mqFrom.Sml} {
    ${typescale.base}
  }
`;

const ol = css`
  counter-reset: item;
  padding: 0;
  margin: 0;

  li {
    ${listDescription}
    margin-top: ${unit * 8}px;
  }

  li:before {
    content: counters(item, '.') '. ';
    counter-increment: item;
    color: ${color(colors.text, 0.9)};
    font-family: ${fontFamily.serif};
    ${typescale.medium}
  }
`;

export const privacyp = {
  wrapper,
  listTitle,
  ol,
  listDescription,
};
