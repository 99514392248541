import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { Donation, Category } from 'models';
import {
  FirestoreListenerActions,
  FirestoreQueryParam,
} from 'modules/firebase';
import { withId } from 'utils';

import { DonationsActions } from './actions';

import { DonationsService } from '../services';

export const getAllDonations = () => (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const donationsListener = getState().listeners.donationsListener;
  if (donationsListener) {
    return;
  }

  dispatch(DonationsActions.addRequest());

  const successFunction = (data: Donation[]) => {
    dispatch(
      DonationsActions.addDonations(
        data.sort(
          (a, b) =>
            +(b.createdAt as firebase.firestore.Timestamp)?.toDate() -
            +(a.createdAt as firebase.firestore.Timestamp)?.toDate(),
        ),
      ),
    );
  };

  const errorFunction = (error: string) =>
    dispatch(DonationsActions.addError(error));

  const listener = DonationsService.Database.filterAndListen(
    successFunction,
    errorFunction,
  );
  dispatch(FirestoreListenerActions.SetListener('donationsListener', listener));
  return;
};

export const getPublishedDonations = () => (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const publishedDonationsListener = getState().listeners
    .publishedDonationsListener;
  if (publishedDonationsListener) {
    return;
  }

  dispatch(DonationsActions.addRequest());

  const successFunction = (data: Donation[]) => {
    dispatch(
      DonationsActions.addDonations(
        data.sort(
          (a, b) =>
            +(b.dateOfPublishing as firebase.firestore.Timestamp)?.toDate() -
            +(a.dateOfPublishing as firebase.firestore.Timestamp)?.toDate(),
        ),
      ),
    );
  };

  const errorFunction = (error: string) =>
    dispatch(DonationsActions.addError(error));

  const listener = DonationsService.Database.filterAndListen(
    successFunction,
    errorFunction,
    new FirestoreQueryParam('published', '==', true),
  );
  dispatch(
    FirestoreListenerActions.SetListener(
      'publishedDonationsListener',
      listener,
    ),
  );

  return;
};

export const saveDonation = (data: Donation) => async () => {
  const response = await DonationsService.Database.update(data);

  return response;
};

export const publishOrUnpublishedDonation = (docId: string) => async (
  _dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const selectedDonation = getState().donations.donations.find(withId(docId));
  if (!selectedDonation) return;

  if (selectedDonation.published) {
    const response = await DonationsService.Database.unpublish(docId);
    return response;
  }

  const response = await DonationsService.Database.publish(docId);
  return response;
};

export const updateFilters = (filter: Category) => (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const currentFilters = [...getState().donations.filters];
  let newFilters = [];

  const filterExists = currentFilters.some(
    currentFilter => currentFilter === filter,
  );
  if (filterExists) {
    newFilters = currentFilters.filter(
      currentFilter => currentFilter !== filter,
    );
  } else {
    newFilters = [...currentFilters, filter];
  }

  dispatch(DonationsActions.addFilters(newFilters));
};

export const deleteDonation = (id: string) => () => {
  DonationsService.Database.remove(id);
};

export const DonationsThunks = {
  getAllDonations,
  getPublishedDonations,
  saveDonation,
  updateFilters,
  deleteDonation,
  publishOrUnpublishedDonation,
};
