import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { Reservation } from 'models';
import { FirestoreListenerActions } from 'modules/firebase';

import { ReservationsActions } from './actions';

import { ReservationsService } from '../services';

export const getAllReservations = () => (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const reservationsListener = getState().listeners.reservationsListener;
  if (reservationsListener) {
    return;
  }

  dispatch(ReservationsActions.addStateChanging());

  const successFunction = (data: Reservation[]) => {
    dispatch(
      ReservationsActions.addReservations(
        data.sort((a, b) => +b.createdAt - +a.createdAt),
      ),
    );
  };

  const errorFunction = (error: string) =>
    dispatch(ReservationsActions.addError(error));

  const listener = ReservationsService.Database.filterAndListen(
    successFunction,
    errorFunction,
  );
  dispatch(
    FirestoreListenerActions.SetListener('reservationsListener', listener),
  );

  return;
};

export const saveReservation = (data: Reservation) => async () => {
  const response = await ReservationsService.Database.add(data);
  if (typeof response === 'string') {
    return response;
  }

  return;
};

export const deleteReservation = (id: string) => () => {
  ReservationsService.Database.remove(id);
};

export const ReservationsThunks = {
  getAllReservations,
  saveReservation,
  deleteReservation,
};
