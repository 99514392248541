/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/core';
import React, { useCallback, useEffect, Fragment } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext, ErrorMessage } from 'react-hook-form';
import { MdDelete } from 'react-icons/md';

import { dropzone, buttons, spacers, form, loading } from 'styles';

interface Props {
  name: string;
  formIsLoading: boolean;
  firebasePhotoUrls?: string[];
  heroImageIndex?: number;
  uplaodImagesToStorageAndGetUrls: (
    id: string,
    files: File[],
  ) => Promise<string[] | undefined>;
  removeImagesFromStorage: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleDropzoneThumbnailClick: (index: number) => void;
  wrapperCss?: SerializedStyles | SerializedStyles[];
}

export const Dropzone: React.FC<Props> = ({
  name,
  firebasePhotoUrls,
  formIsLoading,
  heroImageIndex = 0,
  uplaodImagesToStorageAndGetUrls,
  removeImagesFromStorage,
  handleDropzoneThumbnailClick,
  wrapperCss,
}) => {
  const {
    errors,
    register,
    setValue,
    getValues,
    watch,
    reset,
  } = useFormContext();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const currentUrls: string[] = getValues()[name]
        ? [...getValues()[name]]
        : [];

      const docId: string = getValues().id;
      const photoUrls =
        (await uplaodImagesToStorageAndGetUrls(docId, acceptedFiles)) || [];
      setValue(name, [...currentUrls, ...photoUrls]);
    },
    [name, setValue, getValues, uplaodImagesToStorageAndGetUrls],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  useEffect(() => {
    register({ name: name });
    register('heroImageIndex');

    return () => {
      reset({ [name]: [], heroImageIndex: 0 });
    };
  }, [name, register, reset]);

  useEffect(() => {
    setValue('heroImageIndex', heroImageIndex);
  }, [setValue, heroImageIndex]);

  useEffect(() => {
    if (firebasePhotoUrls && firebasePhotoUrls.length) {
      setValue(name, firebasePhotoUrls);
    }
  }, [firebasePhotoUrls, name, setValue]);

  const photoUrls: string[] = watch(name);
  const heroImageNumber: number = watch('heroImageIndex');

  return (
    <div css={wrapperCss}>
      <div
        {...getRootProps()}
        css={!photoUrls?.length ? dropzone.wrapper : dropzone.container}
      >
        <input disabled={formIsLoading} {...getInputProps()} />
        {photoUrls?.length > 0 && (
          <div css={dropzone.imgWrapper}>
            <img
              src={
                photoUrls[heroImageNumber] ||
                require('assets/images/image-placeholder.png')
              }
              alt="main"
              css={[dropzone.mainImg, formIsLoading && form.disabled]}
            />
            <button
              type="button"
              css={dropzone.mainImgDeleteBtn}
              onClick={removeImagesFromStorage}
              data-index={heroImageNumber.toString()}
              data-name={name}
              data-url={photoUrls[heroImageNumber]}
            >
              <MdDelete size={16} />
            </button>

            {formIsLoading && (
              <div css={dropzone.imgLoadingOverlay}>
                <span css={loading.spinner} />
              </div>
            )}
          </div>
        )}

        {!photoUrls?.length && (
          <div css={dropzone.placeholder}>
            {isDragActive ? (
              <p>Povucite ovdje ...</p>
            ) : (
              <Fragment>
                <img
                  src={require('assets/images/dropzone-vector.svg')}
                  alt="povucite ovdje"
                  css={dropzone.placeholderImg}
                />
                <p css={dropzone.placeholderParagraph}>
                  Povucite ovdje datoteku kako biste učitali fotografije
                </p>
                <span css={dropzone.placeholderSpan}>ili</span>
                <button type="button" css={buttons.primary.small}>
                  Odaberite datoteku
                </button>
              </Fragment>
            )}

            {formIsLoading && (
              <div css={dropzone.imgLoadingOverlay}>
                <span css={loading.spinner} />
              </div>
            )}
          </div>
        )}
      </div>

      <div css={dropzone.thumbnailsWrapper}>
        {photoUrls?.length > 1 &&
          photoUrls.map((photo, index) => {
            return (
              <div key={index} css={dropzone.thumbContainer}>
                <button
                  type="button"
                  data-index={index.toString()}
                  data-name={name}
                  css={[
                    buttons.container,
                    dropzone.imgWrapper,
                    spacers.right.medium,
                  ]}
                  onClick={() => handleDropzoneThumbnailClick(index)}
                >
                  <img
                    src={photo}
                    alt={`thumbnail ${index}`}
                    css={[dropzone.thumbnail]}
                  />
                </button>
                <button
                  type="button"
                  css={dropzone.thumbImgDeleteBtn}
                  onClick={removeImagesFromStorage}
                  data-index={index.toString()}
                  data-name={name}
                  data-url={photo}
                >
                  <MdDelete size={12} />
                </button>
              </div>
            );
          })}
      </div>

      <ErrorMessage
        errors={errors || {}}
        name={name}
        message="Došlo je do pogreške"
        as={<p className="input--error" />}
      />
    </div>
  );
};
