import { Contact } from 'models';

import { ContactsActions } from './actions';
import { ContactsTypes } from './types';

export interface ContactsState {
  contactsAreChanging: boolean;
  error?: string;
  contacts: Contact[];
}

const INITIAL_STATE: ContactsState = {
  contactsAreChanging: true,
  error: undefined,
  contacts: [],
};

export default (
  state: ContactsState = INITIAL_STATE,
  action: ContactsActions,
) => {
  switch (action.type) {
    case ContactsTypes.AddRequest:
      return {
        ...state,
        contactsAreChanging: true,
        error: undefined,
      };

    case ContactsTypes.AddContacts:
      return {
        ...state,
        contactsAreChanging: false,
        error: undefined,
        contacts: action.payload.contacts,
      };

    case ContactsTypes.AddError:
      return {
        ...state,
        contactsAreChanging: false,
        error: action.payload.error,
      };

    default:
      return state || INITIAL_STATE;
  }
};
