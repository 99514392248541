import { css } from '@emotion/core';

import { unit, globalRadial } from './vars.styles';
import { color } from './mixins.styles';

/**
 * Label base
 */
const labelBase = css`
  display: inline-flex;
  align-items: center;

  padding: 0 ${unit * 3};
  height: ${unit * 7};
  font-size: 0.8rem;

  border-radius: ${globalRadial * 3}px;
  color: ${color('black', 0.8)};
`;

const primary = css`
  ${labelBase}

  background: ${color('red', 0.1)};
  border: 1px solid ${color('red', 0.2)};
`;

const sucesss = css`
  ${labelBase}

  background: ${color('green', 0.1)};
  border: 1px solid ${color('green', 0.2)};
`;

const note = css`
  ${labelBase}

  background: ${color('yellow', 0.1)};
  border: 1px solid ${color('yellow', 0.2)};
`;

const warning = css`
  ${labelBase}

  background: ${color('red', 0.1)};
  border: 1px solid ${color('red', 0.2)};
`;

/**
 * Labels with icons
 */
const labelWithIcon = css`
  ${labelBase}

  &span + svg {
    margin-left: ${unit}px;
  }
`;

/**
 * Sublabels
 */
const labelSub = css`
  ${labelBase}

  font-size: 11px;
  line-height: 16px;
  color: ${color('black', 0.4)};
  margin-top: ${unit}px;
`;

/**
 * Export all available button styles
 */
export const labels = {
  primary,
  sucesss,
  note,
  warning,
  labelWithIcon,
  labelSub,
};
