/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { wrappers, content, spacers, form } from 'styles';
import { RoutePath } from 'modules/navigation';
import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { withId } from 'utils';
import { Gallery, Loading, Helmet } from 'components';
import {
  DonationsThunks,
  ReserveDonation,
  CategoryButton,
} from 'modules/donations';
import { supply } from 'styles/supply.styles';
import { extractDocId } from 'utils/extractDocId';

export const SupplyDetails: React.FC = () => {
  const { id } = useParams();
  const docId = extractDocId(id);
  const dispatch = useDispatch<AppDispatch>();
  const { donationsAreChanging, donations } = useSelector(
    (state: ApplicationState) => state.donations,
  );
  const selectedDonation = donations.find(withId(docId));

  useEffect(() => {
    dispatch(DonationsThunks.getPublishedDonations());
  });

  if (!donationsAreChanging && !selectedDonation) {
    return <Redirect to={RoutePath.NotFound} />;
  }

  return (
    <section css={[wrappers.normal, content.padding]}>
      <Helmet
        title={selectedDonation?.name}
        description={selectedDonation?.description}
        ogImageUrl={selectedDonation?.photoUrls[0]}
      />

      <Loading isLoading={donationsAreChanging}>
        <div css={supply.goBack}>
          <Link to={RoutePath.Supply}>{'< Lista donacija'}</Link>
        </div>

        {selectedDonation && (
          <main css={supply.selectedDonationWrapper}>
            <div css={supply.headerMobile}>
              <h2 css={[form.header, spacers.bottom.large]}>
                {selectedDonation.name}
              </h2>
              <div css={supply.categoryInfo}>
                <CategoryButton
                  category={selectedDonation.category}
                  btnActive
                  disabled
                />
              </div>
            </div>

            <Gallery
              photos={selectedDonation.photoUrls}
              wrapperCss={supply.gallery}
              mainPhotoWrapperCss={supply.mainImg}
            />

            <div css={supply.formWrapper}>
              <div css={supply.headerDesktop}>
                <h2 css={[form.header, spacers.bottom.medium]}>
                  {selectedDonation.name}
                </h2>
                <div css={supply.categoryInfo}>
                  <CategoryButton
                    category={selectedDonation.category}
                    btnActive
                    disabled
                  />
                </div>
              </div>

              <p css={supply.descriptionTitle}>Opis</p>
              <p css={supply.description}>{selectedDonation.description}</p>

              <ReserveDonation donation={selectedDonation} />
            </div>
          </main>
        )}
      </Loading>
    </section>
  );
};
