import { createAction, ActionUnion } from 'modules/redux-store';
import { Donation, Category } from 'models';
import { PublishedFilter } from 'consts';

import { DonationsTypes } from './types';

const AddActions = {
  addRequest: () => createAction(DonationsTypes.AddRequest),
  addDonations: (donations: Donation[]) =>
    createAction(DonationsTypes.AddDonations, { donations }),
  addError: (error: string) => createAction(DonationsTypes.AddError, { error }),
  addFilters: (filters: Category[]) =>
    createAction(DonationsTypes.AddFilters, { filters }),
  addPublishedFilter: (filter: PublishedFilter) =>
    createAction(DonationsTypes.AddPublishedFilter, { filter }),
};

export const DonationsActions = {
  ...AddActions,
};

export type DonationsActions = ActionUnion<typeof DonationsActions>;
