import { css } from '@emotion/core';

import {
  unit,
  globalRadial,
  colors,
  fontFamily,
  fontWeights,
} from './vars.styles';
import { color } from './mixins.styles';
import { typescale } from './typescale.styles';
import { mqFrom } from './responsive.styles';
import { wrappers } from './wrapper.styles';
import { keyFrames } from './kf.styles';
import { dropzone } from './dropzone.styles';

const medic = require('assets/images/icon_medicinska_pomagala.svg');
const namjestaj = require('assets/images/icon_namjestaj.svg');
const volonteri = require('assets/images/icon_volonteri.svg');
const odjeca = require('assets/images/icon_odjeca.svg');

const wrapper = css`
  ${wrappers.fourItemsGrid}
  padding: 0;
`;

const item = css`
  margin-bottom: ${unit * 7}px;
  position: relative;

  img, div {
    width: 100%;
    height: auto;
    border-radius: ${globalRadial * 2 + 2}px;
    object-fit: cover;
    margin-bottom: ${unit * 3}px;
    background: ${colors.tertiary};

    ${mqFrom.Sml} {
      width: 273px;
      height: 200px;
    }
  }

  p {
    ${typescale.base}
    font-family: ${fontFamily.serif};
    color: ${color(colors.text, 0.9)};
    opacity: 0.9;
  }

  span {
    transition: transform 0.15s ease-out;
  }

  &:hover {
    span {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
`;

const category = css`
  position: absolute;
  top: ${unit * 3}px;
  right: ${unit * 3}px;
  z-index: 5;
  width: 32px;
  height: 32px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  content: '';

  ${mqFrom.Lrg} {
    width: 48px;
    height: 48px;
  }
`;

const Medicinska = css`
  ${category}

  background-image: url(${medic});
`;

const Namještaj = css`
  ${category}

  background-image: url(${namjestaj});
`;

const Volonteri = css`
  ${category}

  background-image: url(${volonteri});
`;

const Odjeća = css`
  ${category}

  background-image: url(${odjeca});
`;

const filters = css`
  margin-bottom: ${unit * 12}px;

  span {
    color: ${color(colors.text, 0.9)};
    opacity: 0.9;
    font-size: 14px;
    line-height: 20px;
  }

  button {
    margin-bottom: ${unit * 2}px;
  }
`;

const goBack = css`
  margin-bottom: ${unit * 5}px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  font-weight: ${fontWeights.weightBold};
  opacity: 0.5;
  transition: opacity 0.15s ease-out;

  &:hover {
    opacity: 0.7;
  }

  a {
    color: ${color(colors.text, 0.9)};
  }

  ${mqFrom.Xlrg} {
    margin-bottom: ${unit * 10}px;
  }
`;

const categoryInfo = css`
  margin-bottom: ${unit * 6}px;

  ${mqFrom.Xlrg} {
    margin-bottom: ${unit * 12}px;
  }

  span {
    &:hover {
      cursor: default;
      color: ${color(colors.text, 0.6)};
    }
  }
`;

const descriptionTitle = css`
  margin-bottom: ${unit * 4}px;
  ${typescale.medium}
  color: ${color(colors.text, 0.9)};
  opacity: 0.8;
  font-family: ${fontFamily.serif};
`;

const description = css`
  ${typescale.base}
  margin-bottom: ${unit * 8}px;
  color: ${color(colors.text, 0.9)};
  opacity: 0.7;

  ${mqFrom.Xlrg} {
    margin-bottom: ${unit * 20}px;
  }
`;

const filtersFlex = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > div:last-of-type {
    width: 30%;
  }
`;

const gallery = css`
  margin-bottom: ${unit * 10}px;

  ${mqFrom.Sml} {
    width: 376px;
  }
`;

const reserveTitle = css`
  margin-bottom: ${unit * 7}px;
  color: ${color(colors.text, 0.9)};
  ${typescale.medium}
  font-family: ${fontFamily.serif};
`;

const selectedDonationWrapper = css`
  ${mqFrom.Lrg} {
    display: flex;
    justify-content: flex-start;
  }
`;

const headerMobile = css`
  ${mqFrom.Lrg} {
    display: none;
  }
`;

const headerDesktop = css`
  display: none;

  ${mqFrom.Lrg} {
    display: block;
  }
`;

const reserveWrapper = css`
  animation: ${keyFrames.appear} 1s ease-out;
`;

const formWrapper = css`
  ${mqFrom.Lrg} {
    max-width: 582px;
    margin-left: 140px;
  }
`;

const mainImg = css`
  ${dropzone.mainImg}
  width: 100%;
  max-width: 376px;
  height: 376px;

  &:hover {
    cursor: default;
    border: none;
  }
`;

export const supply = {
  wrapper,
  item,
  Medicinska,
  Namještaj,
  Volonteri,
  Odjeća,
  filters,
  goBack,
  categoryInfo,
  descriptionTitle,
  description,
  filtersFlex,
  gallery,
  reserveTitle,
  selectedDonationWrapper,
  headerMobile,
  headerDesktop,
  reserveWrapper,
  formWrapper,
  mainImg,
};
