import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { News } from 'models';
import {
  FirestoreListenerActions,
  FirestoreQueryParam,
} from 'modules/firebase';
import { withId } from 'utils';

import { NewsActions } from './actions';

import { NewsService } from '../services';

export const getAllNews = () => (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const newsListener = getState().listeners.newsListener;
  if (newsListener) {
    return;
  }

  dispatch(NewsActions.addRequest());

  const successFunction = (data: News[]) => {
    dispatch(
      NewsActions.addNews(
        data.sort(
          (a, b) =>
            +(b.createdAt as firebase.firestore.Timestamp)?.toDate() -
            +(a.createdAt as firebase.firestore.Timestamp)?.toDate(),
        ),
      ),
    );
  };

  const errorFunction = (error: string) =>
    dispatch(NewsActions.addError(error));

  const listener = NewsService.Database.filterAndListen(
    successFunction,
    errorFunction,
  );
  dispatch(FirestoreListenerActions.SetListener('newsListener', listener));

  return;
};

export const getPublishedNews = () => (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const publishedNewsListener = getState().listeners.publishedNewsListener;
  if (publishedNewsListener) {
    return;
  }

  dispatch(NewsActions.addRequest());

  const successFunction = (data: News[]) => {
    dispatch(
      NewsActions.addNews(
        data.sort(
          (a, b) =>
            +(b.dateOfPublishing as firebase.firestore.Timestamp)?.toDate() -
            +(a.dateOfPublishing as firebase.firestore.Timestamp)?.toDate(),
        ),
      ),
    );
  };

  const errorFunction = (error: string) =>
    dispatch(NewsActions.addError(error));

  const listener = NewsService.Database.filterAndListen(
    successFunction,
    errorFunction,
    new FirestoreQueryParam('published', '==', true),
  );
  dispatch(
    FirestoreListenerActions.SetListener('publishedNewsListener', listener),
  );

  return;
};

export const saveNews = (news: News) => async () => {
  const response = await NewsService.Database.update(news);
  if (typeof response === 'string') {
    return response;
  }

  return;
};

export const deleteNews = (id: string) => () => {
  NewsService.Database.remove(id);
};

export const publishOrUnpublishedNews = (docId: string) => async (
  _dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const selectedNews = getState().news.news.find(withId(docId));
  if (!selectedNews) return;

  if (selectedNews.published) {
    const response = await NewsService.Database.unpublish(docId);
    return response;
  }

  const response = await NewsService.Database.publish(docId);
  return response;
};

export const NewsThunks = {
  getAllNews,
  getPublishedNews,
  saveNews,
  deleteNews,
  publishOrUnpublishedNews,
};
