/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { buttons, form } from 'styles';

interface Props {
  title: string;
  formError?: string;
  btnText?: string;
  handleReturn?: VoidFunction;
}

export const CompletedFormMessage = React.forwardRef(
  ({ title, formError, btnText, handleReturn }: Props, ref?: any) => (
    <div ref={ref} css={formError ? form.error : form.complete}>
      {title && !formError && <p>{title}</p>}
      {formError && <p>{formError}</p>}
      {handleReturn && btnText && (
        <button
          type="button"
          onClick={handleReturn}
          css={buttons.primary.medium}
        >
          {btnText}
        </button>
      )}
    </div>
  ),
);
