enum types {
  AddRequest = 'news/ADD_REQUEST',
  AddNews = 'news/ADD_NEWS',
  AddPublishFilter = 'news/ADD_PUBLISH_FILTER',
  AddError = 'news/ADD_ERROR',
}

export const NewsTypes = {
  ...types,
};
