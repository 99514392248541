/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { buttons, wrappers, home } from 'styles';
import { RoutePath } from 'modules/navigation';

export const AboutProject: React.FC = () => (
  <div css={home.aboutBg}>
    <div css={wrappers.normal}>
      <div css={home.aboutWrapper}>
        <div css={home.aboutInfo}>
          <h3>O projektu</h3>
          <p>
            Udruga Prijatelji sv. Martina i grad Beli Manastir zajedno koračaju
            prema sigurnijoj socijalnoj budućnosti. Cilj nam je unaprijediti
            socijalne usluge na području Belog Manastira te osigurati prijeko
            potrebne potrepštine i bazu volontera za potrebite.
          </p>

          <Link to={RoutePath.About} css={buttons.primary.medium}>
            Pročitaj više
          </Link>
        </div>

        <img src={require('assets/images/o_projektu.png')} alt="O projektu" />
      </div>
    </div>
  </div>
);
