/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect, Link } from 'react-router-dom';
import { format } from 'date-fns';
import Linkify from 'react-linkify';

import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { withId } from 'utils';
import { RoutePath } from 'modules/navigation';
import { wrappers, content, newsStyles } from 'styles';
import { Gallery, Loading, Helmet } from 'components';
import {
  RelatedAnnouncements,
  AnnouncementsThunks,
} from 'modules/announcements';
import { extractDocId } from 'utils/extractDocId';

export const AnnouncementDetails: React.FC = () => {
  const { id } = useParams();
  const docId = extractDocId(id);
  const dispatch = useDispatch<AppDispatch>();
  const { announcementsAreChanging, announcements } = useSelector(
    (state: ApplicationState) => state.announcements,
  );
  const selectedAnnouncement = announcements.find(withId(docId));

  useEffect(() => {
    dispatch(AnnouncementsThunks.getPublishedAnnouncements());
  }, [dispatch]);

  if (!announcementsAreChanging && !selectedAnnouncement) {
    return <Redirect to={RoutePath.NotFound} />;
  }

  return (
    <section css={[wrappers.normal, content.padding]}>
      <Helmet
        title={selectedAnnouncement?.title}
        description={selectedAnnouncement?.description}
        ogImageUrl={selectedAnnouncement?.photoUrls[0]}
      />

      <Loading isLoading={announcementsAreChanging}>
        {selectedAnnouncement && (
          <div css={newsStyles.detailsWrapper}>
            <div css={newsStyles.detailsGoBack}>
              <Link to={RoutePath.Announcements}>{'< Najave'}</Link>
            </div>

            <div css={newsStyles.detailsInfo}>
              <Gallery
                photos={selectedAnnouncement.photoUrls}
                wrapperCss={newsStyles.detailsGallery}
                mainPhotoWrapperCss={newsStyles.detailsMainImg}
                heroImageIndex={selectedAnnouncement.heroImageIndex}
              />

              <p css={newsStyles.itemDate}>
                {format(
                  (selectedAnnouncement.dateOfPublishing as firebase.firestore.Timestamp).toDate(),
                  'dd.LL.yyyy',
                )}
              </p>

              <div css={newsStyles.detailsInfoFlex}>
                <p css={newsStyles.detailsTitleResponsive}>
                  {selectedAnnouncement.title}
                </p>
                <div css={newsStyles.contentWrapper}>
                  <Linkify>
                    <p css={newsStyles.detailsDescription}>
                      {selectedAnnouncement.description}
                    </p>
                  </Linkify>
                  {Boolean(selectedAnnouncement.files) && (
                    <ul css={newsStyles.filesList}>
                      {selectedAnnouncement.files.map(({ url, name }, i) => (
                        <li key={i}>
                          <a
                            href={url}
                            target="blank"
                            rel="noopener noreferrer"
                          >
                            {name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <RelatedAnnouncements />
      </Loading>
    </section>
  );
};
