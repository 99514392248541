import { FirestoreService, StorageService } from 'modules/firebase';
import { Collection } from 'consts';
import { Announcement } from 'models';

const Database = FirestoreService<Announcement>(Collection.Announcements);

const Storage = StorageService(Collection.Announcements);

export const AnnouncementsService = {
  Database,
  Storage,
};
