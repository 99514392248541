import firebase from 'firebase/app';
import { FieldValue } from 'react-hook-form';

import { NewsFile } from './NewsFile';

export interface News {
  id: string;
  photoUrls: string[];
  published: boolean;
  title: string;
  description: string;
  files: NewsFile[];
  heroImageIndex: number;
  dateOfPublishing: FieldValue<firebase.firestore.Timestamp>;
  createdAt: FieldValue<firebase.firestore.Timestamp>;
}

export class News {
  constructor(object?: Partial<News>) {
    if (object?.id) {
      this.id = object.id;
    }

    this.photoUrls = object?.photoUrls || [];
    this.published = object?.published || false;
    this.title = object?.title || '';
    this.description = object?.description || '';
    this.files = object?.files || [];
    this.heroImageIndex = object?.heroImageIndex || 0;
    this.dateOfPublishing = firebase.firestore.FieldValue.serverTimestamp();
    this.createdAt =
      object?.createdAt || firebase.firestore.FieldValue.serverTimestamp();
  }
}
