import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { Contact } from 'models';
import { FirestoreListenerActions } from 'modules/firebase';

import { ContactsActions } from './actions';

import { ContactsService } from '../services';

export const getAllContacts = () => (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const contactsListener = getState().listeners.contactsListener;
  if (contactsListener) {
    return;
  }

  dispatch(ContactsActions.addRequest());

  const successFunction = (data: Contact[]) => {
    dispatch(
      ContactsActions.addContacts(
        data.sort((a, b) => +b.createdAt - +a.createdAt),
      ),
    );
  };

  const errorFunction = (error: string) =>
    dispatch(ContactsActions.addError(error));

  const listener = ContactsService.Database.filterAndListen(
    successFunction,
    errorFunction,
  );
  dispatch(FirestoreListenerActions.SetListener('contactsListener', listener));

  return;
};

export const saveContact = (data: Contact) => async () => {
  const response = await ContactsService.Database.add(data);
  if (typeof response === 'string') {
    return response;
  }

  return;
};

export const deleteContact = (id: string) => () => {
  ContactsService.Database.remove(id);
};

export const ContactsThunks = {
  getAllContacts,
  saveContact,
  deleteContact,
};
