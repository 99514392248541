/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Request } from 'models';
import { usePagination } from 'hooks';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { RequestsThunks, RequestsSelectors } from 'modules/requests';
import {
  PageHeader,
  Loading,
  FormError,
  EmptyListState,
  DashboardList,
  Pagination,
} from 'components';
import { content } from 'styles';
import { RoutePath } from 'modules/navigation';
import { SearchActions } from 'modules/search';

export const DashboardDemand: React.FC = () => {
  const { requests, requestsAreChanging, error } = useSelector(
    (state: ApplicationState) => state.requests,
  );
  const filteredRequests = useSelector((state: ApplicationState) =>
    RequestsSelectors.getRequestsByQuery(state),
  );
  const dispatch = useDispatch<AppDispatch>();
  const {
    currentPage,
    maxPages,
    next,
    previous,
    selectPage,
    selectedData,
  } = usePagination<Request>(filteredRequests, 16);

  useEffect(() => {
    dispatch(RequestsThunks.getAllRequests());
  }, [dispatch]);

  return (
    <section css={content.area}>
      <PageHeader
        onSearch={handleSearch}
        title="Potražnja donacija i volontera"
      />

      <Loading isLoading={requestsAreChanging}>
        <FormError error={error} />
        <EmptyListState
          data={requests}
          title="Trenutno nema donacija i volontera u potražnji"
        />
        {requests.length > 0 && (
          <EmptyListState
            data={selectedData()}
            title="Nema rezultata za navedene filtre"
          />
        )}

        <DashboardList
          data={selectedData()}
          path={RoutePath.DashboardDemand}
          handleDelete={handleDelete}
        />

        <Pagination
          currentPage={currentPage}
          maxPages={maxPages}
          previous={previous}
          next={next}
          selectPage={selectPage}
        />
      </Loading>
    </section>
  );

  function handleSearch(query: string) {
    dispatch(SearchActions.querySearch(query));
  }

  function handleDelete(id?: string) {
    if (!id) return;

    dispatch(RequestsThunks.deleteRequest(id));
  }
};
