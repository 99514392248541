import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AppDispatch } from 'modules/redux-store';
import { DonationsThunks, DonationsService } from 'modules/donations';
import {
  Donation,
  Contact,
  Request,
  Reservation,
  News,
  NewsFile,
} from 'models';
import { ContactsThunks } from 'modules/contacts/redux';
import { RequestsThunks } from 'modules/requests';
import { ReservationsService, ReservationsThunks } from 'modules/reservations';
import { RoutePath } from 'modules/navigation';
import {
  AnnouncementsThunks,
  AnnouncementsService,
} from 'modules/announcements';
import { NewsThunks, NewsService, NewsState } from 'modules/news';

import { usePageModified } from './usePageModified';

interface State {
  loading: boolean;
  formCompleted: boolean;
  formError?: string;
}

export function useFormLogic<T extends any>(
  backRoutePath?: RoutePath,
  formRef?: HTMLFormElement | null,
  alertRef?: HTMLDivElement | null,
) {
  const [state, setState] = useState<State>({
    loading: false,
    formCompleted: false,
  });
  const dispatch = useDispatch<AppDispatch>();
  const [pageModified, setPageModified] = usePageModified(formRef);
  const methods = useForm<T>({
    mode: 'onBlur',
    validateCriteriaMode: 'all',
  });
  const { setValue, getValues } = methods;
  const { push } = useHistory();
  const { id: itemId } = useParams();

  useEffect(() => {
    if (!state.formCompleted) return;

    const isMobile = window.innerWidth <= 786;

    if (isMobile && alertRef) {
      window.scrollTo({
        top: alertRef.offsetTop - 20,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [state.formCompleted, alertRef]);

  const handleResponse = useCallback(
    (response?: string | Record<string, any>) => {
      if (!response || typeof response !== 'string') {
        setState(s => ({
          ...s,
          loading: false,
          formCompleted: true,
          formError: undefined,
          photoFiles: undefined,
          uploadProgress: undefined,
        }));

        if (backRoutePath) push(backRoutePath);
        return;
      }

      setState(s => ({
        ...s,
        loading: false,
        formError:
          'Došlo je do pogreške s Vašim zahtjevom, molimo Vas pokušajte ponovo.',
      }));
    },
    [push, backRoutePath],
  );

  const handlePublishSwitch = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setPageModified(true);

      setValue('published', checked);
    },
    [setValue, setPageModified],
  );

  const uplaodFilesToStorageAndGetUrls = useCallback(
    async (uploadTasks: firebase.storage.UploadTask[]) => {
      setPageModified(true);
      setState(s => ({ ...s, loading: true }));
      const uploadPromises = uploadTasks.map(task => {
        return new Promise(
          (resolve: (url: string) => void, reject: (error: string) => void) => {
            task.on(
              'state_changed',
              () => undefined,
              error => reject(error.message),
              async () => {
                const downloadUrl: string = await task.snapshot.ref.getDownloadURL();
                resolve(downloadUrl);
              },
            );
          },
        );
      });

      const downloadRefs = await Promise.all(uploadPromises);
      setState(s => ({
        ...s,
        loading: false,
      }));
      return downloadRefs;
    },
    [setPageModified],
  );

  const uploadDonatinImages = useCallback(
    async (id: string, files: File[]) => {
      const uploadTasks = DonationsService.Storage.addFilesAndGetUploadTasks(
        id,
        files,
      );

      if (!uploadTasks) return;

      return uplaodFilesToStorageAndGetUrls(uploadTasks);
    },
    [uplaodFilesToStorageAndGetUrls],
  );

  const uploadNewsFiles = useCallback(
    async (id: string, files: File[]) => {
      const uploadTasks = NewsService.Storage.addFilesAndGetUploadTasks(
        id,
        files,
      );

      if (!uploadTasks) return;

      return uplaodFilesToStorageAndGetUrls(uploadTasks);
    },
    [uplaodFilesToStorageAndGetUrls],
  );

  const uploadAnnouncementFiles = useCallback(
    async (id: string, files: File[]) => {
      const uploadTasks = AnnouncementsService.Storage.addFilesAndGetUploadTasks(
        id,
        files,
      );

      if (!uploadTasks) return;

      return uplaodFilesToStorageAndGetUrls(uploadTasks);
    },
    [uplaodFilesToStorageAndGetUrls],
  );

  const removeImagesFromDonations = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const { index, url, name } = event.currentTarget.dataset;
      if (!index || !url || !name) return;

      setPageModified(true);
      const currentUrls: string[] = [...getValues()[name]];
      currentUrls.splice(parseInt(index), 1);
      setValue(name, currentUrls);

      DonationsService.Storage.deleteFile(url);
    },
    [getValues, setValue, setPageModified],
  );

  const removeFilesFromNews = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const { index, url, name } = event.currentTarget.dataset;

      if (!index || !url || !name) return;

      setPageModified(true);
      const currentUrls: (string | NewsState)[] = [...getValues()[name]];
      currentUrls.splice(parseInt(index), 1);
      setValue(name, currentUrls);

      NewsService.Storage.deleteFile(url);
    },
    [setValue, getValues, setPageModified],
  );

  const removeFilesFromAnnouncements = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const { index, url, name } = event.currentTarget.dataset;
      if (!index || !url || !name) return;

      setPageModified(true);
      const currentUrls: (string | NewsFile)[] = [...getValues()[name]];
      currentUrls.splice(parseInt(index), 1);
      setValue(name, currentUrls);

      AnnouncementsService.Storage.deleteFile(url);
    },
    [getValues, setValue, setPageModified],
  );

  const handleDropzoneThumbnailClick = useCallback(
    (index: number) => {
      setValue('heroImageIndex', index);
    },
    [setValue],
  );

  const saveDonation = useCallback(
    async (data: T) => {
      setPageModified(false);
      setState(s => ({ ...s, loading: true }));
      const request = new Donation({ ...data });
      const response = await dispatch(DonationsThunks.saveDonation(request));
      handleResponse(response);
    },
    [dispatch, handleResponse, setPageModified],
  );

  const saveRequest = useCallback(
    async (data: T) => {
      setPageModified(false);
      setState(s => ({ ...s, loading: true }));
      const request = new Request(data);
      const response = await dispatch(RequestsThunks.saveRequest(request));
      handleResponse(response);
    },
    [dispatch, handleResponse, setPageModified],
  );

  const saveAnnouncement = useCallback(
    async (data: T) => {
      setPageModified(false);
      setState(s => ({ ...s, loading: true }));
      const request = new News(data);
      const response = await dispatch(
        AnnouncementsThunks.saveAnnouncement(request),
      );
      handleResponse(response);
    },
    [dispatch, handleResponse, setPageModified],
  );

  const saveNews = useCallback(
    async (data: T) => {
      setPageModified(false);
      setState(s => ({ ...s, loading: true }));
      const request = new News(data);
      const response = await dispatch(NewsThunks.saveNews(request));
      handleResponse(response);
    },
    [dispatch, handleResponse, setPageModified],
  );

  const saveContact = useCallback(
    async (data: T) => {
      setPageModified(false);
      setState(s => ({ ...s, loading: true }));
      const request = new Contact(data);
      const response = await dispatch(ContactsThunks.saveContact(request));
      handleResponse(response);
    },
    [dispatch, handleResponse, setPageModified],
  );

  const deleteDonation = useCallback(() => {
    if (!itemId) return;

    dispatch(DonationsThunks.deleteDonation(itemId));

    if (backRoutePath) push(backRoutePath);
  }, [dispatch, backRoutePath, itemId, push]);

  const deleteRequest = useCallback(() => {
    if (!itemId) return;

    dispatch(RequestsThunks.deleteRequest(itemId));

    if (backRoutePath) push(backRoutePath);
  }, [dispatch, backRoutePath, itemId, push]);

  const deleteReservation = useCallback(() => {
    if (!itemId) return;

    dispatch(ReservationsThunks.deleteReservation(itemId));

    if (backRoutePath) push(backRoutePath);
  }, [dispatch, backRoutePath, itemId, push]);

  const deleteAnnouncement = useCallback(() => {
    if (!itemId) return;

    dispatch(AnnouncementsThunks.deleteAnnouncement(itemId));

    if (backRoutePath) push(backRoutePath);
  }, [dispatch, backRoutePath, itemId, push]);

  const deleteNews = useCallback(() => {
    if (!itemId) return;

    dispatch(NewsThunks.deleteNews(itemId));

    if (backRoutePath) push(backRoutePath);
  }, [dispatch, backRoutePath, itemId, push]);

  const deleteContact = useCallback(() => {
    if (!itemId) return;

    dispatch(ContactsThunks.deleteContact(itemId));

    if (backRoutePath) push(backRoutePath);
  }, [dispatch, backRoutePath, itemId, push]);

  const handleReturnToForm = useCallback(() => {
    setState(s => ({ ...s, formCompleted: false }));
  }, []);

  const reserveDonation = useCallback(
    async (data: T) => {
      setState(s => ({ ...s, loading: true }));
      const request = new Reservation(data);
      const response = await ReservationsService.Database.add(request);
      handleResponse(response);
    },
    [handleResponse],
  );

  return {
    methods,
    pageModified,
    formIsLoading: state.loading,
    formCompleted: state.formCompleted,
    formError: state.formError,
    handlePublishSwitch,
    uploadDonatinImages,
    uploadNewsFiles,
    uploadAnnouncementFiles,
    removeImagesFromDonations,
    removeFilesFromNews,
    removeFilesFromAnnouncements,
    handleDropzoneThumbnailClick,
    saveDonation,
    saveContact,
    saveRequest,
    saveAnnouncement,
    saveNews,
    reserveDonation,
    deleteDonation,
    deleteRequest,
    deleteReservation,
    deleteAnnouncement,
    deleteNews,
    deleteContact,
    handleReturnToForm,
  };
}
