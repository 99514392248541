import { css } from '@emotion/core';

import { unit, colors } from './vars.styles';
import { color } from './mixins.styles';

const padding = css`
  padding-top: ${unit * 9}px;
  padding-bottom: ${unit * 13}px;
`;

const area = css`
  padding: ${unit * 10}px 0;
`;

const heading = css`
  padding-bottom: ${unit * 8}px;
  margin-bottom: ${unit * 10}px;
  border-bottom: 1px solid ${color(colors.dark, 0.1)};
`;

const headingMain = css`
  display: flex;
  justify-content: space-between;
`;

const headingContent = css`
  ${headingMain}

  align-items: center;
`;

const headingSearch = css`
  margin-top: ${unit * 8}px;
`;

export const content = {
  padding,
  area,
  heading,
  headingMain,
  headingContent,
  headingSearch,
};
