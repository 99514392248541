import { User } from 'firebase';

import { createAction, ActionUnion } from 'modules/redux-store';

import { AuthTypes } from './types';

export const AuthActions = {
  authStateChange: () => createAction(AuthTypes.AuthStateChange),
  loginSuccess: (user: User) => createAction(AuthTypes.LoginSuccess, { user }),
  loginError: (error?: string) => createAction(AuthTypes.LoginError, { error }),
  logout: () => createAction(AuthTypes.Logout),
};

export type AuthActions = ActionUnion<typeof AuthActions>;
