import { createAction, ActionUnion } from 'modules/redux-store';
import { Contact } from 'models';

import { ContactsTypes } from './types';

const AddActions = {
  addRequest: () => createAction(ContactsTypes.AddRequest),
  addContacts: (contacts: Contact[]) =>
    createAction(ContactsTypes.AddContacts, { contacts }),
  addError: (error: string) => createAction(ContactsTypes.AddError, { error }),
};

export const ContactsActions = {
  ...AddActions,
};

export type ContactsActions = ActionUnion<typeof ContactsActions>;
