import { createAction, ActionUnion } from 'modules/redux-store';
import { Request } from 'models';

import { RequestsTypes } from './types';

const AddActions = {
  addStateChanging: () => createAction(RequestsTypes.AddStateChanging),
  addRequests: (requests: Request[]) =>
    createAction(RequestsTypes.AddRequests, { requests }),
  addError: (error: string) => createAction(RequestsTypes.AddError, { error }),
};

export const RequestsActions = {
  ...AddActions,
};

export type RequestsActions = ActionUnion<typeof RequestsActions>;
