import { css } from '@emotion/core';

import { color } from './mixins.styles';
import { typescale } from './typescale.styles';
import { colors, unit } from './vars.styles';

const list = css`
  position: relative;
  margin-top: 0;
  margin-bottom: 12px;
  padding: 0;
`;

const listItem = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const button = css`
  background: white;
  border-radius: 50%;
  border: 1px solid ${color(colors.dark, 0.1)};
  padding: ${unit}px;
  width: 30px;
  height: 30px;
  transition: transform 0.15s ease-out;
  cursor: pointer;

  &:hover {
    transform: scale3d(1.2, 1.2, 1);
  }
`;

const wrapper = css`
  margin: 20px 0;
`;

const title = css`
  margin-bottom: ${unit * 2}px;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  ${typescale.tiny}
  color: ${color(colors.text, 0.9)};
  margin-bottom: 8px;
`;

const placeholder = css`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  ${typescale.tiny}
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${color(colors.text, 0.9)};
  border: 2px dashed rgba(13, 13, 13, 0.2);
  border-radius: 10px;
  cursor: pointer;
`;

const placeholderParagraph = css`
  display: flex;
  align-items: center;
  gap: 8px;
  ${typescale.tiny}
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${color(colors.text, 0.9)};
  opacity: 0.7;
`;

const placeholderSpan = css`
  margin: ${unit * 3}px 0;
  opacity: 0.7;
  text-transform: lowercase;
`;

export const fileUpload = {
  list,
  listItem,
  button,
  wrapper,
  title,
  placeholder,
  placeholderParagraph,
  placeholderSpan,
};
