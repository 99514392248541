import createCachedSelector from 're-reselect';

import { ApplicationState } from 'modules/redux-store';
import { getSearchQuery, applySearchParams } from 'modules/search';

const contacts = (state: ApplicationState) => state.contacts.contacts;

const getContactsByQuery = createCachedSelector(
  contacts,
  getSearchQuery,
  (contacts, query) => {
    return contacts.filter(({ title }) => applySearchParams([title], query));
  },
)((_news, query) => `${query}`);

export const ContactsSelectors = {
  getContactsByQuery,
};
