import { css } from '@emotion/core';

import { colors, unit } from './vars.styles';

const wrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primary};

  span {
    margin-left: ${unit * 2}px;
    margin-right: ${unit * 2}px;
    cursor: pointer;
  }
`;

const currentPage = css`
  color: ${colors.text};
`;

export const pagination = {
  wrapper,
  currentPage,
};
