import { keyframes } from '@emotion/core';

const appear = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
`;

const appear2 = keyframes`
  0% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
`;

const appear3 = keyframes`
  0% {
    opacity: 0
  }
  66% {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
`;

const showUp = keyframes`
  0% {
    transform: translateY(20px);
    animation-timing-function: ease-out;
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const modalShowUp = keyframes`
  0% {
    transform: translate(-50%, -40%);
    animation-timing-function: ease-out;
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const showRight = keyframes`
  0% {
    transform: translateX(20px);
    animation-timing-function: ease-out;
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`;

export const keyFrames = {
  appear,
  appear2,
  appear3,
  showUp,
  modalShowUp,
  showRight,
};
