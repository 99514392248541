/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { spacers } from 'styles';
import { isCategory } from 'models';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { supply } from 'styles/supply.styles';

import { CategoryButton } from './CategoryButton';

import { DonationsThunks } from '../redux';

export const SupplyFilters: React.FC = () => {
  const { filters } = useSelector((state: ApplicationState) => state.donations);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div css={supply.filters}>
      <span css={[spacers.right.xLarge]}>Filtriranje</span>
      <CategoryButton
        category="Medicinska pomagala"
        btnActive={filters.includes('Medicinska pomagala')}
        onClick={handleFilterChange}
      />

      <CategoryButton
        category="Namještaj"
        btnActive={filters.includes('Namještaj')}
        onClick={handleFilterChange}
      />

      <CategoryButton
        category="Odjeća"
        btnActive={filters.includes('Odjeća')}
        onClick={handleFilterChange}
      />

      <CategoryButton
        category="Volonteri"
        btnActive={filters.includes('Volonteri')}
        onClick={handleFilterChange}
      />
    </div>
  );

  function handleFilterChange(event: React.MouseEvent<HTMLButtonElement>) {
    const { category } = event.currentTarget.dataset;
    if (!category || !isCategory(category)) return;

    dispatch(DonationsThunks.updateFilters(category));
  }
};
