/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { spacers, privacyp } from 'styles';
import { FormHeader, Helmet } from 'components';

export const PrivacyPolicy: React.FC = () => {
  return (
    <section css={privacyp.wrapper}>
      <Helmet
        title="Socijalna burza - Politika privatnosti"
        description="Politika privatnosti donosi odredbe o zaštiti prikupljenih podataka te upotrebi istih."
      />

      <FormHeader title="Politika privatnosti" />

      <p css={privacyp.listDescription}>
        Udruga Prijatelji svetog Martina (dalje: Udruga), Trg slobode 16 a, Beli
        Manastir, tel: +385 97 698 2901, e-mail: prijatelji.sv.martina@gmail.com
      </p>

      <ol css={privacyp.ol}>
        <li>
          <p css={privacyp.listTitle}>
            PODACI O SLUŽBENIKU ZA ZAŠTITU PODATAKA
          </p>
        </li>
        <p css={[privacyp.listDescription, spacers.top.huge]}>
          tel: +385 97 698 2901, e-mail: prijatelji.sv.martina@gmail.com
        </p>

        <li>
          <p css={privacyp.listTitle}>
            SVRHA I PRAVNI TEMELJI ZA OBRADU OSOBNIH PODATAKA
          </p>
          <ol css={[privacyp.ol, spacers.top.large]}>
            <li>
              Vaše osobne podatke koji su nužni u svrhu izvršavanja službenih
              zadaća i ovlasti Udruge obrađujemo u smislu članka 6. stavka 1.
              točke (e) UREDBE (EU) 2016/679 EUROPSKOG PARLAMENTA I VIJEĆA od
              27. travnja 2016. o zaštiti pojedinaca u vezi s obradom osobnih
              podataka i o slobodnom kretanju takvih podataka te o stavljanju
              izvan snage Direktive 95/46/EZ (dalje u tekstu: Opća Uredba).
              Navedene zadaće i ovlasti propisane su odredbama Opće uredbe te
              Zakona o provedbi Opće uredbe, a iste uključuju:
              <p>
                — prikupljanje i upotreba informacija prilikom prijave predmeta
                za donaciju i prijava za volontiranje;
              </p>
              <p>
                — prikupljanje i upotreba informacija prilikom upita za donaciju
                ponuđenih predmeta i upita za potrebu volonterske pomoći;
              </p>
              <p>
                — prikupljanje informacija dobivenih kroz kontakt formu kako bi
                se ostvario daljnji kontakt;
              </p>
            </li>

            <li>
              Vaše osobne podatke obrađujemo ispunjavajući svoje pravne obveze u
              smislu članka 6. stavka 1. točke (c) Opće uredbe u svrhu:
              <p>— odgovaranja na upite dobivene putem kontakt forme;</p>
              <p>— dogovaranje donacija predmeta;</p>
              <p>— dogovaranje pomoći volontera;</p>
            </li>

            <li>
              Vaše osobne podatke obrađujemo temeljem legitimnog interesa u
              smislu članka 6. stavka 1. točke (f) Opće uredbe, u svrhu:
              <p>
                — sudjelovanja na bilo koji način u svim oblicima aktivnosti
                Udruge
              </p>
            </li>

            <li>
              Vaše osobne podatke obrađujemo temeljem privole u smislu članka 6.
              stavka 1. točke (a) Opće uredbe, u svrhu:
              <p>
                — boljeg funkcioniranja svih značajki internetskih stranica i
                omogućavanja boljeg korisničkog iskustva
              </p>
            </li>
          </ol>
        </li>

        <li>
          <p css={privacyp.listTitle}>KORIŠTENJE KOLAČIĆA (COOKIES)</p>

          <p css={[spacers.top.large]}>
            — na službenim internetskim stranicama www. koriste se tzv. kolačići
            (cookies) - tekstualne datoteke koje na računalo korisnika smješta
            internetski poslužitelj (server), putem kojeg davatelj usluge
            pristupa Internetu (ISP) prikazuje web stranicu.
          </p>
          <p>
            — kolačići nastaju kada preglednik na uređaju korisnika učita
            posjećeno mrežno odredište, koje potom šalje podatke pregledniku te
            izrađuje tekstualnu datoteku (kolačić). Preglednik dohvaća i šalje
            kolačić na poslužitelj internetske stranice prilikom povratka
            korisnika na njega.
          </p>
          <p>
            — Na našim stranicama koriste se tehnički kolačići (obavezni
            kolačići, ne mogu se isključiti) koji su nužni za funkcioniranje
            Internet mjesta te pružanja boljeg korisničkog iskustva.
          </p>
          <p>
            {' '}
            — više informacija možete pronaći na sljedećoj poveznici:
            https://.(???)
          </p>
        </li>

        <li>
          <p css={privacyp.listTitle}>
            KOJA SU VAŠA PRAVA U VEZI OBRADE OSOBNIH PODATAKA
          </p>
          <p css={[privacyp.listDescription, spacers.top.huge]}>
            Za ostvarivanje svojih prava možete nam se obratiti pisanim putem
            ili putem elektroničke pošte koristeći naše kontakt podatke koji su
            navedeni na sljedećoj poveznici: https://
          </p>
          <p>Vaša prava su:</p>
          <ol css={[privacyp.ol, spacers.top.large]}>
            <li>
              Pravo na pristup osobnim podacima
              <p>
                Imate pravo na pristup svojim osobnim podacima koje obrađujemo o
                Vama i možete zatražiti detaljne informacije osobito o njihovoj
                svrsi obrade, o vrsti/kategorijama osobnih podataka koji se
                obrađuju uključujući i uvid u svoje osobne podatke, o
                primateljima ili kategorijama primatelja te o predviđenom
                razdoblju u kojem će osobni podaci biti pohranjeni. Pristup
                osobnim podacima može biti ograničen samo u slučajevima
                propisanim pravom Unije ili našim nacionalnim zakonodavstvom
                odnosno kada se takvim ograničenjem poštuje bit temeljnih prava
                i sloboda drugih.
              </p>
            </li>

            <li>
              Pravo na ispravak osobnih podataka
              <p>
                Imate pravo zatražiti ispravljanje ili dopunjavanje osobnih
                podataka ako Vaši podaci nisu točni, potpuni i ažurni. Da biste
                to učinili, pošaljite svoj zahtjev nama kao voditelju obrade
                elektronički putem.
              </p>
              <p>
                Napominjemo kako je u zahtjevu potrebno specificirati što
                konkretno nije točno, potpuno ili ažurno i u kojem smislu bi
                navedeno trebalo ispraviti te dostaviti potrebnu dokumentaciju u
                prilog svojih navoda.
              </p>
            </li>

            <li>
              Pravo na brisanje
              <p>
                Imate pravo tražiti brisanje osobnih podataka koji se na Vas
                odnose ako je ispunjen jedan od sljedećih uvjeta:
              </p>
              <p>
                {' '}
                - Vaši osobni podaci više nisu nužni u odnosu na svrhu u koje
                smo ih prikupili ili obradili;
              </p>
              <p>
                {' '}
                - povukli ste privolu na kojoj se obrada temelji u skladu s
                člankom 6. stavkom 1. točkom (a) ili člankom 9. stavkom 2.
                točkom (a) i ako ne postoji druga pravna osnova za obradu;
              </p>
              <p>
                {' '}
                - uložili ste prigovor na obradu svojih osobnih podataka u
                skladu s člankom 21. stavkom 1. Opće uredbe o zaštiti podataka
                te ako ne postoje naši jači legitimni razlozi za obradu;
              </p>
              <p> - osobni podaci nezakonito su obrađeni;</p>
              <p>
                {' '}
                - osobni podaci moraju se brisati radi poštivanja pravne obveze
                iz prava Unije ili prava države kojoj podliježe voditelj obrade
              </p>
              <p>
                {' '}
                - osobni podaci prikupljeni su u vezi s ponudom usluga
                informacijskog društva iz članka 8. stavka 1.
              </p>
            </li>

            <li>
              Iznimke vezane uz ostvarivanje navedenog prava predviđene su
              člankom 17. stavkom 3. Opće uredbe
              <p>
                Navedena prava nisu primjenjiva u mjeri u kojoj je obrada nužna:
              </p>
              <p>
                {' '}
                - radi ostvarivanja prava na slobodu izražavanja i informiranja;
              </p>
              <p>
                {' '}
                - radi poštovanja pravne obveze kojom se zahtijeva obrada u
                pravu Unije ili pravu države članice kojem podliježe voditelj
                obrade ili za izvršavanje zadaće od javnog interesa ili pri
                izvršavanju službene ovlasti voditelja obrade;
              </p>
              <p>
                {' '}
                - u svrhe arhiviranja u javnom interesu, u svrhe znanstvenog ili
                povijesnog istraživanja ili u statističke svrhe u skladu s
                člankom 89. stavkom 1. u mjeri u kojoj je vjerojatno da se
                pravom iz stavka 1. može onemogućiti ili ozbiljno ugroziti
                postizanje ciljeva te obrade; ili
              </p>
              <p>
                {' '}
                - radi postavljanja, ostvarivanja ili obrane pravnih zahtjeva.
              </p>
            </li>

            <li>
              Pravo na ograničavanje obrade osobnih podataka
              <p>Imate pravo ishoditi ograničenje obrade ako:</p>
              <p> - osporavate njihovu točnost;</p>
              <p>
                {' '}
                - ako je obrada nezakonita, a protivite se njihovom brisanju;
              </p>
              <p>
                {' '}
                - ako voditelj obrade više ne treba osobne podatke ali ste ih
                zatražili radi postavljanja, ostvarivanja ili obrane pravnih
                zahtjeva;
              </p>
              <p>
                {' '}
                - ako ste uložili prigovor na obradu Vaših osobnih podataka.
              </p>
            </li>

            <li>
              Pravo na prigovor
              <p>
                Ako Vaše podatke obrađujemo u svrhe izvršavanja zadaća od javnog
                interesa ili pri izvršavanju svojih službenih ovlasti ili se
                prilikom obrade istih pozivamo na naše legitimne interese,
                možete podnijeti prigovor protiv takve obrade.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <p css={privacyp.listTitle}>DAVANJE NA KORIŠTENJE OSOBNIH PODATAKA</p>
          <p css={[privacyp.listDescription, spacers.top.huge]}>
            U određenim okolnostima, imamo pravnu obvezu proslijediti Vaše
            osobne podatke, a obradom osobnih podataka može biti obuhvaćen i
            međunarodni prijenos istih. Pravna obveza može proizlaziti iz
            nacionalnih propisa ili iz propisa EU. Primjerice nekada je potrebno
            poduzimanje zajedničkih nadzornih aktivnosti nadzornih tijela država
            članica ili je potrebno poduzeti radnje s ciljem naplata izrečene, a
            neplaćene kazne. Dakle, Vaši podaci prosljeđuju se drugim
            primateljima kad nas obvezuju relevantni propisi u opsegu koji je
            potreban za ostvarenje utvrđene svrhe.
          </p>
          <p css={privacyp.listDescription}>
            Vaši osobni podaci neće se prosljeđivati trećim stranama u svrhe
            izravnog marketinga.
          </p>
        </li>

        <li>
          <p css={privacyp.listTitle}>SIGURNOST OSOBNIH PODATAKA</p>
          <p css={[privacyp.listDescription, spacers.top.huge]}>
            Prikupljamo i obrađujemo osobne podatke na način kojim se osigurava
            odgovarajuća sigurnost i povjerljivost u njihovoj obradi te
            omogućavanje učinkovite primjene načela zaštite podataka, smanjenje
            količine podataka, opseg njihove obrade, razdoblje pohrane i njihovu
            dostupnost.
          </p>
          <p css={privacyp.listDescription}>
            Poduzimamo sve odgovarajuće tehničke i organizacijske mjere zaštite
            kako bi spriječili slučajno ili nezakonito uništavanje, gubitak,
            izmjene, neovlašteno korištenje, otkrivanje uvid ili pristup
            podatcima.
          </p>
          <p css={privacyp.listDescription}>
            Svi zaposlenici voditelja obrade obvezuju se na čuvanje osobnih
            podataka potpisivanjem izjave o povjerljivosti.
          </p>
          <p css={privacyp.listDescription}>
            Djelatnici Udruge dužni su kao profesionalnu tajnu odnosno kao drugu
            odgovarajuću vrstu tajne, sukladno zakonu kojim se uređuje tajnost
            podataka, čuvati sve osobne i druge povjerljive podatke koje saznaju
            u obavljanju svojih dužnosti. Navedena obveza traje i nakon
            prestanka djelovanja u Udruzi.
          </p>
        </li>

        <li>
          <p css={privacyp.listTitle}>
            VREMENSKO RAZDOBLJE ČUVANJA OSOBNIH PODATAKA
          </p>
          <p css={[privacyp.listDescription, spacers.top.huge]}>
            Vaše osobne podatke obrađujemo dok se ne ispuni svrha obrade osobnih
            podataka. Nakon prestanka svrhe u koju su prikupljeni, Vaše
            osobne podatke više ne koristimo, te isti ostaju u našem sustavu
            pohrane i čuvamo ih koliko nas obvezuju zakonski propisi o čuvanju
            arhivskog gradiva.
          </p>
        </li>

        <li>
          <p css={privacyp.listTitle}>KONTAKT INFORMACIJE</p>
          <p css={[privacyp.listDescription, spacers.top.huge]}>
            Ako imate pitanja u vezi obrade Vaših osobnih podataka, možete nam
            se obratiti na mail: prijatelji.sv.martina@gmail.com ili na telefon
            +385 97 698 2901.
          </p>
        </li>

        <li>
          <p css={privacyp.listTitle}>PROMJENE POLITIKE PRIVATNOSTI</p>
          <p css={[privacyp.listDescription, spacers.top.huge]}>
            Politiku privatnosti redovito ažuriramo kako bi ista bila točna i
            ažurna te zadržavamo pravo promjene sadržaja iste ako smatramo da je
            to nužno. O svim izmjenama i dopunama bit ćete pravovremeno
            informirani putem naše internetske stranice u skladu s načelom
            transparentnosti.
          </p>
        </li>
      </ol>
    </section>
  );
};
