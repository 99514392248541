/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Switch from '@material-ui/core/Switch';

import { fields } from 'styles';

interface Props {
  name: string;
  checked?: boolean;
  handlePublishSwitch: (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
}

export const PublishSwitch: React.FC<Props> = ({
  name,
  checked,
  handlePublishSwitch,
}) => {
  const { register, setValue, watch } = useFormContext();

  useEffect(() => {
    setValue(name, checked);
  }, [setValue, name, checked]);

  const checkedValue = watch(name);

  return (
    <div css={fields.field}>
      <label css={fields.label}>Objavljeno</label>
      <Switch
        checked={checkedValue || false}
        disableRipple
        size="medium"
        inputRef={register}
        name={name}
        onChange={handlePublishSwitch}
      />
    </div>
  );
};
