/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useRef } from 'react';
import { FormContext } from 'react-hook-form';

import { Donation, Reservation } from 'models';
import { useFormLogic } from 'hooks';
import {
  ValidatedInput,
  FormCheckbox,
  FormSubmit,
  CompletedFormMessage,
} from 'components';
import { form, buttons } from 'styles';
import { EmailRegExp, PhoneRegExp } from 'consts';
import { supply } from 'styles/supply.styles';

interface Props {
  donation: Donation;
}

export const ReserveDonation: React.FC<Props> = ({ donation }) => {
  const alertEl = useRef<HTMLParagraphElement>(null);
  const {
    methods,
    formIsLoading,
    formCompleted,
    formError,
    reserveDonation,
  } = useFormLogic<Reservation>(undefined, undefined, alertEl.current);

  const onSubmit = (data: Reservation) => {
    const reservation = new Reservation({ ...data, donation });
    reserveDonation(reservation);
  };

  return (
    <div css={supply.reserveWrapper}>
      <p ref={alertEl} css={supply.reserveTitle}>
        Rezerviraj donaciju
      </p>

      {!formCompleted && (
        <FormContext {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div>
              <div css={form.flexFields}>
                <ValidatedInput
                  label="Ime"
                  placeholder="Vaše ime"
                  name="firstName"
                  validationObject={{ required: true }}
                />

                <ValidatedInput
                  label="Prezime"
                  placeholder="Vaše prezime"
                  name="lastName"
                  validationObject={{ required: true }}
                />
              </div>

              <div css={form.flexFields}>
                <ValidatedInput
                  label="E-mail"
                  placeholder="Vaša e-mail adresa"
                  name="email"
                  errMsg="Krivi format e-mail adrese"
                  validationObject={{ pattern: EmailRegExp }}
                />

                <ValidatedInput
                  label="Broj telefona"
                  placeholder="Vaš broj telefona"
                  name="phone"
                  errMsg="Krivi format telefonskog broja"
                  validationObject={{ required: true, pattern: PhoneRegExp }}
                />
              </div>

              <FormCheckbox name="policyAccepted" required />

              <FormSubmit
                value="Rezerviraj"
                stateIsChanging={formIsLoading}
                className={buttons.primary.medium}
              />
            </div>
          </form>
        </FormContext>
      )}

      {formCompleted && (
        <CompletedFormMessage
          title="Hvala Vam na zamolbi za rezervaciju. 
        Nakon što pregledamo i odobrimo rezervaciju predmeta, bit ćete obaviješteni o 
        uspjehu iste kao i ostatku procedure."
          formError={formError}
        />
      )}
    </div>
  );
};
