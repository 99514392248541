/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import Linkify from 'react-linkify';
import Image from 'react-shimmer';

import { News, Announcement } from 'models';
import { newsStyles, wrappers, buttons } from 'styles';
import { getHeroImage, slugify } from 'utils';

interface Props {
  item?: News | Announcement;
  path: string;
}

export const HeroItem: React.FC<Props> = ({ item, path }) =>
  item ? (
    <section css={newsStyles.heroBg}>
      <div css={[wrappers.normal, newsStyles.heroWrapper]}>
        <div css={newsStyles.heroText}>
          <p css={newsStyles.heroItemDate}>
            {format(
              (item.dateOfPublishing as firebase.firestore.Timestamp).toDate(),
              'dd.LL.yyyy',
            )}
          </p>
          <p css={newsStyles.heroItemTitle}>{item.title}</p>
          <Linkify>
            <p css={newsStyles.heroItemDescription}>{item.description}</p>
          </Linkify>
          <Link
            to={`${path}/${slugify(item.title)}/${item.id}`}
            css={buttons.primary.medium}
          >
            Pročitaj više
          </Link>
        </div>

        <div css={newsStyles.heroImg}>
          <Image
            src={getHeroImage(item.photoUrls, item.heroImageIndex)}
            fallback={<div />}
            errorFallback={() => (
              <img
                src={require('assets/images/image-placeholder.png')}
                alt="placeholder"
              />
            )}
          />
        </div>
      </div>
    </section>
  ) : null;
