import { FirestoreListenerActions } from '.';

import { FirestoreListenerActionTypes } from './types';

export interface FirestoreListenerState {
  [prop: string]: VoidFunction | undefined;
}

const INITIAL_STATE: FirestoreListenerState = {};

export default (
  state: FirestoreListenerState = INITIAL_STATE,
  action: FirestoreListenerActions,
) => {
  switch (action.type) {
    case FirestoreListenerActionTypes.RemoveAllListeners:
      return INITIAL_STATE;

    case FirestoreListenerActionTypes.RemoveListener:
      const tempState = { ...state };
      const listener = tempState[action.payload.listenerName];
      if (listener) listener();
      delete tempState[action.payload.listenerName];
      return { ...tempState };

    case FirestoreListenerActionTypes.SetListener:
      return {
        ...state,
        [action.payload.listenerName]: action.payload.listener,
      };

    default:
      return state || INITIAL_STATE;
  }
};
