import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

interface Props {
  title?: string;
  description?: string;
  ogImageUrl?: string;
  canonicalUrl?: string;
}

export const Helmet: React.FC<Props> = ({ title, description, ogImageUrl }) => (
  <ReactHelmet>
    <meta charSet="utf-8" />

    <title>{`Socijalna Burza | ${title}`}</title>
    <meta property="og:title" content={`Socijalna Burza | ${title}`} />
    <meta name="twitter:title" content={`Socijalna Burza | ${title}`} />

    <meta name="description" content={description} />
    <meta property="og:description" content={description} />
    <meta name="twitter:description" content={description} />

    <meta property="og:image" content={ogImageUrl} />
    <meta property="og:image:secure_url" content={ogImageUrl} />
    <meta name="twitter:image" content={ogImageUrl} />
  </ReactHelmet>
);
