import { css } from '@emotion/core';

import { unit } from './vars.styles';
import { mqFrom } from './responsive.styles';

const normal = css`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${unit * 5}px;
  padding-right: ${unit * 5}px;

  ${mqFrom.Med} {
    padding-left: ${unit * 8}px;
    padding-right: ${unit * 8}px;
  }
`;

const narrow = css`
  max-width: 994px;
  margin-left: auto;
  margin-right: auto;

  padding-left: ${unit * 3}px;
  padding-right: ${unit * 3}px;

  ${mqFrom.Med} {
    padding-left: ${unit * 22}px;
    padding-right: ${unit * 22}px;
  }
`;

const threeItemsGrid = css`
  ${mqFrom.Med} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    grid-gap: 36px;
  }

  ${mqFrom.Xlrg} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const fourItemsGrid = css`
  ${mqFrom.Sml} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    grid-gap: 36px;
  }

  ${mqFrom.Lrg} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${mqFrom.Xlrg} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

/**
 * Export all available button styles
 */
export const wrappers = {
  normal,
  narrow,
  threeItemsGrid,
  fourItemsGrid,
};
