import { css } from '@emotion/core';

import { unit, colors, globalRadial } from './vars.styles';
import { color } from './mixins.styles';
import { typescale } from './typescale.styles';

const wrapper = css`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
`;

const branding = css`
  padding: ${unit * 8}px;

  img {
    max-width: 200px;
  }
`;

const navWrapper = css`
  padding: 0 ${unit * 6}px 0 ${unit * 6}px;
`;

const navList = css`
  list-style: none;
  padding: 0;
`;

const navListItem = css`
  margin-bottom: ${unit * 2}px;
  outline: none;
  user-select: none;
`;

const navLink = css`
  display: block;
  padding: ${unit * 3}px 0 ${unit * 3}px ${unit * 4}px;
  background: ${color(colors.bg, 0.4)};

  color: ${color(colors.primary, 0.6)};

  transition: background 0.2s ease-out, color 0.2s ease-out;
  border-radius: ${globalRadial};

  &:hover,
  &:focus {
    background: ${colors.bg};
    color: ${colors.primary};
  }

  &[aria-current] {
    color: ${colors.primary};
    background: ${colors.bg};
    box-shadow: 0 0 10px ${color(colors.dark, 0.1)};
  }
`;

const version = css`
  background: ${color(colors.bg, 0.1)};
  padding: ${unit * 3}px;
  margin: ${unit * 6}px;
  border-radius: ${globalRadial};
  color: ${color(colors.bg, 0.5)};
`;

const logout = css`
  width: 100%;
  padding: ${unit * 3 + 2}px;
  border: none;
  border-top: 1px solid ${color(colors.primary, 0.7)};

  text-transform: uppercase;
  letter-spacing: 0.2em;
  ${typescale.base}
  color: ${colors.primary};
  background: transparent;

  &:hover,
  &:focus {
    color: ${color(colors.primary, 0.7)};
    cursor: pointer;
  }
`;

export const sidebar = {
  wrapper,
  branding,
  navWrapper,
  navList,
  navListItem,
  navLink,
  version,
  logout,
};
