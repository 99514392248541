/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';

import { loading } from 'styles';

interface Props {
  isLoading: boolean;
  fullPage?: boolean;
}

export const Loading: React.FC<Props> = ({ isLoading, fullPage, children }) =>
  isLoading ? (
    <Fragment>
      {fullPage ? (
        <div css={loading.pageWrapper}>
          <div css={loading.content}>
            <img
              src={require('assets/images/socijalna-burza-logo.svg')}
              alt="Socijalna burza"
            />
            <p>Socijalna burza</p>

            <div css={loading.spinner} />
          </div>
        </div>
      ) : (
        <div css={loading.spinnerWrapper}>
          <div css={loading.spinner} />
        </div>
      )}
    </Fragment>
  ) : (
    <Fragment>{children}</Fragment>
  );
