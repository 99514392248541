/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { Announcement, News } from 'models';
import { home } from 'styles';
import { getHeroImage, slugify } from 'utils';

interface Props {
  items: (News | Announcement)[];
  listCss?: SerializedStyles | SerializedStyles[];
  itemCss?: SerializedStyles | SerializedStyles[];
  imgWrapper?: SerializedStyles | SerializedStyles[];
  titleCss?: SerializedStyles | SerializedStyles[];
  dateCss?: SerializedStyles | SerializedStyles[];
  descriptionCss?: SerializedStyles | SerializedStyles[];
  path: string;
}

export const ExtendedItemList: React.FC<Props> = ({
  items,
  listCss,
  itemCss,
  imgWrapper,
  titleCss,
  dateCss,
  descriptionCss,
  path,
}) => {
  return (
    <ul css={listCss}>
      {items.map(item => {
        return (
          <Link key={item.id} to={`${path}/${slugify(item.title)}/${item.id}`}>
            <div css={itemCss}>
              <div css={imgWrapper}>
                <img
                  src={getHeroImage(item.photoUrls, item.heroImageIndex)}
                  alt="main"
                />
              </div>
              <p css={dateCss || home.newsCardDate}>
                {item.dateOfPublishing &&
                  format(
                    (item.dateOfPublishing as firebase.firestore.Timestamp).toDate(),
                    'dd.LL.yyyy',
                  )}
              </p>
              <p css={titleCss || home.newsCardTitle}>{item.title}</p>
              <p css={descriptionCss || home.newsItemDescription}>
                {item.description}
              </p>
            </div>
          </Link>
        );
      })}
    </ul>
  );
};
