/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SideNavigation } from 'modules/navigation';
import { admin } from 'styles';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { FirestoreListenerThunks } from 'modules/firebase';
import { SearchActions } from 'modules/search';

import { ScrollToTop } from './ScrollToTop';
import { Loading } from './Loading';

export const DashboardLayout: React.FC = ({ children }) => {
  const { authStateChanging } = useSelector(
    (state: ApplicationState) => state.auth,
  );
  const { pathname } = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // Unsubscribe all admin dashboard listeners that listen for all documents in collections
    return () => {
      dispatch(
        FirestoreListenerThunks.unsubscribeOneListener('donationsListener'),
      );
      dispatch(FirestoreListenerThunks.unsubscribeOneListener('newsListener'));
      dispatch(
        FirestoreListenerThunks.unsubscribeOneListener('announcementsListener'),
      );
      dispatch(
        FirestoreListenerThunks.unsubscribeOneListener('contactsListener'),
      );
      dispatch(
        FirestoreListenerThunks.unsubscribeOneListener('requestsListener'),
      );
      dispatch(
        FirestoreListenerThunks.unsubscribeOneListener('reservationsListener'),
      );
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(SearchActions.clearSearch());
  }, [dispatch, pathname]);

  return (
    <Loading fullPage isLoading={authStateChanging && pathname !== '/login'}>
      <div css={admin.layout}>
        <div css={admin.layoutAside}>
          <SideNavigation />
        </div>

        <div css={admin.layoutMain}>
          <main>{children}</main>
        </div>

        <ScrollToTop />
      </div>
    </Loading>
  );
};
