/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useRef } from 'react';
import { FormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { withId } from 'utils';
import { useFormLogic, useModal } from 'hooks';
import { Announcement, PageHeaderAction } from 'models';
import { RoutePath } from 'modules/navigation';
import { AnnouncementsThunks } from 'modules/announcements';
import { wrappers, content, form } from 'styles';
import {
  PageHeader,
  Loading,
  ValidatedInput,
  FormSubmit,
  Dropzone,
  PageGuard,
  PublishSwitch,
} from 'components';
import { Modal } from 'components/Modal';
import { FileUpload } from 'components/FileUpload';

export const DashboardAnnouncementDetails: React.FC = () => {
  const { id } = useParams();
  const { announcements, announcementsAreChanging } = useSelector(
    (state: ApplicationState) => state.announcements,
  );
  const selectedAnnouncement = announcements.find(withId(id));
  const dispatch = useDispatch<AppDispatch>();
  const formRef = useRef<HTMLFormElement>(null);
  const {
    methods,
    uploadAnnouncementFiles,
    removeFilesFromAnnouncements,
    handleDropzoneThumbnailClick,
    formIsLoading,
    deleteAnnouncement,
    saveAnnouncement,
    pageModified,
    handlePublishSwitch,
  } = useFormLogic<Announcement>(
    RoutePath.DashboardAnnouncements,
    formRef.current,
  );
  const { isShowing, toggle, entityId, entityTitle } = useModal(
    selectedAnnouncement?.title,
  );

  useEffect(() => {
    dispatch(AnnouncementsThunks.getAllAnnouncements());
  }, [dispatch]);

  useEffect(() => {
    methods.register('id');
    methods.register('dateOfPublishing');
    methods.register('createdAt');

    methods.setValue('id', selectedAnnouncement?.id || id);
    methods.setValue(
      'dateOfPublishing',
      selectedAnnouncement?.dateOfPublishing,
    );
    methods.setValue('createdAt', selectedAnnouncement?.createdAt);
  }, [methods, selectedAnnouncement, id]);

  return (
    <section css={[wrappers.normal, content.area]}>
      <PageHeader
        title="Detalji najave"
        backAction={
          new PageHeaderAction('Natrag', RoutePath.DashboardAnnouncements)
        }
        actions={[new PageHeaderAction('Obriši najavu', toggle)]}
      />
      <Loading isLoading={announcementsAreChanging}>
        <FormContext {...methods}>
          <form
            ref={formRef}
            css={form.flexFields}
            onSubmit={methods.handleSubmit(saveAnnouncement)}
          >
            <Dropzone
              name="photoUrls"
              firebasePhotoUrls={selectedAnnouncement?.photoUrls}
              formIsLoading={formIsLoading}
              heroImageIndex={selectedAnnouncement?.heroImageIndex}
              uplaodImagesToStorageAndGetUrls={uploadAnnouncementFiles}
              removeImagesFromStorage={removeFilesFromAnnouncements}
              handleDropzoneThumbnailClick={handleDropzoneThumbnailClick}
            />

            <div css={form.wrapper}>
              <ValidatedInput
                label="Naslov"
                placeholder="Unesite naslov"
                name="title"
                errMsg="Naslov je predugačak (max. 60 znakova)"
                validationObject={{ required: true, maxLength: 60 }}
                defaultValue={selectedAnnouncement?.title}
              />

              <ValidatedInput
                textarea
                label="Opis"
                placeholder="Unesite opis"
                name="description"
                errMsg="Opis je predugačak (max. 2000 znakova)"
                validationObject={{ required: true, maxLength: 2000 }}
                defaultValue={selectedAnnouncement?.description}
              />

              <FileUpload
                name="files"
                firebaseFiles={selectedAnnouncement?.files}
                formIsLoading={formIsLoading}
                uplaodFilesToStorageAndGetUrls={uploadAnnouncementFiles}
                removeFilesFromStorage={removeFilesFromAnnouncements}
              />

              <PublishSwitch
                name="published"
                checked={selectedAnnouncement?.published}
                handlePublishSwitch={handlePublishSwitch}
              />

              <FormSubmit value="Spremi" stateIsChanging={formIsLoading} />
            </div>
          </form>
        </FormContext>
      </Loading>

      <Modal
        isShowing={isShowing}
        hide={toggle}
        onConfirm={deleteAnnouncement}
        entityId={entityId}
        entityTitle={entityTitle}
      />

      <PageGuard when={pageModified} />
    </section>
  );
};
