import { User } from 'firebase';

import { AuthService, FirestoreListenerActions } from 'modules/firebase';
import { AppDispatch, ApplicationState } from 'modules/redux-store';

import { AuthActions } from './actions';

const authService = AuthService();

const loginAsync = (
  email: string,
  password: string,
  rememberMe: boolean,
) => async (dispatch: AppDispatch) => {
  dispatch(AuthActions.authStateChange());

  const error = await authService.loginWithEmailAndPasswordAsync(
    email,
    password,
    rememberMe,
  );

  if (error) {
    dispatch(AuthActions.loginError(error));
  }
  return;
};

const loginWithPersistedUser = () => (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  dispatch(AuthActions.authStateChange());

  const authListeners = authService.initAuthStateListener(
    (user: User) => dispatch(AuthActions.loginSuccess(user)),
    (error?: string) => dispatch(AuthActions.loginError(error)),
  );

  if (!getState().listeners.authStateListener) {
    dispatch(
      FirestoreListenerActions.SetListener('authStateListener', authListeners),
    );
  }
};

const logout = () => async (dispatch: AppDispatch) => {
  dispatch(AuthActions.authStateChange());

  await authService.logoutAsync();
  dispatch(AuthActions.logout());
};

export const AuthThunks = {
  loginAsync,
  loginWithPersistedUser,
  logout,
};
