import { ApplicationState } from 'modules/redux-store';

export const getSearchQuery = (state: ApplicationState) => state.search.query;
export const getOrderFilter = (state: ApplicationState) =>
  state.search.orderFilter;

export const applySearchParams = (params: string[], query: string) => {
  const match = params.map(param => {
    if (!param) {
      return false;
    }

    return param.toLowerCase().includes(query.trim().toLowerCase());
  });

  return match.some(result => result);
};
