import 'firebase/storage';
import { Collection } from 'consts';

import { FirebaseService } from './FirebaseService';

export function StorageService(collection: Collection) {
  const storage = FirebaseService.Instance().storage();

  const addFilesAndGetUploadTasks = (docId: string, files?: File[]) => {
    const storageRef = storage.ref(collection);
    return files
      ?.filter(file => Boolean(file))
      .map(file => {
        const uploadTask = storageRef
          .child(docId)
          .child(file.name)
          .put(file);
        return uploadTask;
      });
  };

  const deleteFile = (fileUrl: string) => {
    const httpReference = storage.refFromURL(fileUrl);

    httpReference.delete().then(() => console.log('File deleted'));
  };

  return {
    addFilesAndGetUploadTasks,
    deleteFile,
  };
}
