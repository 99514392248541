import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

import { NewsFile } from 'models';

export function useFileUpload(
  name: string,
  uplaodFilesToStorageAndGetUrls: (
    id: string,
    files: File[],
  ) => Promise<string[] | undefined>,
  firebaseFiles?: NewsFile[],
) {
  const {
    errors,
    register,
    setValue,
    getValues,
    watch,
    reset,
  } = useFormContext();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const currentFiles: NewsFile[] = getValues()[name]
        ? [...getValues()[name]]
        : [];

      const docId: string = getValues().id;
      const fileUrls =
        (await uplaodFilesToStorageAndGetUrls(docId, acceptedFiles)) || [];
      const files: NewsFile[] = fileUrls.map((url, index) => {
        return {
          url,
          name: acceptedFiles[index].name,
        };
      });
      setValue(name, [...currentFiles, ...files]);
    },
    [name, setValue, getValues, uplaodFilesToStorageAndGetUrls],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/*',
  });

  useEffect(() => {
    register({ name: name });

    return () => {
      reset({ [name]: [] });
    };
  }, [name, register, reset]);

  useEffect(() => {
    if (firebaseFiles && firebaseFiles.length) {
      setValue(name, firebaseFiles);
    }
  }, [firebaseFiles, name, setValue]);

  const fileUrls: NewsFile[] = watch(name);

  return {
    register,
    setValue,
    getValues,
    watch,
    reset,
    onDrop,
    getRootProps,
    getInputProps,
    errors,
    isDragActive,
    fileUrls,
  };
}
