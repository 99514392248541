import { css } from '@emotion/core';

import { mqFrom } from './responsive.styles';
import { fontFamily, colors, unit, fontWeights } from './vars.styles';
import { typescale } from './typescale.styles';
import { color } from './mixins.styles';
import { keyFrames } from './kf.styles';

const wrapper = css`
  ${mqFrom.Lrg} {
    display: flex;
    justify-content: flex-start;
  }
`;

const formWrapper = css`
  width: 100%;
  animation: ${keyFrames.appear2} 1s ease-out;

  ${mqFrom.Lrg} {
    max-width: 582px;
    margin-left: 140px;
  }
`;

const info = css`
  max-width: 273px;
  color: ${color(colors.text, 0.9)};
  font-size: 14px;
  line-height: 20px;
  display: none;
  animation: ${keyFrames.appear3} 1.5s ease-out;

  ${mqFrom.Med} {
    display: inline-block;
  }

  h3 {
    font-family: ${fontFamily.serif};
    ${typescale.large}
    font-weight: ${fontWeights.weightRegular};
    margin-bottom: ${unit * 3}px;
  }

  h5 {
    ${typescale.medium}
    font-weight: ${fontWeights.weightRegular};
    margin-bottom: ${unit}px;
  }

  p:last-of-type {
    margin-top: ${unit * 10 + 2}px;
  }
`;

const dropzone = css`
  margin-bottom: ${unit * 26}px;
  animation: ${keyFrames.appear} 0.5s ease-out;
`;

const dropzoneInfoWrapper = css`
  ${mqFrom.Med} {
    display: flex;
    justify-content: space-between;
  }

  ${mqFrom.Lrg} {
    display: block;
  }
`;

export const donate = {
  wrapper,
  formWrapper,
  info,
  dropzone,
  dropzoneInfoWrapper,
};
