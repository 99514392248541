import { css } from '@emotion/core';

import { colors, unit } from './vars.styles';

const bg = css`
  background: ${colors.tertiary};
`;

const wrapper = css`
  background: ${colors.tertiary};
  padding-top: ${unit * 21}px;
`;

export const announcements = {
  bg,
  wrapper,
};
