import { css } from '@emotion/core';

import { unit, colors } from './vars.styles';
import { color } from './mixins.styles';

const item = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${unit * 4}px 0;
  border-bottom: 1px solid ${color(colors.dark, 0.1)};
  transition: padding-left 0.2s ease-out, background 0.2s ease-out;
  padding-left: ${unit * 4}px;

  &:hover {
    padding-left: ${unit * 6}px;
    background: ${color(colors.bg, 0.7)};
  }
`;

const itemContent = css`
  padding-right: ${unit * 6}px;
`;

export const list = {
  item,
  itemContent,
};
