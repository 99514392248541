import { css } from '@emotion/core';

import {
  unit,
  colors,
  globalRadial,
  fontWeights,
  fontFamily,
} from './vars.styles';
import { color } from './mixins.styles';
import { typescale } from './typescale.styles';
import { mqFrom } from './responsive.styles';
import { wrappers } from './wrapper.styles';
import { keyFrames } from './kf.styles';

const wrapper = css`
  ${wrappers.threeItemsGrid}
  padding: 0;
  margin-top: -220px;

  ${mqFrom.Med} {
    margin-top: -160px;
  }
`;

const item = css`
  list-style-type: none;
  margin-bottom: ${unit * 10}px;
  overflow: hidden;

  div:first-of-type {
    overflow: hidden;
    border-radius: ${globalRadial * 2 + 2}px;
    margin-bottom: ${unit * 4}px;

    img {
      width: 100%;
      height: auto;
      border-radius: ${globalRadial * 2 + 2}px;
      object-fit: cover;
      margin-bottom: ${unit * 4}px;
      background: ${colors.tertiary};
      transition: transform 0.2s ease-out;
      overflow: hidden;
    }

    ${mqFrom.Med} {
      max-height: 212px;

      img {
        width: 100%;
        height: 212px;
      }
    }
  }

  &:hover {
    img {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
`;

const itemDate = css`
  ${typescale.tiny}
  margin-bottom: ${unit}px;
  color: ${color(colors.text, 0.9)};
  opacity: 0.6;
`;

const heroItemDate = css`
  ${itemDate}
  margin-bottom: ${unit * 3}px;
`;

const itemTitle = css`
  ${typescale.large}
  font-weight: ${fontWeights.weightBold};
  margin-bottom: ${unit * 5}px;
  color: ${color(colors.text, 0.9)};
  font-family: ${fontFamily.serif};
`;

const heroItemTitle = css`
  ${itemTitle}
  margin-bottom: ${unit * 8}px;
`;

const itemDescription = css`
  font-size: 14px;
  line-height: 20px;
  color: ${color(colors.text, 0.9)};
  opacity: 0.7;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const heroItemDescription = css`
  ${itemDescription}
  margin-bottom: ${unit * 13}px;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
`;

const heroBg = css`
  background: ${colors.tertiary};
`;

const heroWrapper = css`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  padding-top: ${unit * 2}px;
  padding-bottom: 264px;

  ${mqFrom.Lrg} {
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${unit * 16}px;

    div > {
      flex: 1;
    }
  }
`;

const heroText = css`
  width: 100%;

  ${mqFrom.Lrg} {
    max-width: 376px;
  }
`;

const heroImg = css`
  & > img,
  & > div {
    width: 100%;
    height: auto;
    margin: 0 auto;
    object-fit: cover;
    background: ${colors.tertiary};
    border-radius: ${globalRadial * 2 + 2}px;
    margin-bottom: ${unit * 2}px;
    animation: ${keyFrames.showRight} 1.5s ease-out;

    ${mqFrom.Lrg} {
      width: 685px;
      height: 376px;
      margin-bottom: 0;
    }
  }
`;

const detailsWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: ${unit * 10}px;

  ${mqFrom.Lrg} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 112px;
  }
`;

const detailsGoBack = css`
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  font-weight: ${fontWeights.weightBold};
  opacity: 0.5;
  margin-bottom: ${unit * 4}px;
  transition: opacity 0.15s ease-out;

  &:hover {
    opacity: 0.7;
  }

  a {
    color: ${color(colors.text, 0.9)};
  }

  ${mqFrom.Lrg} {
    width: 20%;
  }
`;

const detailsInfo = css`
  ${mqFrom.Lrg} {
    width: 60%;
  }
`;

const detailsGallery = css`
  width: 100%;
  margin-bottom: ${unit * 4}px;

  ${mqFrom.Lrg} {
    margin-bottom: ${unit * 16}px;
  }
`;

const detailsImgCore = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: ${colors.tertiary};

  border-radius: ${globalRadial * 2 + 2}px;

  ${mqFrom.Lrg} {
    width: 100%;
    height: 376px;
    object-fit: cover;
  }
`;

const detailsMainImg = css`
  ${detailsImgCore}
  position: relative;
  min-height: 300px;

  & > img {
    ${detailsImgCore}
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const detailsInfoFlex = css`
  ${mqFrom.Med} {
    display: flex;
    gap: 20px;
  }
`;

const detailsTitle = css`
  font-family: ${fontFamily.serif};
  ${typescale.xlarge}
  color: ${color(colors.text, 0.9)};
  margin-bottom: 20px;
`;

const detailsTitleResponsive = css`
  ${detailsTitle}
  ${typescale.large}

  ${mqFrom.Sml} {
    ${typescale.xlarge}
  }

  ${mqFrom.Med} {
    width: 50%;
  }
`;

const relatedNewsWrapper = css``;

const relatedNewsList = css`
  ${wrappers.threeItemsGrid}
  padding: 0;
`;

const filesList = css`
  padding: 0;
  margin: 0;
`;

const contentWrapper = css`
  ${typescale.small}
  color: ${color(colors.text, 0.9)};
  opacity: 0.7;

  ${mqFrom.Sml} {
    ${typescale.base}
  }

  ${mqFrom.Med} {
    width: 50%;

  }
`;

const detailsDescription = css`
  margin-bottom: 0;
`;

export const newsStyles = {
  wrapper,
  item,
  heroBg,
  heroWrapper,
  heroText,
  heroImg,
  itemDate,
  heroItemDate,
  itemTitle,
  heroItemTitle,
  itemDescription,
  heroItemDescription,
  detailsWrapper,
  detailsGoBack,
  detailsInfo,
  detailsGallery,
  detailsMainImg,
  detailsInfoFlex,
  detailsTitle,
  detailsTitleResponsive,
  detailsDescription,
  relatedNewsWrapper,
  relatedNewsList,
  filesList,
  contentWrapper,
};
