/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { nav, wrappers } from 'styles';

import { RoutePath } from '../consts';

export const Header: React.FC = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setHamburgerOpen(false);
  }, [pathname]);

  return (
    <Fragment>
      <div css={nav.hamburger} onClick={handleHamburgerToggle}>
        <div css={nav.bar}></div>
        <div css={nav.bar}></div>
        <div css={[nav.bar, !hamburgerOpen && nav.changeBar]}></div>
      </div>

      <nav
        role="navigation"
        css={[
          checkRoutePathForBgColor() ? nav.navBg : nav.navBgLight,
          hamburgerOpen && nav.hide,
        ]}
      >
        <div css={[wrappers.normal, nav.wrapper]}>
          <NavLink css={nav.logo} to={RoutePath.HomePage}>
            <img
              src={require('assets/images/logo.png')}
              alt="socijalna burza logo"
            />
            <p>Socijalna burza</p>
          </NavLink>

          <ul css={nav.list}>
            <li css={nav.listItem}>
              <NavLink css={nav.link} to={RoutePath.Supply}>
                Ponuda donacija i volontera
              </NavLink>
            </li>
            <li css={nav.listItem}>
              <NavLink css={nav.link} to={RoutePath.Demand}>
                Potražnja donacija i volontera
              </NavLink>
            </li>
            <li css={nav.listItem}>
              <NavLink css={nav.link} to={RoutePath.News}>
                Novosti
              </NavLink>
            </li>
            <li css={nav.listItem}>
              <NavLink css={nav.link} to={RoutePath.Announcements}>
                Najave
              </NavLink>
            </li>
            <li css={nav.listItem}>
              <NavLink css={nav.link} to={RoutePath.About}>
                O projektu
              </NavLink>
            </li>
            <li css={nav.listItem}>
              <NavLink css={nav.link} to={RoutePath.Contact}>
                Kontakt
              </NavLink>
            </li>
            <li css={nav.listItem}>
              <NavLink css={nav.cta} to={RoutePath.Donate}>
                Doniraj/volontiraj
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>

      <nav
        role="navigation"
        css={[nav.navBg, nav.positionFixed, !hamburgerOpen && nav.hide]}
      >
        <div css={[wrappers.normal, nav.wrapper]}>
          <NavLink css={nav.logo} to={RoutePath.HomePage}>
            <img
              src={require('assets/images/logo.png')}
              alt="socijalna burza logo"
            />
            <p>Socijalna burza</p>
          </NavLink>

          <ul css={[nav.hamburgerMenu]}>
            <li css={nav.burgerListItem}>
              <NavLink css={nav.link} to={RoutePath.Supply}>
                Ponuda donacija i volontera
              </NavLink>
            </li>
            <li css={nav.burgerListItem}>
              <NavLink css={nav.link} to={RoutePath.Demand}>
                Potražnja donacija i volontera
              </NavLink>
            </li>
            <li css={nav.burgerListItem}>
              <NavLink css={nav.link} to={RoutePath.News}>
                Novosti
              </NavLink>
            </li>
            <li css={nav.burgerListItem}>
              <NavLink css={nav.link} to={RoutePath.Announcements}>
                Najave
              </NavLink>
            </li>
            <li css={nav.burgerListItem}>
              <NavLink css={nav.link} to={RoutePath.About}>
                O projektu
              </NavLink>
            </li>
            <li css={nav.burgerListItem}>
              <NavLink css={nav.link} to={RoutePath.Contact}>
                Kontakt
              </NavLink>
            </li>
            <li css={nav.burgerListItem}>
              <NavLink css={nav.cta} to={RoutePath.Donate}>
                Doniraj/volontiraj
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </Fragment>
  );

  function handleHamburgerToggle() {
    setHamburgerOpen(!hamburgerOpen);
  }

  function checkRoutePathForBgColor() {
    return (
      pathname === RoutePath.HomePage ||
      pathname === RoutePath.News ||
      pathname === RoutePath.Announcements
    );
  }
};
