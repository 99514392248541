/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { FormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useFormLogic, useModal } from 'hooks';
import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { withId } from 'utils';
import { Reservation, PageHeaderAction } from 'models';
import { RoutePath } from 'modules/navigation';
import { ReservationsThunks } from 'modules/reservations';
import { wrappers, content, form, newsStyles, admin, spacers } from 'styles';
import {
  PageHeader,
  Loading,
  Gallery,
  TextField,
  FormCheckbox,
  FormError,
} from 'components';
import { CategoryButton, DonationsThunks } from 'modules/donations';
import { Modal } from 'components/Modal';

export const DashboardReservationDetails: React.FC = () => {
  const { id } = useParams();
  const { reservations, reservationsAreChanging, error } = useSelector(
    (state: ApplicationState) => state.reservations,
  );
  const { donations, donationsAreChanging } = useSelector(
    (state: ApplicationState) => state.donations,
  );
  const selectedReservation = reservations.find(withId(id));
  const dispatch = useDispatch<AppDispatch>();
  const { methods, deleteReservation } = useFormLogic<Reservation>(
    RoutePath.DashboardReservations,
  );
  const { isShowing, toggle, entityId, entityTitle } = useModal(
    `rezervaciju od ${selectedReservation?.firstName} ${selectedReservation?.lastName}`,
  );
  const [donationError, setDonationError] = useState<string | undefined>();

  useEffect(() => {
    dispatch(ReservationsThunks.getAllReservations());
    dispatch(DonationsThunks.getAllDonations());
  }, [dispatch]);

  useEffect(() => {
    const donationExists = Boolean(
      donations.find(withId(selectedReservation?.donation.id)),
    );

    if (!donationExists) {
      setDonationError('Donacija navedena u rezervaciji više ne postoji');
      return;
    }
    setDonationError(undefined);
  }, [donations, selectedReservation]);

  useEffect(() => {
    methods.register('id');
    methods.register('createdAt');

    methods.setValue('id', selectedReservation?.id);
    methods.setValue('createdAt', selectedReservation?.createdAt);
  }, [methods, selectedReservation]);

  return (
    <section css={[wrappers.normal, content.area]}>
      <PageHeader
        title="Detalji rezervacije"
        backAction={
          new PageHeaderAction('Natrag', RoutePath.DashboardReservations)
        }
        actions={[new PageHeaderAction('Obriši rezervaciju', toggle)]}
      />
      <Loading isLoading={reservationsAreChanging || donationsAreChanging}>
        <FormError error={error || donationError} />

        <FormContext {...methods}>
          <form css={form.flexFields}>
            <Gallery
              photos={selectedReservation?.donation.photoUrls || []}
              wrapperCss={newsStyles.detailsGallery}
              mainPhotoWrapperCss={newsStyles.detailsMainImg}
            />

            <div css={form.wrapper}>
              <div css={form.flexFields}>
                <TextField
                  label="Ime"
                  name="firstName"
                  text={selectedReservation?.firstName}
                />

                <TextField
                  label="Prezime"
                  name="lastName"
                  text={selectedReservation?.lastName}
                />
              </div>

              <div css={form.flexFields}>
                <TextField
                  label="E-mail"
                  name="email"
                  text={selectedReservation?.email}
                />

                <TextField
                  label="Broj telefona"
                  name="phone"
                  text={selectedReservation?.phone}
                />
              </div>

              <div css={[spacers.bottom.large]}>
                <CategoryButton
                  category={selectedReservation?.donation.category}
                  style={admin.category}
                  btnActive
                  disabled
                />
              </div>

              <TextField
                label="Naziv donacije"
                name="name"
                text={selectedReservation?.donation.name}
              />

              <TextField
                label="Opis donacije"
                name="description"
                text={selectedReservation?.donation.description}
              />

              <FormCheckbox
                name="policyAccepted"
                disabled
                checked={selectedReservation?.policyAccepted}
              >
                <span>Prihvaćena politika privatnosti</span>
              </FormCheckbox>
            </div>
          </form>
        </FormContext>
      </Loading>

      <Modal
        isShowing={isShowing}
        hide={toggle}
        onConfirm={deleteReservation}
        entityId={entityId}
        entityTitle={entityTitle}
      />
    </section>
  );
};
