/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { wrappers, content } from 'styles';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { News as NewsModel } from 'models';
import { usePagination } from 'hooks';
import { NewsThunks } from 'modules/news';
import {
  FormError,
  Loading,
  Pagination,
  EmptyListState,
  HeroItem,
  ExtendedItemList,
  Helmet,
} from 'components';
import { newsStyles } from 'styles/news.styles';
import { RoutePath } from 'modules/navigation';

export const News: React.FC = () => {
  const { news, newsAreChanging, error } = useSelector(
    (state: ApplicationState) => state.news,
  );
  const dispatch = useDispatch<AppDispatch>();
  const {
    currentPage,
    maxPages,
    next,
    previous,
    selectPage,
    selectedData,
  } = usePagination<NewsModel>(news, 9);

  useEffect(() => {
    dispatch(NewsThunks.getPublishedNews());
  }, [dispatch]);

  return (
    <section>
      <Helmet
        title="Socijalna burza - Novosti"
        description="Pročitajte novosti i saznajte koje smo aktivnosti održali u sklopu projekta."
      />

      <Loading isLoading={newsAreChanging}>
        <FormError error={error} />

        <EmptyListState title="Trenutno nema novosti" data={selectedData()} />

        <HeroItem item={selectedData()[0]} path={RoutePath.News} />

        <div css={[wrappers.normal, content.padding]}>
          <ExtendedItemList
            items={selectedData().slice(1)}
            path={RoutePath.News}
            listCss={newsStyles.wrapper}
            itemCss={newsStyles.item}
            dateCss={newsStyles.itemDate}
            titleCss={newsStyles.itemTitle}
            descriptionCss={newsStyles.itemDescription}
          />

          <Pagination
            currentPage={currentPage}
            maxPages={maxPages}
            previous={previous}
            next={next}
            selectPage={selectPage}
          />
        </div>
      </Loading>
    </section>
  );
};
