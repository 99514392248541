/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { form } from 'styles';

interface Props {
  title: string;
}

export const FormHeader: React.FC<Props> = ({ title }) => (
  <header css={form.header}>
    <p>{title}</p>
  </header>
);
