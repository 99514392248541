import { css, SerializedStyles } from '@emotion/core';

import { colors, globalRadial, unit } from './vars.styles';
import { color } from './mixins.styles';
import { typescale } from './typescale.styles';

/**
 * Button base
 */
const buttonBase = css`
  display: inline-flex;
  align-items: center;
  position: relative;

  touch-action: manipulation;
  user-select: none;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;

  /**
  * Remove border radius on iOS buttons
  */
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-border-radius: 0;

  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  background: transparent;
  font-weight: bold;
  text-transform: uppercase;
  border: none;

  transition: opacity 0.15s ease-out, background 0.15s ease-out;

  &:focus {
    outline: 0;
  }

  /**
  * Disabled
  */

  &.is-disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    background-color: transparent;
  }

  &.is-disabled {
    pointer-events: none;
  }
`;

const primary = css`
  ${buttonBase}

  background: ${colors.primary};
  color: white;
  box-shadow: 0 2px 5px ${color('black', 0.2)};

  &:hover,
  &:focus {
    color: white;
    background: ${color(colors.primary, 0.5)};
  }

  &:disabled,
  &.is-disabled {
    background: ${colors.primary};
  }
`;

const disabled = css`
  &:disabled,
  &.is-disabled {
    background: ${colors.secondary};
  }
`;

const secondary = css`
  ${buttonBase}

  background: ${colors.secondary};
  color: white;

  &:hover,
  &:focus {
    color: white;
    background: ${color('gray', 0.2)};
  }

  ${disabled}
`;

const danger = css`
  ${buttonBase}

  background: ${colors.warning};
  color: white;
  box-shadow: 0 2px 5px ${color('black', 0.2)};

  &:hover,
  &:focus {
    color: white;
    background: ${color('red', 0.7)}; 
  }

  &:disabled,
  &.is-disabled {
    background: ${colors.warning};
  }
`;

const dark = css`
  ${buttonBase}

  background: ${colors.dark};
  color: white;
  box-shadow: 0 2px 5px ${color('black', 0.2)};

  &:hover,
  &:focus {
    color: white;
    background: ${color('black', 0.7)};
  }

  &:disabled,
  &.is-disabled {
    background: ${colors.dark};
  }
`;

const outline = css`
  ${buttonBase}

  background: transparent;
  color: ${colors.text};
  border: 1px solid ${color(colors.dark, 0.1)};

  &:hover,
  &:focus {
    border-color: ${colors.primary};
  }

  &:disabled,
  &.is-disabled {
    opacity: 0.4;
  }
`;

const outlineActive = css`
  border-color: ${colors.primary};
  background: ${color(colors.primary, 0.1)};
`;

const ghost = css`
  ${buttonBase}

  background: transparent;
  color: ${color(colors.text, 0.6)};
  border: 1px solid ${color(colors.dark, 0.1)};

  &:hover,
  &:focus {
    color: ${colors.primary};
  }

  &:disabled,
  &.is-disabled {
    opacity: 0.4;
  }
`;

const container = css`
  ${ghost}

  padding: ${unit * 3}px;
  height: auto;
  border: none;
  box-shadow: none;

  &:hover,
  &:focus {
    box-shadow: none;
  }
`;

/**
 * Button sizes
 */
const small = css`
  padding: ${unit * 2}px ${unit * 4 + 2}px;
  border-radius: ${globalRadial * 5}px;
  ${typescale.tiny}
`;

const medium = css`
  padding: ${unit * 2 + 2}px ${unit * 6 + 2}px;
  border-radius: ${globalRadial * 5}px;
`;

const large = css`
  padding: ${unit * 3 + 2}px ${unit * 8}px;
  border-radius: ${globalRadial * 50}px;
  text-transform: uppercase;
`;

/**
 * Size helper
 */
const createSizeMap = (style: SerializedStyles) => ({
  small: css(style, small),
  medium: css(style, medium),
  large: css(style, large),
});

/**
 * Export all available button styles
 */
export const buttons = {
  primary: createSizeMap(primary),
  secondary: createSizeMap(secondary),
  dark: createSizeMap(dark),
  danger: createSizeMap(danger),
  outline: createSizeMap(outline),
  outlineActive: createSizeMap(outlineActive),
  ghost: createSizeMap(ghost),
  container,
};
