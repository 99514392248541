import createCachedSelector from 're-reselect';

import { ApplicationState } from 'modules/redux-store';
import { getSearchQuery, applySearchParams } from 'modules/search';

const announcements = (state: ApplicationState) =>
  state.announcements.announcements;
const publishFilter = (state: ApplicationState) =>
  state.announcements.publishFilter;

const getAnnouncementsByPublishedFiltersAndQuery = createCachedSelector(
  announcements,
  publishFilter,
  getSearchQuery,
  (announcements, filter, query) => {
    return announcements
      .filter(({ title }) => applySearchParams([title], query))
      .filter(
        announcementItem =>
          (filter === 'All' && announcementItem) ||
          (filter === 'Published' && announcementItem.published) ||
          (filter === 'Draft' && !announcementItem.published),
      );
  },
)((_announcements, publishFilter, query) => `${query}_${publishFilter}`);

export const AnnouncementSelectors = {
  getAnnouncementsByPublishedFiltersAndQuery,
};
