import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';

import { Modal } from './Modal';

interface Props {
  when: boolean;
}

export const PageGuard: React.FC<Props> = ({ when }) => {
  return (
    <NavigationPrompt when={when}>
      {({ onConfirm, onCancel }) => (
        <Modal
          type="info"
          isShowing
          hide={onCancel}
          onConfirm={onConfirm}
          customMessage="Imate promjena koje nisu spremljene, jeste li sigurni da želite zatvoriti?"
        />
      )}
    </NavigationPrompt>
  );
};
