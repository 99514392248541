import { Reservation } from 'models';

import { ReservationsActions } from './actions';
import { ReservationsTypes } from './types';

export interface ReservationsState {
  reservationsAreChanging: boolean;
  error?: string;
  reservations: Reservation[];
}

const INITIAL_STATE: ReservationsState = {
  reservationsAreChanging: true,
  error: undefined,
  reservations: [],
};

export default (
  state: ReservationsState = INITIAL_STATE,
  action: ReservationsActions,
) => {
  switch (action.type) {
    case ReservationsTypes.AddStateChanging:
      return {
        ...state,
        reservationsAreChanging: true,
        error: undefined,
      };

    case ReservationsTypes.AddReservations:
      return {
        ...state,
        reservationsAreChanging: false,
        error: undefined,
        reservations: action.payload.reservations,
      };

    case ReservationsTypes.AddError:
      return {
        ...state,
        reservationsAreChanging: false,
        error: action.payload.error,
      };

    default:
      return state || INITIAL_STATE;
  }
};
