/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { alert } from 'styles';

interface Props {
  error?: string;
}

export const FormError: React.FC<Props> = ({ error }) =>
  error ? (
    <div css={alert.danger}>
      <p>{error}</p>
    </div>
  ) : null;
