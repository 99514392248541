import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { Announcement } from 'models';
import {
  FirestoreListenerActions,
  FirestoreQueryParam,
} from 'modules/firebase';
import { withId } from 'utils';

import { AnnouncementsActions } from './actions';

import { AnnouncementsService } from '../services';

export const getPublishedAnnouncements = () => (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const publishedAnnouncementsListener = getState().listeners
    .publishedAnnouncementsListener;
  if (publishedAnnouncementsListener) {
    return;
  }

  dispatch(AnnouncementsActions.addRequest());

  const successFunction = (data: Announcement[]) => {
    dispatch(
      AnnouncementsActions.addAnnouncement(
        data.sort(
          (a, b) =>
            +(b.dateOfPublishing as firebase.firestore.Timestamp)?.toDate() -
            +(a.dateOfPublishing as firebase.firestore.Timestamp)?.toDate(),
        ),
      ),
    );
  };

  const errorFunction = (error: string) =>
    dispatch(AnnouncementsActions.addError(error));

  const listener = AnnouncementsService.Database.filterAndListen(
    successFunction,
    errorFunction,
    new FirestoreQueryParam('published', '==', true),
  );
  dispatch(
    FirestoreListenerActions.SetListener(
      'publishedAnnouncementsListener',
      listener,
    ),
  );

  return;
};

export const getAllAnnouncements = () => (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const announcementsListener = getState().listeners.announcementsListener;
  if (announcementsListener) {
    return;
  }

  dispatch(AnnouncementsActions.addRequest());

  const successFunction = (data: Announcement[]) => {
    dispatch(
      AnnouncementsActions.addAnnouncement(
        data.sort(
          (a, b) =>
            +(b.createdAt as firebase.firestore.Timestamp)?.toDate() -
            +(a.createdAt as firebase.firestore.Timestamp)?.toDate(),
        ),
      ),
    );
  };

  const errorFunction = (error: string) =>
    dispatch(AnnouncementsActions.addError(error));

  const listener = AnnouncementsService.Database.filterAndListen(
    successFunction,
    errorFunction,
  );
  dispatch(
    FirestoreListenerActions.SetListener('announcementsListener', listener),
  );

  return;
};

export const saveAnnouncement = (announcement: Announcement) => async () => {
  const response = await AnnouncementsService.Database.update(announcement);
  if (typeof response === 'string') {
    return response;
  }

  return;
};

export const publishOrUnpublishedAnnouncement = (docId: string) => async (
  _dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const selectedAnnouncement = getState().announcements.announcements.find(
    withId(docId),
  );
  if (!selectedAnnouncement) return;

  if (selectedAnnouncement.published) {
    const response = await AnnouncementsService.Database.unpublish(docId);
    return response;
  }

  const response = await AnnouncementsService.Database.publish(docId);
  return response;
};

export const deleteAnnouncement = (id: string) => () => {
  AnnouncementsService.Database.remove(id);
};

export const AnnouncementsThunks = {
  getPublishedAnnouncements,
  getAllAnnouncements,
  saveAnnouncement,
  publishOrUnpublishedAnnouncement,
  deleteAnnouncement,
};
