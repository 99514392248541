export enum RoutePath {
  HomePage = '/',
  Supply = '/ponuda',
  SupplyDetails = '/ponuda/:slug/:id',
  Demand = '/potraznja',
  News = '/novosti',
  NewsDetails = '/novosti/:slug/:id',
  Announcements = '/najave',
  AnnouncementDetails = '/najave/:slug/:id',
  Contact = '/kontakt',
  Donate = '/doniraj-volontiraj',
  About = '/o-projektu',
  PrivacyPolicy = '/politika-privatnosti',
  Login = '/login',
  Dashboard = '/admin',
  DashboardAnnouncements = '/admin/najave',
  DashboardAnnouncementDetails = '/admin/najave/:id',
  DashboardContacts = '/admin/kontakti',
  DashboardContactDetails = '/admin/kontakti/:id',
  DashboardSupply = '/admin/ponuda',
  DashboardSupplyDetails = '/admin/ponuda/:id',
  DashboardNews = '/admin/novosti',
  DashboardNewsDetails = '/admin/novosti/:id',
  DashboardDemand = '/admin/potraznja',
  DashboardDemandDetails = '/admin/potraznja/:id',
  DashboardReservations = '/admin/rezervacije',
  DashboardReservationDetails = '/admin/rezervacije/:id',
  Unauthorized = '/neovlasten-pristup',
  NotFound = '/greska',
}
