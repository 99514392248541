import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkDispatch } from 'redux-thunk';
import {
  applyMiddleware,
  combineReducers,
  createStore,
  AnyAction,
} from 'redux';

import { authenticationReducer } from 'modules/authentication';
import { firestoreListenerReducer } from 'modules/firebase';
import { donationsReducer } from 'modules/donations';
import { newsReducer } from 'modules/news';
import { announcementsReducer } from 'modules/announcements';
import { contactsReducer } from 'modules/contacts/redux';
import { searchReducer } from 'modules/search';
import { requestsReducer } from 'modules/requests';
import { reservationsReducer } from 'modules/reservations/redux';

import { ApplicationState } from '../models';

export const configureStore = () => {
  /**
   * Create the composing function for our middlewares
   * Include dev tools support
   */
  const composeEnhancers = composeWithDevTools({});

  /**
   * Merge all reducers into a single object
   */
  const rootReducer = {
    auth: authenticationReducer,
    donations: donationsReducer,
    requests: requestsReducer,
    reservations: reservationsReducer,
    news: newsReducer,
    announcements: announcementsReducer,
    contacts: contactsReducer,
    search: searchReducer,
    listeners: firestoreListenerReducer,
  };

  /**
   * We'll create our store with the combined reducers and all enchancers
   */
  const store = createStore(
    combineReducers(rootReducer),
    {},
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};

export type AppDispatch = ThunkDispatch<ApplicationState, any, AnyAction>;
