/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useRef } from 'react';
import { FormContext } from 'react-hook-form';

import { Contact as ContactModel } from 'models';
import {
  ValidatedInput,
  FormSubmit,
  FormCheckbox,
  FormHeader,
  CompletedFormMessage,
  Helmet,
} from 'components';
import { form, wrappers, content, contact, spacers } from 'styles';
import { useFormLogic } from 'hooks';
import { EmailRegExp, PhoneRegExp } from 'consts';

export const Contact: React.FC = () => {
  const alertEl = useRef<HTMLDivElement>(null);
  const {
    methods,
    saveContact,
    formIsLoading,
    formCompleted,
    formError,
    handleReturnToForm,
  } = useFormLogic<ContactModel>(undefined, undefined, alertEl.current);

  return (
    <section css={[wrappers.normal, content.padding]}>
      <Helmet
        title="Socijalna burza - Kontakt"
        description="Obratite nam se s povjerenjem putem kontakt obrasca ili dostupnih podataka."
      />

      <FormHeader title="Kontakt" />
      <div css={contact.wrapper}>
        <div css={contact.info}>
          <p css={contact.infoLarge}>Socijalna burza</p>
          <p css={contact.infoSmall}>prijatelji.sv.martina@gmail.com</p>
          <p css={[contact.infoSmall, spacers.bottom.medium]}>
            +385 97 698 2901
          </p>

          <p css={contact.infoLarge}>Udruga Prijatelji Svetog Martina</p>
          <p css={[contact.infoSmall, spacers.bottom.medium]}>
            Trg Slobode 16a, Beli Manastir
          </p>

          <p css={contact.infoLarge}>Socijalna trgovina Beli Manastir</p>
          <p css={[contact.infoSmall, spacers.bottom.medium]}>
            Osječka 49a, Beli Manastir
          </p>

          <p css={contact.infoLarge}>Socijalna trgovina Popovac</p>
          <p css={[contact.infoSmall, spacers.bottom.medium]}>
            Vladimira Nazora 32, Popovac
          </p>

          <p css={contact.infoLarge}>Socijalna burza i praonica</p>
          <p css={contact.infoSmall}>Josipa Pančića 27c, Beli Manastir</p>

          <p css={contact.infoSmall}>IBAN: HR67 2500 0091 1014 18431</p>

          <img
            src={require('assets/images/kontakt_vector.svg')}
            alt="kontakt"
            css={contact.coverImg}
          />
        </div>

        {!formCompleted && (
          <FormContext {...methods}>
            <form
              css={contact.formWrapper}
              onSubmit={methods.handleSubmit(saveContact)}
            >
              <div css={form.flexFields}>
                <ValidatedInput
                  label="Ime"
                  placeholder="Vaše ime"
                  name="firstName"
                  validationObject={{ required: true }}
                />

                <ValidatedInput
                  label="Prezime"
                  placeholder="Vaše prezime"
                  name="lastName"
                  validationObject={{ required: true }}
                />
              </div>

              <div css={form.flexFields}>
                <ValidatedInput
                  label="E-mail"
                  placeholder="Vaša e-mail adresa"
                  name="email"
                  errMsg="Krivi format e-mail adrese"
                  validationObject={{ pattern: EmailRegExp }}
                />

                <ValidatedInput
                  label="Broj telefona"
                  placeholder="Vaš broj telefona"
                  name="phone"
                  errMsg="Krivi format telefonskog broja"
                  validationObject={{ required: true, pattern: PhoneRegExp }}
                />
              </div>

              <ValidatedInput
                label="Naslov"
                placeholder="Naslov Vašeg upita"
                name="title"
                validationObject={{ required: true }}
              />

              <ValidatedInput
                textarea
                label="Upit"
                placeholder="Upišite upit"
                name="message"
                validationObject={{ required: true }}
              />

              <FormCheckbox name="policyAccepted" required />

              <FormSubmit
                value="Pošaljite upit"
                stateIsChanging={formIsLoading}
              />
            </form>
          </FormContext>
        )}

        {formCompleted && (
          <div css={contact.formWrapper}>
            <CompletedFormMessage
              title="Hvala Vam! Javit ćemo Vam se s odgovorom na upit u najkraćem
            mogućem vremenu. U slučaju hitnosti, možete nas kontaktirati na
            navedeni broj telefona."
              btnText="Pošalji novi upit"
              handleReturn={handleReturnToForm}
              formError={formError}
              ref={alertEl}
            />
          </div>
        )}
      </div>
    </section>
  );
};
