/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import { useFormContext, ErrorMessage } from 'react-hook-form';

import { SelectOption } from 'models';
import { fields, inputs } from 'styles';
import { isSelectOption } from 'utils';

interface Props {
  name: string;
  label: string;
  sublabel?: string;
  defaultValue?: SelectOption;
  options: SelectOption[];
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export const ValidatedSelect: React.FC<Props> = ({
  name,
  label,
  sublabel,
  defaultValue,
  required,
  options,
  disabled,
  placeholder,
}) => {
  const {
    register,
    control,
    errors,
    setValue,
    triggerValidation,
  } = useFormContext();
  const [option, setOption] = useState<SelectOption | undefined>(defaultValue);

  const handleValidate = useCallback(() => {
    triggerValidation(name);
  }, [triggerValidation, name]);

  useEffect(() => {
    register(name, { required: required });
  }, [register, name, required]);

  useEffect(() => {
    if (option && isSelectOption(option)) {
      setValue(name, option.value);
      handleValidate();
    }
  }, [option, name, setValue, triggerValidation, handleValidate]);

  return (
    <div css={fields.field}>
      <label htmlFor={name} css={fields.label}>
        {label}
      </label>

      <Select
        options={options}
        name={name}
        rules={{ required: required }}
        css={[
          inputs.inputSelect,
          errors && errors[name] && inputs.inputSelectError,
        ]}
        control={control}
        value={option}
        onChange={handleChange}
        isDisabled={disabled}
        onBlur={handleValidate}
        placeholder={placeholder}
      />

      <p css={fields.label}>{sublabel}</p>

      <ErrorMessage
        errors={errors || {}}
        name={name}
        message="Obavezno polje"
        as={<p css={fields.errorMsg} />}
      />
    </div>
  );

  function handleChange(value: any) {
    setOption(value);
  }
};
