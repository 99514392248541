import { css } from '@emotion/core';

import { fontFamily, colors } from './vars.styles';
import { typescale } from './typescale.styles';

export const globalStyles = css`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;

    font-family: ${fontFamily.sans};
    ${typescale.base}
    color: ${colors.text};
    background: ${colors.bg};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  ul,
  ol,
  dl {
    list-style-position: inside;
  }

  ul[class],
  ol[class],
  dl[class] {
    list-style: none;
  }

  img {
    display: block;
    height: auto;
  }

  a {
    text-decoration: none;
    color: ${colors.primary};
  }

  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/Merriweather-Regular.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/Merriweather-Bold.ttf') format('ttf');
  }

  @font-face {
    font-family: 'PTSans';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/PTSans-Regular.ttf') format('ttf');
  }
  @font-face {
    font-family: 'PTSans';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/PTSans-Bold.ttf') format('ttf');
  }
`;
