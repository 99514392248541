import { css } from '@emotion/core';

import { color } from './mixins.styles';
import { colors, globalRadial, unit } from './vars.styles';
import { typescale } from './typescale.styles';
import { mqFrom } from './responsive.styles';

const container = css`
  width: 100%;
  max-width: 376px;
  height: 376px;
  outline: none;
  border-radius: ${globalRadial * 2 + 2}px;
  transition: border 0.15s ease-out;

  ${mqFrom.Sml} {
    width: 376px;
  }
`;

const wrapper = css`
  ${container}

  border: 2px dashed ${color(colors.dark, 0.2)};
  color: ${color(colors.dark, 0.9)};
  cursor: pointer;

  &:hover {
    border-color: ${color(colors.primary, 0.3)};
  }
`;

const placeholder = css`
  position: relative;
  width: 100%;
  max-width: 376px;
  height: 376px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${unit * 18}px;
  ${typescale.tiny}
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${color(colors.text, 0.9)};
`;

const placeholderImg = css`
  margin-bottom: ${unit * 4 + 2}px;
`;

const placeholderParagraph = css`
  ${typescale.tiny}
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${color(colors.text, 0.9)};
  opacity: 0.7;
`;

const placeholderSpan = css`
  margin: ${unit * 3}px 0;
  opacity: 0.7;
  text-transform: lowercase;
`;

const imgWrapper = css`
  position: relative;
  text-align: center;
  font-size: 20px;
  color: ${colors.primary};
  padding: 0;
  height: 100%;
`;

const imageCore = css`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: ${globalRadial * 2 + 2}px;
  transition: none;
  background: ${colors.tertiary};
`;

const mainImg = css`
  ${imageCore}
  cursor: pointer;

  &:hover {
    border: 2px dashed ${color(colors.primary, 0.3)};
  }

  & > img {
    ${imageCore}
  }
`;

const thumbnailsWrapper = css`
  width: 100%;
  max-width: 376px;
  overflow-x: auto;
  white-space: nowrap;
  padding-top: 12px;
`;

const thumbnail = css`
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: ${globalRadial + 1}px;

  &:hover {
    cursor: pointer;
  }

  & > img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: ${globalRadial + 1}px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const imgLoadingOverlay = css`
  position: absolute;
  z-index: 12;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color(colors.gray, 0.7)};
  border-radius: ${globalRadial * 2}px;
`;

const mainImgDeleteBtn = css`
  position: absolute;
  top: 15px;
  right: 15px;
  background: white;
  border-radius: ${globalRadial * 10}px;
  border: 1px solid ${color(colors.dark, 0.1)};
  padding: ${unit}px;
  width: 30px;
  height: 30px;
  transition: transform 0.15s ease-out;
  cursor: pointer;

  &:hover {
    transform: scale3d(1.2, 1.2, 1);
  }
`;

const thumbContainer = css`
  display: inline-block;
  position: relative;
`;

const thumbImgDeleteBtn = css`
  ${mainImgDeleteBtn}

  padding: 0;
  top: -10px;
  right: 0;
  width: 20px;
  height: 20px;
`;

export const dropzone = {
  container,
  wrapper,
  thumbnail,
  thumbnailsWrapper,
  mainImg,
  placeholder,
  placeholderImg,
  placeholderParagraph,
  placeholderSpan,
  imgWrapper,
  imgLoadingOverlay,
  mainImgDeleteBtn,
  thumbContainer,
  thumbImgDeleteBtn,
};
