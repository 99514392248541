import { News } from 'models';
import { PublishedFilter } from 'consts';

import { NewsActions } from './actions';
import { NewsTypes } from './types';

export interface NewsState {
  newsAreChanging: boolean;
  error?: string;
  news: News[];
  publishFilter: PublishedFilter;
}

const INITIAL_STATE: NewsState = {
  newsAreChanging: true,
  error: undefined,
  news: [],
  publishFilter: 'All',
};

export default (state: NewsState = INITIAL_STATE, action: NewsActions) => {
  switch (action.type) {
    case NewsTypes.AddRequest:
      return {
        ...state,
        newsAreChanging: true,
        error: undefined,
      };

    case NewsTypes.AddNews:
      return {
        ...state,
        newsAreChanging: false,
        error: undefined,
        news: action.payload.news,
      };

    case NewsTypes.AddError:
      return {
        ...state,
        newsAreChanging: false,
        error: action.payload.error,
      };

    case NewsTypes.AddPublishFilter:
      return {
        ...state,
        publishFilter: action.payload.filter,
      };

    default:
      return state || INITIAL_STATE;
  }
};
