import { css } from '@emotion/core';

import { unit, colors, fontFamily, fontWeights } from './vars.styles';
import { color } from './mixins.styles';

const layout = css`
  display: flex;
  min-height: 100vh;
`;

const layoutMain = css`
  flex: 4;
  padding: 0 ${unit * 12}px;
  width: 80vw;
`;

const layoutAside = css`
  flex: 1;
  background: ${colors.tertiary};
`;

const pageHeader = css`
  font-family: ${fontFamily.serif};
  font-weight: ${fontWeights.weightRegular};
`;

const textRow = css`
  display: flex;
  justify-content: flex-start;

  & > div {
    min-width: 40%;
  }
`;

const category = css`
  margin-bottom: ${unit * 10}px;

  &:hover {
    cursor: default;
    color: ${color(colors.text, 0.6)};
  }
`;

export const admin = {
  layout,
  layoutMain,
  layoutAside,
  pageHeader,
  textRow,
  category,
};
