import createCachedSelector from 're-reselect';

import { ApplicationState } from 'modules/redux-store';
import { getSearchQuery, applySearchParams } from 'modules/search';

const reservations = (state: ApplicationState) =>
  state.reservations.reservations;

const getReservationsByQuery = createCachedSelector(
  reservations,
  getSearchQuery,
  (reservations, query) => {
    return reservations.filter(({ firstName, lastName, email }) =>
      applySearchParams([firstName, lastName, email], query),
    );
  },
)((_reservations, query) => `${query}`);

export const ReservationsSelectors = {
  getReservationsByQuery,
};
