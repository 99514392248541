import { css } from '@emotion/core';

import { unit, globalRadial, colors } from './vars.styles';
import { color } from './mixins.styles';

const container = css`
  position: relative;
  display: block;
  padding: ${unit * 4}px;
  border-radius: ${globalRadial * 4}px;
  margin-bottom: ${unit * 4}px;
`;

const success = css`
  ${container}
  background-color: ${color('green', 0.1)};
`;

const danger = css`
  ${container}
  background-color: ${color(colors.warning, 0.1)};
  `;

export const alert = {
  success,
  danger,
};
