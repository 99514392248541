/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/core';
import React from 'react';

import { buttons, loading } from 'styles';

interface Props {
  value: string;
  stateIsChanging?: boolean;
  disabled?: boolean;
  className?: SerializedStyles | SerializedStyles[];
}

export const FormSubmit: React.FC<Props> = ({
  value,
  stateIsChanging,
  disabled,
  className,
}) =>
  stateIsChanging ? (
    <button type="button" disabled css={className || buttons.primary.medium}>
      <span css={loading.spinner} />
    </button>
  ) : (
    <input
      type="submit"
      value={value}
      css={className || buttons.primary.medium}
      disabled={disabled}
    />
  );
