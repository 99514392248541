import { css } from '@emotion/core';

import { mqFrom } from './responsive.styles';
import { unit } from './vars.styles';
import { keyFrames } from './kf.styles';

const wrapper = css`
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: ${unit * 13}px;
  padding-top: ${unit * 2}px;
  align-items: center;

  ${mqFrom.Lrg} {
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${unit * 9}px;
  }
`;

const formWrapper = css`
  animation: ${keyFrames.appear} 1s ease-out;
  max-width: 580px;
`;

const coverImg = css`
  margin-bottom: ${unit * 11}px;
  max-width: 100%;

  ${mqFrom.Lrg} {
    margin-bottom: 0;
    margin-left: ${unit * 9}px;
  }
`;

export const demand = {
  wrapper,
  formWrapper,
  coverImg,
};
