/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { PublicLayout, Helmet } from 'components';
import { wrappers, content, layout, buttons, spacers } from 'styles';
import { RoutePath } from 'modules/navigation';

export const Page404: React.FC = () => {
  return (
    <PublicLayout>
      <Helmet
        title="Socijalna burza - 404"
        description="Tražena stranica ne postoji."
      />

      <div css={[wrappers.normal, content.padding, layout.notFound]}>
        <p css={[spacers.bottom.large]}>Tražena stranica ne postoji.</p>

        <Link to={RoutePath.HomePage} type="button" css={buttons.primary.large}>
          Natrag na naslovnicu
        </Link>
      </div>
    </PublicLayout>
  );
};
