/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { home, wrappers } from 'styles';
import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { RoutePath } from 'modules/navigation';
import { FormError, EmptyListState, ItemList } from 'components';

import { HomeNewsHero } from './HomeNewsHero';

import { NewsThunks } from '../redux';

export const HomepageNews: React.FC = () => {
  const { news, newsAreChanging, error } = useSelector(
    (state: ApplicationState) => state.news,
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(NewsThunks.getPublishedNews());
  }, [dispatch]);

  return (
    <div css={home.newsBg}>
      <div css={[wrappers.normal, home.newsTitle]}>
        <h3>Novosti</h3>

        {!newsAreChanging && (
          <Link css={home.newsLinkTop} to={RoutePath.News}>
            Pogledaj sve
          </Link>
        )}
      </div>

      <div css={wrappers.normal}>
        <FormError error={error} />

        <EmptyListState
          title="Trenutno nema novosti"
          data={news}
          isLoading={newsAreChanging}
        />
      </div>

      <HomeNewsHero item={news[0]} isLoading={newsAreChanging} />

      <ItemList
        items={news.slice(1, 4)}
        path={RoutePath.News}
        listCss={[wrappers.normal, home.newsCardsWrapper]}
        itemCss={home.newsCard}
        placeholderItems={3}
        isLoading={newsAreChanging}
      />

      {!newsAreChanging && (
        <Link css={[wrappers.normal, home.newsLinkBottom]} to={RoutePath.News}>
          Pogledaj sve
        </Link>
      )}
    </div>
  );
};
