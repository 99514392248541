export interface PageHeaderAction {
  name: string;
  actionHandler?: string | VoidFunction | ((...arg: []) => void);
}

export class PageHeaderAction {
  constructor(
    name: string,
    actionHandler?: string | VoidFunction | ((...arg: []) => void),
  ) {
    this.name = name;
    this.actionHandler = actionHandler;
  }
}
