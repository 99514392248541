import { ActionUnion } from 'modules/redux-store';

import { SearchActions } from './actions';
import { SearchActionTypes } from './types';

export interface SearchState {
  readonly query: string;
  readonly orderFilter: 'asc' | 'desc';
}

const INITIAL_STATE: SearchState = {
  query: '',
  orderFilter: 'desc',
};

export default (
  state: SearchState = INITIAL_STATE,
  action: ActionUnion<typeof SearchActions>,
): SearchState => {
  switch (action.type) {
    case SearchActionTypes.ClearSearch:
      return INITIAL_STATE;
    case SearchActionTypes.QuerySearch:
      return {
        ...state,
        query: action.payload.query,
      };
    case SearchActionTypes.UpdateOrderFilter:
      return {
        ...state,
        orderFilter: action.payload.orderFilter,
      };
    default:
      return state || INITIAL_STATE;
  }
};
