import * as views from 'views';

import { RoutePath } from './RoutePath';

import { AppRoute } from '../models';

export const authenticatedRoutes: AppRoute[] = [
  {
    key: 'dashboard-supply',
    title: 'Ponuda donacija i volontera',
    exact: true,
    path: RoutePath.DashboardSupply,
    accessibilityLevel: 'authenticated',
    component: views.DashboardSupply,
  },
  {
    key: 'dashboard-supply-details',
    title: 'Dashboard Supply Details',
    exact: true,
    path: RoutePath.DashboardSupplyDetails,
    accessibilityLevel: 'authenticated',
    component: views.DashboardSupplyDetails,
  },
  {
    key: 'dashboard-demand',
    title: 'Potražnja donacija i volontera',
    exact: true,
    path: RoutePath.DashboardDemand,
    accessibilityLevel: 'authenticated',
    component: views.DashboardDemand,
  },
  {
    key: 'dashboard-demand-details',
    title: 'Dashboard Demand Details',
    exact: true,
    path: RoutePath.DashboardDemandDetails,
    accessibilityLevel: 'authenticated',
    component: views.DashboardDemandDetails,
  },
  {
    key: 'dashboard-reservations',
    title: 'Rezervacije donacija i volontera',
    exact: true,
    path: RoutePath.DashboardReservations,
    accessibilityLevel: 'authenticated',
    component: views.DashboardReservations,
  },
  {
    key: 'dashboard-reservation-details',
    title: 'Dashboard Reservation Details',
    exact: true,
    path: RoutePath.DashboardReservationDetails,
    accessibilityLevel: 'authenticated',
    component: views.DashboardReservationDetails,
  },
  {
    key: 'dashboard-news',
    title: 'Novosti',
    exact: true,
    path: RoutePath.DashboardNews,
    accessibilityLevel: 'authenticated',
    component: views.DashboardNews,
  },
  {
    key: 'dashboard-news-details',
    title: 'Dashboard News Details',
    exact: true,
    path: RoutePath.DashboardNewsDetails,
    accessibilityLevel: 'authenticated',
    component: views.DashboardNewsDetails,
  },
  {
    key: 'dashboard-announcements',
    title: 'Najave',
    exact: true,
    path: RoutePath.DashboardAnnouncements,
    accessibilityLevel: 'authenticated',
    component: views.DashboardAnnouncements,
  },
  {
    key: 'dashboard-announcement-details',
    title: 'Dashboard Announcement Details',
    exact: true,
    path: RoutePath.DashboardAnnouncementDetails,
    accessibilityLevel: 'authenticated',
    component: views.DashboardAnnouncementDetails,
  },
  {
    key: 'dashboard-contacts',
    title: 'Kontakti',
    exact: true,
    path: RoutePath.DashboardContacts,
    accessibilityLevel: 'authenticated',
    component: views.DashboardContacts,
  },
  {
    key: 'dashboard-contact-details',
    title: 'Dashboard Contact Details',
    exact: true,
    path: RoutePath.DashboardContactDetails,
    accessibilityLevel: 'authenticated',
    component: views.DashboardContactDetails,
  },
];
