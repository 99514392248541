import { css } from '@emotion/core';

import { unit, colors, globalRadial } from './vars.styles';
import { color } from './mixins.styles';

const flexWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${unit * 25}px ${unit * 4}px;
`;

const wrapper = css`
  border: none;
  box-shadow: 0 2px 5px ${color(colors.dark, 0.2)};
  background: ${colors.tertiary};
  border-radius: ${globalRadial * 2 + 2}px;
  width: 100%;
  max-width: 480px;
`;

const header = css`
  padding: ${unit * 10}px ${unit * 10}px ${unit * 5}px ${unit * 10}px;
`;

const content = css`
  padding: 0 ${unit * 10}px;
`;

const checkbox = css`
  margin: ${unit * 5}px 0;
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  color: ${color(colors.text, 0.9)};
  opacity: 0.7;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    margin-left: ${unit * 4}px;
  }
`;

const action = css`
  float: right;
  padding-bottom: ${unit * 10}px;
`;

export const auth = {
  flexWrapper,
  wrapper,
  header,
  content,
  checkbox,
  action,
};
