/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { News, PageHeaderAction } from 'models';
import { usePagination } from 'hooks';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import {
  NewsThunks,
  NewsSelectors,
  NewsActions,
  PublishedNewsOptions,
  NewsService,
} from 'modules/news';
import { content } from 'styles';
import {
  PageHeader,
  Loading,
  FormError,
  EmptyListState,
  DashboardList,
  Pagination,
} from 'components';
import { RoutePath } from 'modules/navigation';
import { SearchActions } from 'modules/search';
import { supply } from 'styles/supply.styles';

export const DashboardNews: React.FC = () => {
  const { news, newsAreChanging, error, publishFilter } = useSelector(
    (state: ApplicationState) => state.news,
  );
  const filteredNews = useSelector((state: ApplicationState) =>
    NewsSelectors.getNewsByPublishedFiltersAndQuery(state),
  );
  const dispatch = useDispatch<AppDispatch>();
  const {
    currentPage,
    maxPages,
    next,
    previous,
    selectPage,
    selectedData,
  } = usePagination<News>(filteredNews, 16);

  useEffect(() => {
    dispatch(NewsThunks.getAllNews());
  }, [dispatch]);

  return (
    <section css={content.area}>
      <PageHeader
        onSearch={handleSearch}
        title="Novosti"
        actions={[
          new PageHeaderAction(
            'Kreiraj novost',
            `${RoutePath.DashboardNews}/${NewsService.Database.generateId()}`,
          ),
        ]}
      />
      <div css={supply.filtersFlex}>
        <Select
          options={PublishedNewsOptions}
          name="publishedFilter"
          value={PublishedNewsOptions.find(
            option => option.value === publishFilter,
          )}
          onChange={handlePublishedFilterChange}
        />
      </div>

      <Loading isLoading={newsAreChanging}>
        <FormError error={error} />
        <EmptyListState data={news} title="Trenutno nema novosti" />
        {news.length > 0 && (
          <EmptyListState
            data={selectedData()}
            title="Nema rezultata za navedene filtre"
          />
        )}

        <DashboardList
          data={selectedData()}
          path={RoutePath.DashboardNews}
          handleDelete={handleDelete}
        />

        <Pagination
          currentPage={currentPage}
          maxPages={maxPages}
          previous={previous}
          next={next}
          selectPage={selectPage}
        />
      </Loading>
    </section>
  );

  function handlePublishedFilterChange(data: any) {
    dispatch(NewsActions.addPublishFilter(data.value));
  }

  function handleSearch(query: string) {
    dispatch(SearchActions.querySearch(query));
  }

  function handleDelete(id?: string) {
    if (!id) return;

    dispatch(NewsThunks.deleteNews(id));
  }
};
