import createCachedSelector from 're-reselect';

import { ApplicationState } from 'modules/redux-store';
import { getSearchQuery, applySearchParams } from 'modules/search';

const news = (state: ApplicationState) => state.news.news;
const publishFilter = (state: ApplicationState) => state.news.publishFilter;

const getNewsByPublishedFiltersAndQuery = createCachedSelector(
  news,
  publishFilter,
  getSearchQuery,
  (news, filter, query) => {
    return news
      .filter(({ title }) => applySearchParams([title], query))
      .filter(
        newsItem =>
          (filter === 'All' && newsItem) ||
          (filter === 'Published' && newsItem.published) ||
          (filter === 'Draft' && !newsItem.published),
      );
  },
)((_news, publishFilter, query) => `${query}_${publishFilter}`);

export const NewsSelectors = {
  getNewsByPublishedFiltersAndQuery,
};
