/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { MdKeyboardBackspace } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { PageHeaderAction } from 'models';
import { Search } from 'modules/search';
import { content, buttons, admin, spacers } from 'styles';

interface Props {
  title?: string | React.ReactElement;
  actions?: PageHeaderAction[];
  backAction?: PageHeaderAction;
  onSearch?: (query: string) => void;
}

export const PageHeader: React.FC<Props> = ({
  title,
  actions,
  backAction,
  onSearch,
}) => {
  const isRouteLink = (
    actionHandler: string | VoidFunction | ((...arg: []) => void),
  ): actionHandler is string => {
    return typeof actionHandler === 'string';
  };

  const renderAction = (action?: PageHeaderAction) => {
    if (!action) {
      return null;
    }

    if (action.actionHandler && isRouteLink(action.actionHandler)) {
      return (
        <Link css={buttons.primary.small} to={action.actionHandler}>
          {action.name}
        </Link>
      );
    }

    if (action.actionHandler && !isRouteLink(action.actionHandler)) {
      return (
        <button
          type="button"
          css={buttons.primary.small}
          onClick={action.actionHandler}
        >
          {action.name}
        </button>
      );
    }

    return <div>{action.name}</div>;
  };

  const renderBackButton = () => {
    if (!backAction || !backAction.actionHandler) {
      return null;
    }

    if (isRouteLink(backAction.actionHandler)) {
      return (
        <Link
          className="btn btn--med btn--outline btn--hasIcon"
          to={backAction.actionHandler}
          css={[spacers.bottom.medium]}
        >
          <MdKeyboardBackspace size={16} />
          <span>{backAction.name}</span>
        </Link>
      );
    }

    return null;
  };

  const renderTitle = () => {
    if (backAction) {
      return (
        <div>
          <h1 css={admin.pageHeader}>{title}</h1>
        </div>
      );
    }

    return (
      <div>
        <h1 css={admin.pageHeader}>{title}</h1>
      </div>
    );
  };

  const renderActions = () => {
    return (
      <div className="contentarea__heading__action">
        {actions &&
          actions.map((action, index) => (
            <div className="s-left--sml" key={index}>
              {renderAction(action)}
            </div>
          ))}
      </div>
    );
  };

  const renderSearch = () => {
    if (!onSearch) {
      return null;
    }

    return <Search onChange={onSearch} placeholder="Pretraži" />;
  };

  return (
    <header css={content.heading}>
      <div
        css={
          !title || !backAction ? content.headingMain : content.headingContent
        }
      >
        {renderBackButton()}
        {!backAction && renderTitle()}
        {!backAction && renderActions()}
      </div>

      {backAction && (
        <div css={content.headingMain}>
          {renderTitle()}
          {renderActions()}
        </div>
      )}

      {onSearch && <div css={content.headingSearch}>{renderSearch()}</div>}
    </header>
  );
};
