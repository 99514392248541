/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { ApplicationState } from 'modules/redux-store';
import { withId, slugify, getHeroImage } from 'utils';
import { newsStyles, home } from 'styles';
import { RoutePath } from 'modules/navigation';
import { extractDocId } from 'utils/extractDocId';

export const RelatedNews: React.FC = () => {
  const { id } = useParams();
  const docId = extractDocId(id);
  const { news } = useSelector((state: ApplicationState) => state.news);
  const currentNews = news.find(withId(docId));

  if (!currentNews) {
    return null;
  }

  let relatedNews = news
    .filter(
      item =>
        (item.dateOfPublishing as firebase.firestore.Timestamp).toDate() <
        (currentNews.dateOfPublishing as firebase.firestore.Timestamp).toDate(),
    )
    .slice(0, 3);

  if (!relatedNews.length) {
    relatedNews = news
      .filter(
        item =>
          (item.dateOfPublishing as firebase.firestore.Timestamp).toDate() >
          (currentNews.dateOfPublishing as firebase.firestore.Timestamp).toDate(),
      )
      .slice(0, 3);
  }

  return (
    <section css={newsStyles.relatedNewsWrapper}>
      {relatedNews.length > 0 && (
        <Fragment>
          <p css={newsStyles.detailsTitle}>Povezane novosti</p>

          <ul css={newsStyles.relatedNewsList}>
            {relatedNews.map(newsItem => (
              <div key={newsItem.id} css={newsStyles.item}>
                <Link
                  to={`${RoutePath.News}/${slugify(newsItem.title)}/${
                    newsItem.id
                  }`}
                >
                  <div>
                    <img
                      src={getHeroImage(
                        newsItem.photoUrls,
                        newsItem.heroImageIndex,
                      )}
                      alt="news cover"
                    />
                  </div>
                  <p css={home.newsItemDate}>
                    {format(
                      +(newsItem.dateOfPublishing as firebase.firestore.Timestamp),
                      'dd.LL.yyyy',
                    )}
                  </p>
                  <p css={home.newsItemTitle}>{newsItem.title}</p>
                  <p css={home.newsItemDescription}>{newsItem.description}</p>
                </Link>
              </div>
            ))}
          </ul>
        </Fragment>
      )}
    </section>
  );
};
