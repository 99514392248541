/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import {
  DashboardList,
  EmptyListState,
  FormError,
  Loading,
  PageHeader,
  Pagination,
} from 'components';
import {
  DonationsThunks,
  DonationsSelectors,
  SupplyFilters,
  DonationsActions,
  PublishedDonationOptions,
  DonationsService,
} from 'modules/donations';
import { RoutePath } from 'modules/navigation';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { SearchActions } from 'modules/search';
import { content } from 'styles';
import { usePagination } from 'hooks';
import { Donation, PageHeaderAction } from 'models';
import { supply } from 'styles/supply.styles';

export const DashboardSupply: React.FC = () => {
  const {
    donations,
    donationsAreChanging,
    error,
    publishedFilter,
  } = useSelector((state: ApplicationState) => state.donations);
  const filteredDonations = useSelector((state: ApplicationState) =>
    DonationsSelectors.getDonationsByFiltersAndQuery(state),
  );
  const dispatch = useDispatch<AppDispatch>();
  const {
    currentPage,
    maxPages,
    next,
    previous,
    selectPage,
    selectedData,
  } = usePagination<Donation>(filteredDonations, 16);

  useEffect(() => {
    dispatch(DonationsThunks.getAllDonations());
  }, [dispatch]);

  return (
    <section css={content.area}>
      <PageHeader
        onSearch={handleSearch}
        title="Ponuda donacija i volontera"
        actions={[
          new PageHeaderAction(
            'Kreiraj novu ponudu',
            `${
              RoutePath.DashboardSupply
            }/${DonationsService.Database.generateId()}`,
          ),
        ]}
      />
      <div css={supply.filtersFlex}>
        <SupplyFilters />
        <Select
          options={PublishedDonationOptions}
          name="publishedFilter"
          value={PublishedDonationOptions.find(
            option => option.value === publishedFilter,
          )}
          onChange={handlePublishedFilterChange}
        />
      </div>

      <Loading isLoading={donationsAreChanging}>
        <FormError error={error} />
        <EmptyListState
          data={donations}
          title="Trenutno nema donacija i volontera u ponudi"
        />
        {donations.length > 0 && (
          <EmptyListState
            data={selectedData()}
            title="Nema rezultata za navedene filtre"
          />
        )}

        <DashboardList
          data={selectedData()}
          path={RoutePath.DashboardSupply}
          handleDelete={handleDelete}
        />

        <Pagination
          currentPage={currentPage}
          maxPages={maxPages}
          previous={previous}
          next={next}
          selectPage={selectPage}
        />
      </Loading>
    </section>
  );

  function handleSearch(query: string) {
    dispatch(SearchActions.querySearch(query));
  }

  function handleDelete(id?: string) {
    if (!id) return;

    dispatch(DonationsThunks.deleteDonation(id));
  }

  function handlePublishedFilterChange(data: any) {
    dispatch(DonationsActions.addPublishedFilter(data.value));
  }
};
