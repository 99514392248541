import { Dispatch } from 'redux';

import { ApplicationState } from 'modules/redux-store';

import { FirestoreListenerActions } from './actions';

const unsubscribeListeners = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  const listeners = getState().listeners;
  for (const prop in listeners) {
    if (listeners.hasOwnProperty(prop)) {
      const unsubscribeListener = await listeners[prop];
      if (unsubscribeListener) {
        await unsubscribeListener();
      }
    }
  }

  await dispatch(FirestoreListenerActions.RemoveAllListeners());

  return;
};

const unsubscribeOneListener = (listener: string) => async (
  dispatch: Dispatch,
) => {
  await dispatch(FirestoreListenerActions.RemoveListener(listener));
};

export const FirestoreListenerThunks = {
  unsubscribeListeners,
  unsubscribeOneListener,
};
