/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form';

import { AuthState, AuthThunks } from 'modules/authentication';
import { ApplicationState, AppDispatch } from 'modules/redux-store';
import {
  FormHeader,
  FormError,
  ValidatedInput,
  FormSubmit,
  PublicLayout,
  FormCheckbox,
} from 'components';
import { EmailRegExp } from 'consts';
import { auth } from 'styles';

export const Login: React.FC = () => {
  const methods = useForm<AuthState>({
    mode: 'onBlur',
    validateCriteriaMode: 'all',
  });
  const { authStateChanging, error } = useSelector(
    (state: ApplicationState) => state.auth,
  );
  const dispatch = useDispatch<AppDispatch>();

  return (
    <PublicLayout>
      <div css={auth.flexWrapper}>
        <div css={auth.wrapper}>
          <div css={auth.header}>
            <FormHeader title="Prijava" />
          </div>

          <main css={auth.content}>
            <FormError error={error} />
            <FormContext {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <ValidatedInput
                  label="E-mail adresa"
                  name="email"
                  validationObject={{ required: true, pattern: EmailRegExp }}
                  errMsg="Invalid format of email"
                />

                <ValidatedInput
                  label="Zaporka"
                  name="password"
                  type="password"
                  validationObject={{ required: true }}
                />

                <FormCheckbox name="rememberMe" style={auth.checkbox}>
                  <span>Zapamti me?</span>
                </FormCheckbox>

                <div css={auth.action}>
                  <FormSubmit
                    value="Prijavi se"
                    stateIsChanging={authStateChanging}
                  />
                </div>
              </form>
            </FormContext>
          </main>
        </div>
      </div>
    </PublicLayout>
  );

  function onSubmit(data: AuthState) {
    const { email, password, rememberMe } = data;

    dispatch(AuthThunks.loginAsync(email, password, rememberMe));
  }
};
