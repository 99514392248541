/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useRef, Fragment, useCallback } from 'react';
import ReactDOM from 'react-dom';

import { modal, buttons, spacers } from 'styles';

interface Props {
  type?: 'delete' | 'info';
  isShowing: boolean;
  hide: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm: (id?: string) => void;
  entityId?: string;
  entityTitle?: string;
  customMessage?: string;
}

export const Modal: React.FC<Props> = ({
  type = 'delete',
  isShowing,
  hide,
  onConfirm,
  entityId,
  entityTitle,
  customMessage,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleConfirm = useCallback(() => {
    if (entityId) {
      onConfirm(entityId);
    } else {
      onConfirm();
    }

    hide();
  }, [entityId, hide, onConfirm]);

  const onBlur = useCallback(
    (event: MouseEvent) => {
      if (event.target === modalRef.current) {
        hide();
      }
    },
    [hide],
  );

  const onEscOrEnter = useCallback(
    (event: KeyboardEvent) => {
      if (isShowing) {
        switch (event.keyCode) {
          case 27:
            if (isShowing) {
              hide();
            }
            break;
          case 13:
            if (isShowing && onConfirm) {
              handleConfirm();
              hide();
            }
            break;
          default:
            break;
        }
      }
    },
    [hide, isShowing, handleConfirm, onConfirm],
  );

  useEffect(() => {
    window.addEventListener('keydown', onEscOrEnter, false);
    window.addEventListener('mousedown', onBlur, false);

    return () => {
      window.removeEventListener('keydown', onEscOrEnter, false);
      window.removeEventListener('mousedown', onBlur, false);
    };
  }, [onBlur, onEscOrEnter]);

  if (!isShowing) return null;

  return ReactDOM.createPortal(
    <Fragment>
      <div css={modal.overlay} />
      <div
        css={modal.wrapper}
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
        ref={modalRef}
      >
        <div css={modal.container}>
          <div css={modal.header}>
            <button
              type="button"
              css={modal.close}
              data-dismiss="modal"
              aria-label="Close"
              onClick={hide}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div css={modal.content}>
            {customMessage ? (
              <p>{customMessage}</p>
            ) : (
              <p>
                Jeste li sigurni da želite obrisati <em>{entityTitle}</em>?
              </p>
            )}
          </div>

          <div css={modal.footer}>
            <button
              type="button"
              css={[buttons.danger.medium, spacers.right.large]}
              onClick={handleConfirm}
            >
              {type === 'delete' ? 'Obriši' : 'Zatvori'}
            </button>

            <button type="button" css={buttons.container} onClick={hide}>
              Natrag
            </button>
          </div>
        </div>
      </div>
    </Fragment>,
    document.body,
  );
};
