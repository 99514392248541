import { Announcement } from 'models';
import { PublishedFilter } from 'consts';

import { AnnouncementsActions } from './actions';
import { AnnouncementsTypes } from './types';

export interface AnnouncementsState {
  announcementsAreChanging: boolean;
  error?: string;
  announcements: Announcement[];
  publishFilter: PublishedFilter;
}

const INITIAL_STATE: AnnouncementsState = {
  announcementsAreChanging: true,
  error: undefined,
  announcements: [],
  publishFilter: 'All',
};

export default (
  state: AnnouncementsState = INITIAL_STATE,
  action: AnnouncementsActions,
) => {
  switch (action.type) {
    case AnnouncementsTypes.AddRequest:
      return {
        ...state,
        announcementsAreChanging: true,
        error: undefined,
      };

    case AnnouncementsTypes.AddAnnouncements:
      return {
        ...state,
        announcementsAreChanging: false,
        error: undefined,
        announcements: action.payload.announcements,
      };

    case AnnouncementsTypes.AddError:
      return {
        ...state,
        announcementsAreChanging: false,
        error: action.payload.error,
      };

    case AnnouncementsTypes.AddPublishFilter:
      return {
        ...state,
        publishFilter: action.payload.filter,
      };

    default:
      return state || INITIAL_STATE;
  }
};
