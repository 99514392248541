enum types {
  AddRequest = 'donations/ADD_REQUEST',
  AddDonations = 'donations/ADD_DONATIONS',
  AddFilters = 'donations/ADD_FILTERS',
  AddPublishedFilter = 'donations/ADD_PUBLISH_FILTER',
  AddError = 'donations/ADD_ERROR',
}

export const DonationsTypes = {
  ...types,
};
