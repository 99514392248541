/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { getYear } from 'date-fns/esm';
import { NavLink } from 'react-router-dom';

import { nav, wrappers, spacers } from 'styles';

import { RoutePath } from '../consts';

export const Footer: React.FC = () => {
  return (
    <section css={nav.footerBg}>
      <div css={[wrappers.normal, nav.footerWrapper]}>
        <img
          src={require('assets/images/PRIJATELJI_SV_MARTINA_2.png')}
          alt="Prijatelji svetog Martina"
          css={nav.logoBlend}
        />

        <div css={[nav.footerCol, nav.footerCompany]}>
          <p>Socijalna burza</p>
          <p css={[spacers.bottom.medium]}>prijatelji.sv.martina@gmail.com</p>

          <p>T: +385 97 698 2901</p>
          <p>IBAN: HR67 2500 0091 1014 18431</p>

          <p css={nav.footerYear}>
            &copy; {getYear(Date.now())} Sva prava pridržana
          </p>
        </div>

        <div css={nav.footerCol}>
          <NavLink css={nav.footerLink} to={RoutePath.Supply}>
            Ponuda donacija
          </NavLink>
          <NavLink css={nav.footerLink} to={RoutePath.Demand}>
            Potražnja donacija
          </NavLink>
          <NavLink css={nav.footerLink} to={RoutePath.News}>
            Novosti
          </NavLink>
          <NavLink css={nav.footerLink} to={RoutePath.Contact}>
            Kontakt
          </NavLink>
          <a
            href="https://www.facebook.com/prijatelji.sv.martina"
            target="_blank"
            rel="noopener noreferrer"
            css={nav.footerFbDesktop}
          >
            POVEŽIMO SE
            <img
              src={require('assets/images/icon_facebook.svg')}
              alt="Povežimo se"
            />
          </a>
        </div>

        <div css={nav.footerCol}>
          <NavLink css={nav.footerLink} to={RoutePath.Announcements}>
            Najave
          </NavLink>
          <NavLink css={nav.footerLink} to={RoutePath.About}>
            O projektu
          </NavLink>
          <NavLink css={nav.footerLink} to={RoutePath.PrivacyPolicy}>
            Politika privatnosti
          </NavLink>
          <a
            href="https://www.facebook.com/prijatelji.sv.martina"
            target="_blank"
            rel="noopener noreferrer"
            css={nav.footerFbMobile}
          >
            POVEŽIMO SE
            <img
              src={require('assets/images/icon_facebook.svg')}
              alt="Povežimo se"
            />
          </a>
        </div>

        <div css={nav.footerCol}>
          <img
            src={require('assets/images/fondovi-footer.png')}
            alt="Europski fondovi"
            css={nav.fund}
          />
          <p css={nav.footerFundText}>
            <a href="https://www.esf.hr">www.esf.hr</a> Projekt je sufinancirala
            Europska unija iz Europskog socijalnog fonda.
          </p>
        </div>
      </div>
    </section>
  );
};
