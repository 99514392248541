/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { usePagination } from 'hooks';
import { Announcement } from 'models';
import { AnnouncementsThunks } from 'modules/announcements';
import {
  Loading,
  FormError,
  EmptyListState,
  HeroItem,
  Pagination,
  ExtendedItemList,
  Helmet,
} from 'components';
import { wrappers, content, newsStyles } from 'styles';
import { RoutePath } from 'modules/navigation';

export const Announcements: React.FC = () => {
  const { announcements, announcementsAreChanging, error } = useSelector(
    (state: ApplicationState) => state.announcements,
  );
  const dispatch = useDispatch<AppDispatch>();
  const {
    currentPage,
    maxPages,
    next,
    previous,
    selectPage,
    selectedData,
  } = usePagination<Announcement>(announcements, 9);

  useEffect(() => {
    dispatch(AnnouncementsThunks.getPublishedAnnouncements());
  }, [dispatch]);

  return (
    <section>
      <Helmet
        title="Socijalna burza - Najave"
        description="Pročitajte najave i saznajte sve o predstojećim aktivnostima te kako podržati iste."
      />

      <Loading isLoading={announcementsAreChanging}>
        <FormError error={error} />

        <EmptyListState title="Trenutno nema najava" data={selectedData()} />

        <HeroItem item={selectedData()[0]} path={RoutePath.Announcements} />

        <div css={[wrappers.normal, content.padding]}>
          <ExtendedItemList
            items={selectedData().slice(1)}
            path={RoutePath.Announcements}
            listCss={newsStyles.wrapper}
            itemCss={newsStyles.item}
            dateCss={newsStyles.itemDate}
            titleCss={newsStyles.itemTitle}
            descriptionCss={newsStyles.itemDescription}
          />

          <Pagination
            currentPage={currentPage}
            maxPages={maxPages}
            previous={previous}
            next={next}
            selectPage={selectPage}
          />
        </div>
      </Loading>
    </section>
  );
};
