/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { ErrorMessage } from 'react-hook-form';
import { MdDelete } from 'react-icons/md';

import { NewsFile } from 'models';
import { loading, buttons, dropzone } from 'styles';
import { fileUpload } from 'styles/fileUpload.styles';
import { useFileUpload } from 'hooks/useFileUpload';

interface Props {
  name: string;
  formIsLoading: boolean;
  firebaseFiles?: NewsFile[];
  uplaodFilesToStorageAndGetUrls: (
    id: string,
    files: File[],
  ) => Promise<string[] | undefined>;
  removeFilesFromStorage: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export const FileUpload: React.FC<Props> = ({
  name,
  formIsLoading,
  firebaseFiles,
  uplaodFilesToStorageAndGetUrls,
  removeFilesFromStorage,
}) => {
  const file = useFileUpload(
    name,
    uplaodFilesToStorageAndGetUrls,
    firebaseFiles,
  );

  return (
    <div css={fileUpload.wrapper}>
      <p css={fileUpload.title}>Datoteke</p>
      <div {...file.getRootProps()}>
        <input disabled={formIsLoading} {...file.getInputProps()} />

        <div css={fileUpload.placeholder}>
          {file.isDragActive ? (
            <p>Povucite ovdje ...</p>
          ) : (
            <Fragment>
              <div css={fileUpload.placeholderParagraph}>
                Povucite ovdje datoteke
              </div>
              <span css={fileUpload.placeholderSpan}>ili</span>
              <button type="button" css={buttons.primary.small}>
                Odaberite datoteku
              </button>
            </Fragment>
          )}

          {formIsLoading && (
            <div css={dropzone.imgLoadingOverlay}>
              <span css={loading.spinner} />
            </div>
          )}
        </div>
      </div>
      <ul css={fileUpload.list}>
        {file.fileUrls?.map(({ name, url }, index) => (
          <li key={index} css={fileUpload.listItem}>
            <a target="blank" rel="noopener noreferrer" href={url}>
              {name}
            </a>
            <button
              css={fileUpload.button}
              type="button"
              onClick={removeFilesFromStorage}
              data-index={index.toString()}
              data-name="files"
              data-url={url}
            >
              <MdDelete size={12} />
            </button>
          </li>
        ))}
      </ul>

      <ErrorMessage
        errors={file.errors || {}}
        name={name}
        message="Došlo je do pogreške"
        as={<p className="input--error" />}
      />
    </div>
  );
};
