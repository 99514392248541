import { createAction, ActionUnion } from 'modules/redux-store';
import { Announcement } from 'models';
import { PublishedFilter } from 'consts';

import { AnnouncementsTypes } from './types';

const AddActions = {
  addRequest: () => createAction(AnnouncementsTypes.AddRequest),
  addAnnouncement: (announcements: Announcement[]) =>
    createAction(AnnouncementsTypes.AddAnnouncements, { announcements }),
  addError: (error: string) =>
    createAction(AnnouncementsTypes.AddError, { error }),
  addPublishFilter: (filter: PublishedFilter) =>
    createAction(AnnouncementsTypes.AddPublishFilter, { filter }),
};

export const AnnouncementsActions = {
  ...AddActions,
};

export type AnnouncementsActions = ActionUnion<typeof AnnouncementsActions>;
