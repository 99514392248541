import { useEffect, useState, useCallback } from 'react';

export const usePageModified = (
  ref?: HTMLFormElement | null,
): [boolean, (state: boolean) => void] => {
  const [pageModified, setModified] = useState(false);

  const setPageModified = useCallback((state: boolean) => {
    setModified(state);
  }, []);

  const handleModified = useCallback(() => setModified(true), []);

  useEffect(() => {
    if (!ref) {
      return;
    }

    ref.addEventListener('keydown', handleModified, false);

    return () => {
      ref.removeEventListener('keydown', handleModified, false);
    };
  });

  return [pageModified, setPageModified];
};
