/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { fields } from 'styles';

interface Props {
  name: string;
  label: string;
  text?: string;
}

export const TextField: React.FC<Props> = ({ name, label, text }) => (
  <div css={fields.field}>
    <label htmlFor={name} css={fields.label}>
      {label}
    </label>
    <p id={name}>{text}</p>
  </div>
);
