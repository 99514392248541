/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState, AppDispatch } from 'modules/redux-store';
import {
  ReservationsSelectors,
  ReservationsThunks,
} from 'modules/reservations';
import { usePagination } from 'hooks';
import { Reservation } from 'models';
import { content } from 'styles';
import {
  PageHeader,
  Loading,
  FormError,
  EmptyListState,
  DashboardList,
  Pagination,
} from 'components';
import { RoutePath } from 'modules/navigation';
import { SearchActions } from 'modules/search';

export const DashboardReservations: React.FC = () => {
  const { reservations, reservationsAreChanging, error } = useSelector(
    (state: ApplicationState) => state.reservations,
  );
  const filteredReservations = useSelector((state: ApplicationState) =>
    ReservationsSelectors.getReservationsByQuery(state),
  );
  const dispatch = useDispatch<AppDispatch>();
  const {
    currentPage,
    maxPages,
    next,
    previous,
    selectPage,
    selectedData,
  } = usePagination<Reservation>(filteredReservations, 16);

  useEffect(() => {
    dispatch(ReservationsThunks.getAllReservations());
  }, [dispatch]);

  return (
    <section css={content.area}>
      <PageHeader
        onSearch={handleSearch}
        title="Rezervacije donacija i volontera"
      />

      <Loading isLoading={reservationsAreChanging}>
        <FormError error={error} />
        <EmptyListState
          data={reservations}
          title="Trenutno nema donacija i volontera u potražnji"
        />
        {reservations.length > 0 && (
          <EmptyListState
            data={selectedData()}
            title="Nema rezultata za navedene filtre"
          />
        )}

        <DashboardList
          data={selectedData()}
          path={RoutePath.DashboardReservations}
          handleDelete={handleDelete}
        />

        <Pagination
          currentPage={currentPage}
          maxPages={maxPages}
          previous={previous}
          next={next}
          selectPage={selectPage}
        />
      </Loading>
    </section>
  );

  function handleSearch(query: string) {
    dispatch(SearchActions.querySearch(query));
  }

  function handleDelete(id?: string) {
    if (!id) return;

    dispatch(ReservationsThunks.deleteReservation(id));
  }
};
