/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { home, wrappers, buttons } from 'styles';
import { RoutePath } from 'modules/navigation';
import { ReactComponent as HeroImg } from 'assets/images/illustration_učini_dobro_djelo_humaaans.svg';

export const HomeHero: React.FC = () => (
  <div css={home.heroBg}>
    <div css={[wrappers.normal, home.heroWrapper]}>
      <div css={home.heroImg}>
        <HeroImg width="100%" height="100%" />
      </div>

      <div css={home.heroText}>
        <h2>Učini dobro djelo</h2>
        <p>
          Kada siromasima pomažemo ne iskazujemo im time neku osobnu
          darežljivost, nego im vraćamo što je njihovo. Više nego čin ljubavi,
          vršimo dužnost pravednosti.
        </p>

        <Link to={RoutePath.Donate} css={buttons.primary.large}>
          Doniraj / volontiraj
        </Link>
      </div>
    </div>
  </div>
);
