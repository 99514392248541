enum types {
  AddRequest = 'announcements/ADD_REQUEST',
  AddAnnouncements = 'announcements/ADD_ANNOUNCEMENTS',
  AddPublishFilter = 'announcements/ADD_PUBLISH_FILTER',
  AddError = 'announcements/ADD_ERROR',
}

export const AnnouncementsTypes = {
  ...types,
};
