import { useState, useCallback, useEffect } from 'react';

export const useModal = (defaultTitle?: string) => {
  const [isShowing, setIsShowing] = useState(false);
  const [entityId, setEntityId] = useState('');
  const [entityTitle, setEntityTitle] = useState(defaultTitle || '');

  useEffect(() => {
    if (defaultTitle) setEntityTitle(defaultTitle);
  }, [defaultTitle]);

  const toggle = useCallback((event?: React.MouseEvent<HTMLButtonElement>) => {
    setIsShowing(prevState => !prevState);

    if (event) {
      const { id, title } = event.currentTarget.dataset;
      if (!id || !title) return;

      setEntityId(id);
      setEntityTitle(title);
    }
  }, []);

  return {
    isShowing,
    toggle,
    entityId,
    entityTitle,
  };
};
