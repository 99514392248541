/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useRef } from 'react';
import { FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Dropzone,
  FormSubmit,
  Loading,
  Modal,
  PageGuard,
  PageHeader,
  ValidatedInput,
  PublishSwitch,
} from 'components';
import { useFormLogic, useModal } from 'hooks';
import { News, PageHeaderAction } from 'models';
import { RoutePath } from 'modules/navigation';
import { NewsThunks } from 'modules/news';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { content, form, wrappers } from 'styles';
import { withId } from 'utils';
import { FileUpload } from 'components/FileUpload';

export const DashboardNewsDetails: React.FC = () => {
  const { id } = useParams();
  const { news, newsAreChanging } = useSelector(
    (state: ApplicationState) => state.news,
  );
  const selectedNews = news.find(withId(id));
  const dispatch = useDispatch<AppDispatch>();
  const formRef = useRef<HTMLFormElement>(null);
  const {
    methods,
    uploadNewsFiles,
    removeFilesFromNews,
    handleDropzoneThumbnailClick,
    formIsLoading,
    deleteNews,
    saveNews,
    pageModified,
    handlePublishSwitch,
  } = useFormLogic<News>(RoutePath.DashboardNews, formRef.current);
  const { register, setValue, handleSubmit } = methods;
  const { isShowing, toggle, entityId, entityTitle } = useModal(
    selectedNews?.title,
  );

  useEffect(() => {
    dispatch(NewsThunks.getAllNews());
  }, [dispatch]);

  useEffect(() => {
    register('id');
    register('dateOfPublishing');
    register('createdAt');
    setValue('id', selectedNews?.id || id);
    setValue('dateOfPublishing', selectedNews?.dateOfPublishing);
    setValue('createdAt', selectedNews?.createdAt);
  }, [register, setValue, selectedNews, id]);

  return (
    <section css={[wrappers.normal, content.area]}>
      <PageHeader
        title="Detalji novosti"
        backAction={new PageHeaderAction('Natrag', RoutePath.DashboardNews)}
        actions={[new PageHeaderAction('Obriši novost', toggle)]}
      />
      <Loading isLoading={newsAreChanging}>
        <FormContext {...methods}>
          <form
            ref={formRef}
            css={form.flexFields}
            onSubmit={handleSubmit(saveNews)}
          >
            <Dropzone
              name="photoUrls"
              firebasePhotoUrls={selectedNews?.photoUrls}
              formIsLoading={formIsLoading}
              uplaodImagesToStorageAndGetUrls={uploadNewsFiles}
              removeImagesFromStorage={removeFilesFromNews}
              handleDropzoneThumbnailClick={handleDropzoneThumbnailClick}
              heroImageIndex={selectedNews?.heroImageIndex}
            />

            <div css={form.wrapper}>
              <ValidatedInput
                label="Naslov"
                placeholder="Unesite naslov"
                name="title"
                errMsg="Naslov je predugačak (max. 60 znakova)"
                validationObject={{ required: true, maxLength: 60 }}
                defaultValue={selectedNews?.title}
              />

              <ValidatedInput
                textarea
                label="Opis"
                placeholder="Unesite opis"
                name="description"
                errMsg="Opis je predugačak (max. 2000 znakova)"
                validationObject={{ required: true, maxLength: 2000 }}
                defaultValue={selectedNews?.description}
              />

              <FileUpload
                name="files"
                firebaseFiles={selectedNews?.files}
                formIsLoading={formIsLoading}
                uplaodFilesToStorageAndGetUrls={uploadNewsFiles}
                removeFilesFromStorage={removeFilesFromNews}
              />

              <PublishSwitch
                name="published"
                checked={selectedNews?.published}
                handlePublishSwitch={handlePublishSwitch}
              />

              <FormSubmit value="Spremi" stateIsChanging={formIsLoading} />
            </div>
          </form>
        </FormContext>
      </Loading>

      <Modal
        isShowing={isShowing}
        hide={toggle}
        onConfirm={deleteNews}
        entityId={entityId}
        entityTitle={entityTitle}
      />

      <PageGuard when={pageModified} />
    </section>
  );
};
