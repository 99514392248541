/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useRef } from 'react';
import { FormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useFormLogic, useModal } from 'hooks';
import { Donation, PageHeaderAction } from 'models';
import {
  ValidatedInput,
  PageHeader,
  ValidatedSelect,
  FormCheckbox,
  FormSubmit,
  Loading,
  Dropzone,
  PageGuard,
  PublishSwitch,
} from 'components';
import { wrappers, content, form } from 'styles';
import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { withId } from 'utils';
import { RoutePath } from 'modules/navigation';
import { EmailRegExp, CategoryOptions, PhoneRegExp } from 'consts';
import { DonationsThunks } from 'modules/donations';
import { Modal } from 'components/Modal';

export const DashboardSupplyDetails: React.FC = () => {
  const { id } = useParams();
  const { donations, donationsAreChanging } = useSelector(
    (state: ApplicationState) => state.donations,
  );
  const selectedDonation = donations.find(withId(id));
  const dispatch = useDispatch<AppDispatch>();
  const formRef = useRef<HTMLFormElement>(null);
  const {
    methods,
    uploadDonatinImages,
    removeImagesFromDonations,
    handleDropzoneThumbnailClick,
    saveDonation,
    formIsLoading,
    deleteDonation,
    pageModified,
    handlePublishSwitch,
  } = useFormLogic<Donation>(RoutePath.DashboardSupply, formRef.current);
  const { isShowing, toggle, entityId, entityTitle } = useModal(
    selectedDonation?.name,
  );

  useEffect(() => {
    dispatch(DonationsThunks.getAllDonations());
  }, [dispatch]);

  useEffect(() => {
    methods.register('id');
    methods.register('createdAt');
    methods.register('dateOfPublishing');

    methods.setValue('id', selectedDonation?.id || id);
    methods.setValue('createdAt', selectedDonation?.createdAt);
    methods.setValue('dateOfPublishing', selectedDonation?.dateOfPublishing);
  }, [methods, selectedDonation, id]);

  return (
    <section css={[wrappers.normal, content.area]}>
      <PageHeader
        title="Detalji donacije"
        backAction={new PageHeaderAction('Natrag', RoutePath.DashboardSupply)}
        actions={[new PageHeaderAction('Obriši donaciju', toggle)]}
      />
      <Loading isLoading={donationsAreChanging}>
        <FormContext {...methods}>
          <form
            ref={formRef}
            css={form.flexFields}
            onSubmit={methods.handleSubmit(saveDonation)}
          >
            <Dropzone
              name="photoUrls"
              firebasePhotoUrls={selectedDonation?.photoUrls}
              formIsLoading={formIsLoading}
              heroImageIndex={selectedDonation?.heroImageIndex}
              uplaodImagesToStorageAndGetUrls={uploadDonatinImages}
              removeImagesFromStorage={removeImagesFromDonations}
              handleDropzoneThumbnailClick={handleDropzoneThumbnailClick}
            />

            <div css={form.wrapper}>
              <div css={form.flexFields}>
                <ValidatedInput
                  label="Ime"
                  placeholder="Vaše ime"
                  name="firstName"
                  validationObject={{ required: true }}
                  defaultValue={selectedDonation?.firstName}
                />

                <ValidatedInput
                  label="Prezime"
                  placeholder="Vaše prezime"
                  name="lastName"
                  validationObject={{ required: true }}
                  defaultValue={selectedDonation?.lastName}
                />
              </div>

              <div css={form.flexFields}>
                <ValidatedInput
                  label="E-mail"
                  placeholder="Vaša e-mail adresa"
                  errMsg="Krivi format e-mail adrese"
                  name="email"
                  validationObject={{ pattern: EmailRegExp }}
                  defaultValue={selectedDonation?.email}
                />

                <ValidatedInput
                  label="Broj telefona"
                  placeholder="Vaš broj telefona"
                  name="phone"
                  errMsg="Krivi format telefonskog broja"
                  validationObject={{ required: true, pattern: PhoneRegExp }}
                  defaultValue={selectedDonation?.phone}
                />
              </div>

              <ValidatedInput
                label="Naziv donacije"
                placeholder="Unesite naziv donacije koju želite donirati"
                name="name"
                errMsg="Naziv donacije je predugačak (max. 60 znakova)"
                validationObject={{ required: true, maxLength: 60 }}
                defaultValue={selectedDonation?.name}
              />

              <ValidatedSelect
                name="category"
                label="Kategorija"
                placeholder="Izaberite kategoriju"
                options={CategoryOptions}
                defaultValue={CategoryOptions.find(
                  option => option.value === selectedDonation?.category,
                )}
                required
              />

              <ValidatedInput
                textarea
                label="Opis donacije"
                placeholder="Unesite opis donacije"
                name="description"
                errMsg="Opis donacije je predugačak (max. 2000 znakova)"
                validationObject={{ required: true, maxLength: 2000 }}
                defaultValue={selectedDonation?.description}
              />

              {selectedDonation ? (
                <FormCheckbox
                  name="policyAccepted"
                  disabled
                  checked={selectedDonation?.policyAccepted}
                >
                  <span>Prihvaćena politika privatnosti</span>
                </FormCheckbox>
              ) : (
                <FormCheckbox name="policyAccepted" />
              )}

              <PublishSwitch
                name="published"
                checked={selectedDonation?.published}
                handlePublishSwitch={handlePublishSwitch}
              />

              <FormSubmit value="Spremi" stateIsChanging={formIsLoading} />
            </div>
          </form>
        </FormContext>
      </Loading>

      <Modal
        isShowing={isShowing}
        hide={toggle}
        onConfirm={deleteDonation}
        entityId={entityId}
        entityTitle={entityTitle}
      />

      <PageGuard when={pageModified} />
    </section>
  );
};
