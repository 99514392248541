import 'firebase/firestore';
import firebase from 'firebase/app';

import { Collection } from 'consts';
import { stripMetaProperties } from 'utils';

import { FirebaseService } from './FirebaseService';

import { FirestoreOrderingParam, FirestoreQueryParam } from '../models';

/**
 * Service function that manages documents within a collection.
 * Depends on document model/interface and collection.
 * @example
 * const profileService = FirestoreService<Profile>(Collection.Profiles);
 */
export function FirestoreService<T extends any>(
  collection: Collection | firebase.firestore.CollectionReference,
  document?: firebase.firestore.DocumentReference,
) {
  const firestore = FirebaseService.Instance().firestore();

  /** Document reference object */
  const documentRef = document;

  /** Collection reference object */
  const collectionRef =
    typeof collection === 'string'
      ? firestore.collection(collection)
      : collection;

  /** Type guard for FirestoreOrderingParam */
  function isFirestoreOrderingParam(
    value: FirestoreQueryParam | FirestoreOrderingParam,
  ): value is FirestoreOrderingParam {
    return (value as FirestoreOrderingParam).orderBy !== undefined;
  }

  /**
   * This method instantiates new Firestore service with defined documentRef.
   * This method is used as a helper method in conjunction with withSubcollection method.
   * Methods of Firebase service will be called on previously selected collection,
   * until new one is selected.
   *
   * @param docId unique identifier of the document
   */
  // function getDocRef(docId: string) {
  //   return FirestoreService(collectionRef, collectionRef.doc(docId));
  // }

  /**
   * Function for instantiating new FirestoreService for some subcollection.
   * This method requires documentRef to be set with document reference.
   * It will return Firestore service for another collection.
   *
   * @example
   * const eventsService = orgService.getDocRef('AOIwX2OhZDQSsr7GBBeb').withSubcollection<Event>(
   * Subcollection.Event
   * )
   */
  // function withSubcollection<S extends any>(subcollectionId: Subcollection) {
  //   if (!documentRef) {
  //     return FirestoreService<S>(collectionRef);
  //   }

  //   const newCollectionRef = documentRef.collection(subcollectionId);
  //   return FirestoreService<S>(newCollectionRef);
  // }

  /** Add a new document to the collection.
   * Result will return either an object with the saved properties or an error message.
   *
   * @example
   * service.add(item).then(result => {...})
   */
  async function add(data: T): Promise<T | string> {
    data = stripMetaProperties(data);
    return collectionRef
      .add({ ...data })
      .then(doc => ({
        ...data,
        id: doc.id,
      }))
      .catch(error => error.message);
  }
  /** Add multiple documents to the collection. Will return an array of results coupled with their document ids.
   * Note: returned results may include false positive results - ie document was not created on Firestore.
   *
   * @example
   * service.addMultiple(items);
   */
  function addMultiple(data: T[]): T[] {
    const result: T[] = [];
    data.forEach(item => {
      const id = collectionRef.doc().id;
      collectionRef.doc(id).set({ ...item }, { merge: true });
      result.push({ ...item, id });
    });
    return result;
  }
  /** Get firestore document by id.
   * Will return the document data object or an error string
   *
   * @example
   * service.find(id).then(result => {...})
   */
  async function find(id: string): Promise<T | null> {
    return collectionRef
      .doc(id)
      .get()
      .then(snap =>
        snap.exists ? { ...(snap.data() as T), id: snap.id } : null,
      )
      .catch(error => {
        console.log('error', error);
        return null;
      });
  }
  /**
   * Open up a listener for firestore document.
   * Will return a listener function that should be stored as a reference in order to be able to unsubscribe from it if necessary.
   *
   * It returns document if it exists or the id of the deleted document
   * @example
   * const listener = service.findAndListen(id, doc => {...}, error => {...});
   */
  function findAndListen(
    id: string,
    onSuccess: (data: T | string) => void,
    onError: (error: string) => void,
  ): () => void {
    return collectionRef.doc(id).onSnapshot(
      snap =>
        snap.exists
          ? onSuccess(({ ...snap.data(), id: snap.id } as unknown) as T)
          : onSuccess(snap.id),
      error => {
        onError(error.message);
      },
    );
  }

  async function findAll(): Promise<T[] | null> {
    return collectionRef
      .get()
      .then(snap =>
        snap.docs.map(doc => ({ ...(doc.data() as T), id: doc.id })),
      )
      .catch(error => {
        console.log('error: ', error);
        return null;
      });
  }

  async function findFirst(): Promise<T | null> {
    return collectionRef
      .limit(1)
      .get()
      .then(
        snap => snap.docs.map(doc => ({ ...(doc.data() as T), id: doc.id }))[0],
      )
      .catch(error => {
        console.log('error', error);
        return null;
      });
  }

  function findFirstAndListen(
    onSuccess: (data?: T) => void,
    onError: (error: string) => void,
  ): () => void {
    return collectionRef.limit(1).onSnapshot(
      snap =>
        snap.docs.length
          ? onSuccess(({
              ...snap.docs[0].data(),
              id: snap.docs[0].id,
            } as unknown) as T)
          : undefined,
      error => {
        onError(error.message);
      },
    );
  }

  /** Filter collection items by passed parameters.
   * To get entire collection, pass no query parameters.
   * If specific ordering is required, pass the orderBy prop into the filter objects, but be careful with them, they can break the query.
   * Returns array of objects or error message.
   *
   * @example
   * service.filter(undefined, new FirestoreQueryParam("category", "==", "Fish"), new FirestoreOrderingParam("name")).then(result => {...})
   */
  async function filter(
    ...params: (FirestoreQueryParam | FirestoreOrderingParam)[]
  ): Promise<T[] | string> {
    let query: firebase.firestore.Query = collectionRef;

    params.forEach(param => {
      if (!isFirestoreOrderingParam(param)) {
        query = query.where(param.field, param.operator, param.value);
      }
    });
    params.forEach(param => {
      if (isFirestoreOrderingParam(param)) {
        query = query.orderBy(param.orderBy, param.direction || 'asc');
      }
    });

    return query
      .get()
      .then(snap => snap.docs.map(doc => ({ ...doc.data(), id: doc.id })))
      .catch(error => error.message);
  }

  /** Filter collection items by passed parameters and listen.
   * Will return a listener function that should be stored as a reference in order to be able to unsubscribe from it if necessary.
   *
   * @example
   * const listener = service.filterAndListen(data => {...}, error => {...}, undefined, new FirestoreQueryParam("category", "==", "Fish"), new FirestoreOrderingParam("name"));
   */
  function filterAndListen(
    onSuccess: (data: T[]) => void,
    onError: (error: string) => void,
    ...params: (FirestoreQueryParam | FirestoreOrderingParam)[]
  ): () => void {
    let query: firebase.firestore.Query = collectionRef;

    params.forEach(param => {
      if (!isFirestoreOrderingParam(param)) {
        query = query.where(param.field, param.operator, param.value);
      }
    });
    params.forEach(param => {
      if (isFirestoreOrderingParam(param)) {
        query = query.orderBy(param.orderBy, param.direction || 'asc');
      }
    });

    return query.onSnapshot(
      snap =>
        onSuccess(
          snap.docs.map(
            change =>
              (({
                ...change.data(),
                id: change.id,
              } as unknown) as T),
          ),
        ),
      error => {
        onError(error.message);
      },
    );
  }

  /** Update document with new data.
   * If data object does not contain the id, pass it explicitly as the second parameter.
   * If response is undefined, update succeeded; if response exists the update failed.
   *
   * @example
   * service.update(item, id).then((error: string | undefined) => {...})
   */
  async function update(
    data: Partial<T>,
    id?: string,
  ): Promise<undefined | string> {
    const refId = data.id || data.uid || id;
    data = stripMetaProperties(data);
    // Can't update object because there is no reference to get the document by.
    if (!refId) {
      const error =
        'Reference id is missing. If the data property does not contain an id, pass it explicitly as the second parameter.';
      return error;
    }
    return collectionRef
      .doc(refId)
      .set({ ...data }, { merge: true })
      .then(() => undefined)
      .catch(error => error.message);
  }
  /** Update multiple items at once in a batch. Only items with id props set will be updated, rest will be left as-is.
   * Returns undefined if successful, string error message if failed.
   *
   * @example
   * service.updateMultiple(items).then((error: string | undefined) => {...})
   */
  async function updateMultiple(data: T[]) {
    const batch = firestore.batch();
    data
      .filter(item => item.id)
      .forEach(item => {
        const id = item.id;
        const strippedItem = stripMetaProperties(item);
        batch.update(collectionRef.doc(id), { ...strippedItem });
      });
    return batch
      .commit()
      .then(() => undefined)
      .catch(error => {
        console.log('updateMultiple -> error', error);
        return 'Update operation failed';
      });
  }
  /** Delete document.
   * If response is undefined, delete succeeded; if response exists deletion failed.
   *
   * @example
   * service.remove(id).then((error: string | undefined) => {...})
   */
  async function remove(id: string): Promise<undefined | string> {
    return collectionRef
      .doc(id)
      .delete()
      .then(() => undefined)
      .catch(error => error.message);
  }

  /** Delete multiple documents.
   * Returns undefined if successful, error string if operation failed.
   *
   * @example
   * service.removeMultiple(ids).then((error: string | undefined) => {...})
   */
  async function removeMultiple(ids: string[]): Promise<undefined | string> {
    const batch = firestore.batch();
    ids.forEach(id => {
      batch.delete(collectionRef.doc(id));
    });
    return batch
      .commit()
      .then(() => undefined)
      .catch(error => {
        console.log('error', error);
        return 'Delete operation failed';
      });
  }

  /**
   * Thread safe increment/decrement of number values
   * @param id of the document to increment some value
   * @param field name of the field to increment
   * @param amount how much to increment/decrement, for decrement purposes use negative number (e.g. -1)
   */
  async function incrementFieldValue(
    id: string,
    field: string,
    amount: number,
  ): Promise<string | undefined> {
    const increment = firebase.firestore.FieldValue.increment(amount);
    return collectionRef
      .doc(id)
      .update({ [field]: increment })
      .then(() => undefined)
      .catch(error => error.message);
  }

  /** Set published prop to true in document reference. To be used when publishing documents.
   * If response is undefined, update succeeded; if response exists the update failed.
   *
   * @example
   * service.archive(id).then((error: string | undefined) => {...})
   */
  async function publish(id: string): Promise<undefined | string> {
    return collectionRef
      .doc(id)
      .set({ published: true }, { merge: true })
      .then(() => undefined)
      .catch(error => {
        return error.message;
      });
  }
  /** Set published prop to false in document reference. To be used when unpublishing documents.
   * If response is undefined, update succeeded; if response exists the update failed.
   * @example
   * service.unarchive(id).then((error: string | undefined) => {...})
   */
  async function unpublish(id: string): Promise<undefined | string> {
    return collectionRef
      .doc(id)
      .set({ published: false }, { merge: true })
      .then(() => undefined)
      .catch(error => error.message);
  }
  /**
   * Unsubscribe all active listeners.
   */
  function unsubscribeListeners(listeners: { [listener: string]: () => void }) {
    Object.values(listeners).forEach(listener => {
      if (listener) {
        listener();
      }
    });
  }

  function generateId() {
    return collectionRef.doc().id;
  }

  return {
    documentRef,
    collectionRef,
    add,
    addMultiple,
    find,
    findAndListen,
    findAll,
    findFirst,
    findFirstAndListen,
    filter,
    filterAndListen,
    update,
    updateMultiple,
    remove,
    removeMultiple,
    incrementFieldValue,
    publish,
    unpublish,
    unsubscribeListeners,
    generateId,
  };
}
