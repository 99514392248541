export interface Profile {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  policyAccepted: boolean;
}

export class Profile {
  constructor(object?: Partial<Profile>) {
    this.firstName = object?.firstName || '';
    this.lastName = object?.lastName || '';
    this.email = object?.email || '';
    this.phone = object?.phone || '';
    this.policyAccepted = object?.policyAccepted || false;
  }
}
