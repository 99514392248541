import { css } from '@emotion/core';

import { unit, colors } from './vars.styles';
import { color } from './mixins.styles';
import { typescale } from './typescale.styles';

/**
 * Field base
 */
const field = css`
  margin-bottom: ${unit * 5}px;
`;

/**
 * Field label base
 */
const label = css`
  margin-bottom: ${unit * 2}px;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  ${typescale.tiny}
  color: ${color(colors.text, 0.9)};
`;

const checkbox = css`
  display: block;
  position: relative;
  margin-top: ${unit * 11}px;
  margin-bottom: ${unit * 13}px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  color: ${color(colors.text, 0.9)};
  opacity: 0.7;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    margin-left: ${unit * 4}px;
  }
`;

const checkboxImg = css`
  display: inline-block;
`;

const errorMsg = css`
  margin-top: ${unit}px;
  ${typescale.small}
  color: ${color(colors.warning, 0.8)};
`;

/**
 * Export all available field styles
 */
export const fields = {
  field,
  label,
  checkbox,
  checkboxImg,
  errorMsg,
};
