/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Image from 'react-shimmer';

import { AppDispatch, ApplicationState } from 'modules/redux-store';
import {
  DonationsThunks,
  DonationsSelectors,
  SupplyFilters,
} from 'modules/donations';
import { wrappers, content } from 'styles';
import {
  FormHeader,
  Loading,
  Pagination,
  EmptyListState,
  FormError,
  Helmet,
} from 'components';
import { supply } from 'styles/supply.styles';
import { RoutePath } from 'modules/navigation';
import { usePagination } from 'hooks';
import { Donation } from 'models';
import { slugify } from 'utils';

export const Supply: React.FC = () => {
  const { donations, donationsAreChanging, error } = useSelector(
    (state: ApplicationState) => state.donations,
  );
  const filteredDonations = useSelector((state: ApplicationState) =>
    DonationsSelectors.getDonationsByFilter(state),
  );
  const dispatch = useDispatch<AppDispatch>();
  const {
    currentPage,
    maxPages,
    next,
    previous,
    selectPage,
    selectedData,
  } = usePagination<Donation>(filteredDonations, 16);

  useEffect(() => {
    dispatch(DonationsThunks.getPublishedDonations());
  }, [dispatch]);

  return (
    <Fragment>
      <Helmet
        title="Socijalna burza - Ponuda donacija i volontera"
        description="Pogledajte ponudu premeta za donaciju i volontera spremnih pomoći."
      />

      <Loading isLoading={donationsAreChanging}>
        <section css={[wrappers.normal, content.padding]}>
          <FormHeader title="Ponuda donacija" />

          <SupplyFilters />

          <FormError error={error} />

          <EmptyListState
            title={
              !donations.length
                ? 'Trenutno nema donacija u ponudi'
                : 'Nema rezultata za odabrane filtere'
            }
            data={selectedData()}
          />

          <ul css={supply.wrapper}>
            {selectedData().map(donation => (
              <div key={donation.id} css={supply.item}>
                <Link
                  to={`${RoutePath.Supply}/${slugify(donation.name)}/${
                    donation.id
                  }`}
                >
                  <Image
                    src={
                      donation.photoUrls[0] ||
                      require('assets/images/image-placeholder.png')
                    }
                    fallback={<div />}
                    errorFallback={() => (
                      <img
                        src={require('assets/images/image-placeholder.png')}
                        alt="placeholder"
                      />
                    )}
                  />
                  <p>{donation.name}</p>
                  <span css={supply[donation.category.split(' ')[0]]} />
                </Link>
              </div>
            ))}
          </ul>

          <Pagination
            currentPage={currentPage}
            maxPages={maxPages}
            previous={previous}
            next={next}
            selectPage={selectPage}
          />
        </section>
      </Loading>
    </Fragment>
  );
};
