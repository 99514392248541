import firebase from 'firebase/app';

/**
 * FirebaseService needs to implement singleton pattern for creating and
 * maintaining its instance - it's created as a IIFE
 */
export const FirebaseService = (() => {
  let instance: firebase.app.App;

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDER,
    appId: process.env.REACT_APP_APPID,
  };

  function Instance() {
    if (instance) {
      return instance;
    }

    instance = firebase.initializeApp(firebaseConfig);
    if (process.env.NODE_ENV === 'development') {
      (async () => {
        await Promise.all([
          import('firebase/storage'),
          import('firebase/functions'),
        ]);

        instance.functions().useFunctionsEmulator('http://localhost:5000');
      })();
    }

    instance.firestore().enablePersistence({ synchronizeTabs: true });
    return instance;
  }

  return { Instance };
})();
