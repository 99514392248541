import * as views from 'views';

import { RoutePath } from './RoutePath';

import { AppRoute } from '../models';

export const anonymousRoutes: AppRoute[] = [
  {
    key: 'login',
    title: 'Login',
    exact: true,
    path: RoutePath.Login,
    accessibilityLevel: 'anonymous',
    component: views.Login,
  },
];
