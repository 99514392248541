/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/core';
import React, { Fragment } from 'react';

import { Category } from 'models';
import { buttons, spacers } from 'styles';

interface Props {
  category?: Category;
  btnActive: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: string | SerializedStyles | SerializedStyles[];
  disabled?: boolean;
}

export const CategoryButton: React.FC<Props> = ({
  category,
  btnActive,
  onClick,
  style,
  disabled,
}) => {
  return (
    <Fragment>
      {disabled ? (
        <span
          css={[
            buttons.ghost.small,
            btnActive && buttons.outlineActive.small,
            spacers.right.small,
            style,
          ]}
        >
          {category && (
            <Fragment>
              <img
                src={resolveIcon(category)}
                alt={category}
                css={[spacers.right.small]}
              />
              <p>{category}</p>
            </Fragment>
          )}
        </span>
      ) : (
        <button
          css={[
            buttons.ghost.small,
            btnActive && buttons.outlineActive.small,
            spacers.right.small,
            style,
          ]}
          data-category={category}
          onClick={onClick}
        >
          {category && (
            <Fragment>
              <img
                src={resolveIcon(category)}
                alt={category}
                css={[spacers.right.small]}
              />
              <p>{category}</p>
            </Fragment>
          )}
        </button>
      )}
    </Fragment>
  );

  function resolveIcon(category: Category) {
    switch (category) {
      case 'Medicinska pomagala':
        return require('assets/images/med_pomagala_vector.svg');

      case 'Namještaj':
        return require('assets/images/namjestaj_vektor.svg');

      case 'Odjeća':
        return require('assets/images/odjeca_vektor.svg');

      case 'Volonteri':
        return require('assets/images/volonteri_vektor.svg');

      default:
        return null;
    }
  }
};
