import { css, SerializedStyles } from '@emotion/core';

import { globalRadial, fontFamily, colors, unit } from './vars.styles';
import { color } from './mixins.styles';

/**
 * Input base
 */
const input = css`
  padding: 0;
  margin: 0;
  border-radius: ${globalRadial + 1}px;

  display: inline-flex;
  width: 100%;

  cursor: pointer;
  white-space: nowrap;

  text-decoration: none;
  font-family: ${fontFamily.sans};

  background: ${colors.bg};
  border: 1px solid ${color(colors.dark, 0.2)};
  color: ${colors.dark};
  font-size: 16px;
  line-height: 24px;

  transition: border-color 0.2s ease-out;

  &::placeholder {
    color: ${color(colors.dark, 0.4)};
  }

  &:hover {
    border-color: ${color(colors.dark, 0.6)};
    cursor: pointer;
  }

  &:focus {
    border-color: ${color(colors.dark, 0.6)};
    outline: none;
  }

  &[disabled] {
    background: ${colors.gray};
    cursor: not-allowed;
  }
`;

const inputNumber = css`
  ${input}

  padding-right: 0;
`;

const inputSelect = css`
  ${input}

  height: ${unit * 7}px;
  line-height: ${unit * 6}px;
`;

const inputSelectMultiple = css`
  ${inputSelect}

  min-height: 160px;
`;

const inputTextarea = css`
  ${input}

  width: 100%;
  height: 160px;
  max-height: 600px;
  resize: vertical;
  padding: ${unit * 4}px;
  white-space: pre-wrap;
  overflow-wrap: normal;
`;

const inputTextareaError = css`
  ${inputTextarea}

  border-color: ${colors.warning};
`;

const inputCheckbox = css`
  ${input}

  position: absolute;
  left: -99999px;
  opacity: 0;

  & + label {
    padding-left: ${unit * 2}px;
    color: ${color('black', 0.7)};

    &:before {
      content: '';
      display: block;
      float: left;
      width: ${unit * 3.5}px;
      height: ${unit * 3.5}px;
      margin-top: ${unit * 2}px;

      transition: border-color 0.2s ease-in-out, background 0.2s ease-in-out;

      border: 1px solid red;
      border-radius: ${globalRadial};
    }
  }

  &:hover,
  &:focus {
    & + label {
      &:before {
        border-color: blue;
        background: red;
      }
    }
  }

  &:checked {
    & + label {
      &:before {
        background: blue;
        border-color: transparent;
      }
    }
  }
`;

const inputRadio = css`
  ${inputCheckbox}

  &+label {
    &:before {
      border-radius: 50%;
    }
  }
`;

const inputError = css`
  ${input}

  border-color: ${colors.warning};
`;

const inputSelectError = css`
  & > div {
    border-color: ${colors.warning};
  }
`;

/**
 * Input sizes
 */
const small = css`
  height: ${unit * 7}px;
  line-height: ${unit * 6}px;
  padding: 0 ${unit * 3}px;
`;

const medium = css`
  height: ${unit * 10}px;
  line-height: ${unit * 10}px;
  padding: 0 ${unit * 4}px;
`;

/**
 * Size helper
 */
const createSizeMap = (style: SerializedStyles) => ({
  small: css(style, small),
  medium: css(style, medium),
});

/**
 * Export all available input styles
 */
export const inputs = {
  input: createSizeMap(input),
  inputNumber: createSizeMap(inputNumber),
  inputSelect: createSizeMap(inputSelect),
  inputSelectMultiple: createSizeMap(inputSelectMultiple),
  inputSelectError,
  inputTextarea,
  inputTextareaError,
  inputCheckbox: createSizeMap(inputCheckbox),
  inputRadio: createSizeMap(inputRadio),
  inputError: createSizeMap(inputError),
};
