/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

import { Header, Footer, RoutePath } from 'modules/navigation';
import { layout } from 'styles';

import { ScrollToTop } from './ScrollToTop';
import 'styles/cookie.css';

export const PublicLayout: React.FC = ({ children }) => (
  <div css={layout.publicWrapper}>
    <Header />
    <main css={layout.main}>{children}</main>
    <Footer />

    <ScrollToTop />

    <CookieConsent
      location="bottom"
      buttonText="Prihvati"
      buttonClasses="cookie__accept"
      flipButtons
      cookieName="Socijalna burza"
      disableStyles={true}
      containerClasses="cookie__wrapper"
    >
      <div>
        <p className="cookie__text">
          Ova web-stranica koristi kolačiće u svrhu unaprjeđenja korisničke
          usluge te korištenjem stranice pristajete na njih. Za više informacija
          o zaštiti podataka pročitajte{' '}
          <Link className="cookie__link" to={RoutePath.PrivacyPolicy}>
            Politiku privatnosti
          </Link>
          .
        </p>
      </div>
    </CookieConsent>
  </div>
);
