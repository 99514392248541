import * as views from 'views';

import { RoutePath } from './RoutePath';

import { AppRoute } from '../models';

export const publicRoutes: AppRoute[] = [
  {
    key: 'homepage',
    title: 'Homepage',
    exact: true,
    path: RoutePath.HomePage,
    accessibilityLevel: 'public',
    component: views.HomePage,
  },
  {
    key: 'supply',
    title: 'Supply',
    exact: true,
    path: RoutePath.Supply,
    accessibilityLevel: 'public',
    component: views.Supply,
  },
  {
    key: 'supply-details',
    title: 'Supply details',
    exact: true,
    path: RoutePath.SupplyDetails,
    accessibilityLevel: 'public',
    component: views.SupplyDetails,
  },
  {
    key: 'demand',
    title: 'Demand',
    exact: true,
    path: RoutePath.Demand,
    accessibilityLevel: 'public',
    component: views.Demand,
  },
  {
    key: 'news',
    title: 'News',
    exact: true,
    path: RoutePath.News,
    accessibilityLevel: 'public',
    component: views.News,
  },
  {
    key: 'news-details',
    title: 'News Details',
    exact: true,
    path: RoutePath.NewsDetails,
    accessibilityLevel: 'public',
    component: views.NewsDetails,
  },
  {
    key: 'announcements',
    title: 'Announcements',
    exact: true,
    path: RoutePath.Announcements,
    accessibilityLevel: 'public',
    component: views.Announcements,
  },
  {
    key: 'announcement-details',
    title: 'Announcement Details',
    exact: true,
    path: RoutePath.AnnouncementDetails,
    accessibilityLevel: 'public',
    component: views.AnnouncementDetails,
  },
  {
    key: 'about',
    title: 'About',
    exact: true,
    path: RoutePath.About,
    accessibilityLevel: 'public',
    component: views.About,
  },
  {
    key: 'contact',
    title: 'Contact',
    exact: true,
    path: RoutePath.Contact,
    accessibilityLevel: 'public',
    component: views.Contact,
  },
  {
    key: 'donate',
    title: 'Donate',
    exact: true,
    path: RoutePath.Donate,
    accessibilityLevel: 'public',
    component: views.Donate,
  },
  {
    key: 'privacy-policy',
    title: 'Privacy policy',
    exact: true,
    path: RoutePath.PrivacyPolicy,
    accessibilityLevel: 'public',
    component: views.PrivacyPolicy,
  },
];
