import { parse } from 'querystring';

import React, { useEffect, Fragment } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { PublicLayout, DashboardLayout } from 'components';
import {
  publicRoutes,
  RoutePath,
  AppRoute,
  authenticatedRoutes,
  anonymousRoutes,
} from 'modules/navigation';
import { Unauthorized, Page404 } from 'views';
import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { AuthThunks } from 'modules/authentication';

import { ACLRoute } from './ACLRoute';

import 'styles/pageTransitions.css';

export const AccessControlList: React.FC = () => {
  const { isLoggedIn } = useSelector((state: ApplicationState) => state.auth);
  const { pathname, key, search } = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(AuthThunks.loginWithPersistedUser());
  }, [dispatch]);

  const { seoredirect } = parse(search.slice(1));
  if (seoredirect) {
    return <Redirect to={seoredirect as string} />;
  }

  return (
    <Fragment>
      <Switch>
        <Route exact path={publicRoutes.map(route => route.path)}>
          <PublicLayout>
            <TransitionGroup component={null}>
              <CSSTransition
                key={key || pathname}
                in={true}
                timeout={200}
                classNames="page"
              >
                <Fragment>
                  {publicRoutes.map((route: AppRoute) => (
                    <ACLRoute
                      {...route}
                      key={route.key}
                      pathname={pathname}
                      isLoggedIn={isLoggedIn}
                    ></ACLRoute>
                  ))}
                </Fragment>
              </CSSTransition>
            </TransitionGroup>
          </PublicLayout>
        </Route>

        <Route exact path={authenticatedRoutes.map(route => route.path)}>
          <DashboardLayout>
            {authenticatedRoutes.map((route: AppRoute) => (
              <ACLRoute
                {...route}
                key={route.key}
                pathname={pathname}
                isLoggedIn={isLoggedIn}
              ></ACLRoute>
            ))}
          </DashboardLayout>
        </Route>

        {anonymousRoutes.map((route: AppRoute) => (
          <ACLRoute
            {...route}
            key={route.key}
            pathname={pathname}
            isLoggedIn={isLoggedIn}
          />
        ))}

        <Route path={RoutePath.Unauthorized}>
          <Unauthorized />
        </Route>

        <Route path={RoutePath.Dashboard}>
          <Redirect to={RoutePath.DashboardSupply} />
        </Route>

        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </Fragment>
  );
};
