/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';

import { NewInSupply } from 'modules/donations';
import { HomepageNews } from 'modules/news';
import { HomePageAnnouncements } from 'modules/announcements';
import { HomeHero, HomeFund, AboutProject, Helmet } from 'components';

export const HomePage: React.FC = () => {
  return (
    <section>
      <Helmet
        title="Socijalna burza - Naslovna"
        description="Učini dobro djelo i pomozi drugima svojom donacijom ili volontiranjem."
      />

      <HomeHero />

      <HomeFund />

      <SkeletonTheme color="#FFF4E0" highlightColor="#FFFBF2">
        <NewInSupply />

        <HomepageNews />

        <HomePageAnnouncements />
      </SkeletonTheme>

      <AboutProject />
    </section>
  );
};
