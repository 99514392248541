import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { Request } from 'models';
import { FirestoreListenerActions } from 'modules/firebase';

import { RequestsActions } from './actions';

import { RequestsService } from '../services';

export const getAllRequests = () => (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const requestsListener = getState().listeners.requestsListener;
  if (requestsListener) {
    return;
  }

  dispatch(RequestsActions.addStateChanging());

  const successFunction = (data: Request[]) => {
    dispatch(
      RequestsActions.addRequests(
        data.sort((a, b) => +b.createdAt - +a.createdAt),
      ),
    );
  };

  const errorFunction = (error: string) =>
    dispatch(RequestsActions.addError(error));

  const listener = RequestsService.Database.filterAndListen(
    successFunction,
    errorFunction,
  );
  dispatch(FirestoreListenerActions.SetListener('requestsListener', listener));

  return;
};

export const saveRequest = (data: Request) => async () => {
  const response = await RequestsService.Database.add(data);
  if (typeof response === 'string') {
    return response;
  }

  return;
};

export const deleteRequest = (id: string) => () => {
  RequestsService.Database.remove(id);
};

export const RequestsThunks = {
  getAllRequests,
  saveRequest,
  deleteRequest,
};
