import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Global } from '@emotion/core';

import { configureStore } from 'modules/redux-store';
import { AccessControlList } from 'modules/authorization';
import { globalStyles } from 'styles/globals.styles';

const App: React.FC = () => {
  const store = configureStore();

  return (
    <Provider store={store}>
      <Global styles={globalStyles} />
      <BrowserRouter>
        <AccessControlList />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
