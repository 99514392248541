/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Image from 'react-shimmer';

import { News } from 'models';
import { home, buttons } from 'styles';
import { RoutePath } from 'modules/navigation';
import { getHeroImage, slugify } from 'utils';

interface Props {
  item?: News;
  isLoading: boolean;
}

export const HomeNewsHero: React.FC<Props> = ({ item, isLoading }) => {
  if (item && !isLoading) {
    return (
      <div css={home.newsHeroWrapper}>
        <div css={home.newsHeroImg}>
          <Image
            src={getHeroImage(item.photoUrls, item.heroImageIndex)}
            fallback={<div />}
            errorFallback={() => (
              <img
                src={require('assets/images/image-placeholder.png')}
                alt="placeholder"
              />
            )}
          />
        </div>

        <div css={home.newsHeroInfo}>
          <p css={home.newsItemDate}>
            {format(
              (item.dateOfPublishing as firebase.firestore.Timestamp).toDate(),
              'dd.LL.yyyy',
            )}
          </p>
          <p css={home.newsItemTitle}>{item.title}</p>
          <p css={home.newsItemDescription}>{item.description}</p>

          <Link
            to={`${RoutePath.News}/${slugify(item.title)}/${item.id}`}
            css={buttons.primary.large}
          >
            Pročitaj više
          </Link>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div css={home.newsHeroWrapper}>
        <Skeleton />

        <div css={home.newsHeroInfo}>
          <p css={home.newsItemDate}>
            <Skeleton height="20px" />
          </p>

          <p css={home.newsItemTitle}>
            <Skeleton height="28px" />
          </p>

          <p css={home.newsItemDescription}>
            <Skeleton height="60px" />
          </p>

          <Skeleton height="48px" width="188px" />
        </div>
      </div>
    );
  }

  return null;
};
