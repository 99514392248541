import { css } from '@emotion/core';

import { colors, globalRadial, unit } from './vars.styles';
import { color } from './mixins.styles';
import { keyFrames } from './kf.styles';

const overlay = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.tertiary};
  opacity: 0.5;
`;

const wrapper = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`;

const container = css`
  z-index: 8;
  background: white;
  width: 100%;
  max-width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 5px ${color(colors.dark, 0.2)};
  transition: transform 0.2s, opacity 0.2s;
  border-radius: ${globalRadial * 2}px;
  animation: ${keyFrames.modalShowUp} 0.4s ease-out;
`;

const header = css`
  display: flex;
  justify-content: flex-end;
  padding: ${unit * 8}px;
`;

const footer = css`
  padding: ${unit * 8}px;
  padding-top: 0;
`;

const content = css`
  ${footer}
`;

const close = css`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
  outline: none;
`;

export const modal = {
  overlay,
  wrapper,
  container,
  header,
  footer,
  content,
  close,
};
