import React from 'react';

interface Props {
  title: string;
  data?: any[];
  isLoading?: boolean;
}

export const EmptyListState: React.FC<Props> = ({ title, data, isLoading }) =>
  data && data.length < 1 && !isLoading ? <h5>{title}</h5> : null;
