/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useRef } from 'react';
import { FormContext } from 'react-hook-form';

import { Donation } from 'models';
import {
  ValidatedInput,
  ValidatedSelect,
  FormSubmit,
  FormCheckbox,
  FormHeader,
  CompletedFormMessage,
  Dropzone,
  Helmet,
} from 'components';
import { CategoryOptions, EmailRegExp, PhoneRegExp } from 'consts';
import { form, wrappers, content, donate } from 'styles';
import { useFormLogic } from 'hooks';
import { DonationsService } from 'modules/donations';

export const Donate: React.FC = () => {
  const alertEl = useRef<HTMLDivElement>(null);
  const {
    methods,
    saveDonation,
    formIsLoading,
    formCompleted,
    formError,
    handleReturnToForm,
    uploadDonatinImages,
    removeImagesFromDonations,
    handleDropzoneThumbnailClick,
  } = useFormLogic<Donation>(undefined, undefined, alertEl.current);

  useEffect(() => {
    methods.register('id');

    if (!methods.getValues().id) {
      methods.setValue('id', DonationsService.Database.generateId());
    }
  }, [methods]);

  return (
    <section css={[wrappers.normal, content.padding]}>
      <Helmet
        title="Socijalna burza - Doniraj/volontiraj"
        description="Učini dobro djelo i ispuni obrazac kako bi donirao ili se prijavio za volontiranje."
      />

      <FormHeader title="Doniraj" />

      {!formCompleted && (
        <FormContext {...methods}>
          <form
            css={donate.wrapper}
            onSubmit={methods.handleSubmit(saveDonation)}
          >
            <div css={donate.dropzoneInfoWrapper}>
              <Dropzone
                name="photoUrls"
                firebasePhotoUrls={[]}
                formIsLoading={formIsLoading}
                uplaodImagesToStorageAndGetUrls={uploadDonatinImages}
                removeImagesFromStorage={removeImagesFromDonations}
                handleDropzoneThumbnailClick={handleDropzoneThumbnailClick}
                wrapperCss={donate.dropzone}
              />

              <div css={donate.info}>
                <h3>Kontaktirajte nas</h3>
                <h5>Socijalna burza</h5>
                <p>prijatelji.sv.martina@gmail.com</p>
                <p>+385 97 698 2901</p>

                <p>
                  Donacije možete prijaviti putem obrasca ili putem navedenih
                  kontakt podataka.
                </p>
              </div>
            </div>

            <div css={donate.formWrapper}>
              <div css={form.flexFields}>
                <ValidatedInput
                  label="Ime"
                  placeholder="Vaše ime"
                  name="firstName"
                  validationObject={{ required: true }}
                />

                <ValidatedInput
                  label="Prezime"
                  placeholder="Vaše prezime"
                  name="lastName"
                  validationObject={{ required: true }}
                />
              </div>

              <div css={form.flexFields}>
                <ValidatedInput
                  label="E-mail"
                  placeholder="Vaša e-mail adresa"
                  errMsg="Krivi format e-mail adrese"
                  name="email"
                  validationObject={{ pattern: EmailRegExp }}
                />

                <ValidatedInput
                  label="Broj telefona"
                  placeholder="Vaš broj telefona"
                  name="phone"
                  errMsg="Krivi format telefonskog broja"
                  validationObject={{ required: true, pattern: PhoneRegExp }}
                />
              </div>

              <ValidatedInput
                label="Naziv donacije"
                placeholder="Unesite naziv donacije koju želite donirati"
                name="name"
                errMsg="Naziv donacije je predugačak (max. 60 znakova)"
                validationObject={{ required: true, maxLength: 60 }}
              />

              <ValidatedSelect
                name="category"
                label="Kategorija"
                placeholder="Izaberite kategoriju"
                options={CategoryOptions}
                required
              />

              <ValidatedInput
                textarea
                label="Opis donacije"
                placeholder="Unesite opis donacije"
                name="description"
                errMsg="Opis donacije je predugačak (max. 2000 znakova)"
                validationObject={{ required: true, maxLength: 2000 }}
              />

              <FormCheckbox name="policyAccepted" required />

              <FormSubmit value="Doniraj" stateIsChanging={formIsLoading} />
            </div>
          </form>
        </FormContext>
      )}

      {formCompleted && (
        <CompletedFormMessage
          title="Hvala Vam na donaciji i pomoći koju pružate zajednici. Nakon što
        pregledamo i odobrimo Vašu donaciju, kontaktirat ćemo Vas kako bi se
        dogovorili o ostatku procedure."
          btnText="Doniraj opet"
          handleReturn={handleReturnToForm}
          formError={formError}
          ref={alertEl}
        />
      )}
    </section>
  );
};
