/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Image from 'react-shimmer';

import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { RoutePath } from 'modules/navigation';
import { FormError, EmptyListState } from 'components';
import { supply } from 'styles/supply.styles';
import { wrappers, home } from 'styles';
import { slugify } from 'utils';

import { DonationsThunks } from '../redux';

export const NewInSupply: React.FC = () => {
  const { donations, donationsAreChanging, error } = useSelector(
    (state: ApplicationState) => state.donations,
  );

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(DonationsThunks.getPublishedDonations());
  });

  return (
    <div css={[wrappers.normal, home.supplyWrapper]}>
      <div css={home.supplyTitle}>
        <h3>Novo u ponudi</h3>

        {!donationsAreChanging && (
          <Link css={home.supplyLinkTop} to={RoutePath.Supply}>
            Pogledaj sve
          </Link>
        )}
      </div>

      <FormError error={error} />

      <EmptyListState
        title="Trenutno nema donacija u ponudi"
        data={donations}
        isLoading={donationsAreChanging}
      />

      <ul css={home.supplyUl}>
        {donationsAreChanging &&
          [0, 1, 2, 3].map((_, i) => (
            <div key={i} css={home.supplySkeleton}>
              <Skeleton />
              <Skeleton />
            </div>
          ))}

        {!donationsAreChanging &&
          donations.slice(0, 4).map(donation => (
            <Link
              key={donation.id}
              to={`${RoutePath.Supply}/${slugify(donation.name)}/${
                donation.id
              }`}
            >
              <div css={home.supplyLi}>
                <Image
                  src={
                    donation.photoUrls[0] ||
                    require('assets/images/image-placeholder.png')
                  }
                  fallback={<div />}
                  errorFallback={() => (
                    <img
                      src={require('assets/images/image-placeholder.png')}
                      alt={donation.name}
                    />
                  )}
                />
                <p>{donation.name}</p>
                <span css={supply[donation.category.split(' ')[0]]} />
              </div>
            </Link>
          ))}
      </ul>

      {!donationsAreChanging && (
        <Link css={home.supplyLinkBottom} to={RoutePath.Supply}>
          Pogledaj sve
        </Link>
      )}
    </div>
  );
};
