export type Category =
  | 'Medicinska pomagala'
  | 'Namještaj'
  | 'Odjeća'
  | 'Volonteri';

export function isCategory(category: string): category is Category {
  return (
    category === 'Medicinska pomagala' ||
    category === 'Namještaj' ||
    category === 'Odjeća' ||
    category === 'Volonteri'
  );
}
