/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { pagination, buttons, spacers } from 'styles';

interface Props {
  currentPage: number;
  maxPages: number;
  previous: VoidFunction;
  next: VoidFunction;
  selectPage: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

export const Pagination: React.FC<Props> = ({
  currentPage,
  maxPages,
  previous,
  next,
  selectPage,
}) => {
  return (
    <div css={pagination.wrapper}>
      {currentPage !== 1 && (
        <button
          css={[buttons.ghost.small, spacers.right.xLarge]}
          onClick={previous}
        >
          Prethodna stranica
        </button>
      )}
      {Array(maxPages)
        .fill(1)
        .map((_, i) => (
          <span
            key={i + 1}
            data-page={i + 1}
            onClick={selectPage}
            css={i + 1 === currentPage && pagination.currentPage}
          >
            {i + 1}
          </span>
        ))}
      {maxPages > 1 && (
        <button css={buttons.ghost.small} onClick={next}>
          Iduća stranica
        </button>
      )}
    </div>
  );
};
