/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { buttons, list, spacers } from 'styles';
import { useModal } from 'hooks';

import { Modal } from './Modal';

interface Props {
  data: Array<{
    id: string;
    title?: string;
    name?: string;
    firstName?: string;
    email?: string;
    lastName?: string;
    published?: boolean;
  }>;
  path: string;
  handleDelete: (id?: string) => void;
}

export const DashboardList: React.FC<Props> = ({
  data,
  path,
  handleDelete,
}) => {
  const { isShowing, toggle, entityId, entityTitle } = useModal();

  return (
    <ul>
      {data.map(item => (
        <li key={item.id} css={list.item}>
          <div css={list.itemContent}>
            <Link to={`${path}/${item.id}`}>
              <h3>
                <strong>
                  {item.title ||
                    item.name ||
                    `${item.firstName} ${item.lastName}`}
                </strong>
              </h3>
              {typeof item.published !== 'undefined' && (
                <p>{item.published ? 'Objavljeno' : 'Nije objavljeno'}</p>
              )}
              {item.email && <p>{item.email}</p>}
            </Link>
          </div>

          <div>
            <div>
              <Link
                css={[buttons.secondary.small, spacers.right.large]}
                to={`${path}/${item.id}`}
              >
                Detalji
              </Link>

              <button
                css={buttons.container}
                onClick={toggle}
                data-id={item.id}
                data-title={
                  item.title ||
                  item.name ||
                  `zahtjev od ${item.firstName} ${item.lastName}`
                }
              >
                <MdDelete size={16} />
              </button>
            </div>
          </div>
        </li>
      ))}

      <Modal
        isShowing={isShowing}
        hide={toggle}
        onConfirm={handleDelete}
        entityId={entityId}
        entityTitle={entityTitle}
      />
    </ul>
  );
};
