import { createAction, ActionUnion } from 'modules/redux-store';
import { Reservation } from 'models';

import { ReservationsTypes } from './types';

const AddActions = {
  addStateChanging: () => createAction(ReservationsTypes.AddStateChanging),
  addReservations: (reservations: Reservation[]) =>
    createAction(ReservationsTypes.AddReservations, { reservations }),
  addError: (error: string) =>
    createAction(ReservationsTypes.AddError, { error }),
};

export const ReservationsActions = {
  ...AddActions,
};

export type ReservationsActions = ActionUnion<typeof ReservationsActions>;
