/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { ErrorMessage, useFormContext } from 'react-hook-form';

import { fields, inputs } from 'styles';

interface Props {
  name: string;
  label: string;
  sublabel?: string;
  type?: string;
  placeholder?: string;
  defaultValue?: string;
  checked?: boolean;
  inputClassName?: string;
  errMsg?: string;
  disabled?: boolean;
  textarea?: boolean;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  pattern?: string;
  validationObject?: {
    required?: boolean;
    maxLength?: number;
    minLength?: number;
    max?: number;
    min?: number;
    pattern?: RegExp;
    validate?: any;
  };
}

export const ValidatedInput: React.FC<Props> = ({
  name,
  type,
  defaultValue,
  checked,
  placeholder,
  label,
  sublabel,
  inputClassName,
  errMsg,
  textarea,
  inputMode,
  pattern,
  disabled,
  validationObject,
}) => {
  const { register, errors } = useFormContext();

  return (
    <div css={fields.field}>
      {type !== 'checkbox' && (
        <label htmlFor={name} css={fields.label}>
          {label}
        </label>
      )}
      {!textarea && (
        <input
          type={type || 'text'}
          name={name}
          id={name}
          defaultChecked={checked}
          placeholder={placeholder}
          defaultValue={defaultValue || ''}
          ref={validationObject ? register(validationObject) : register}
          css={[
            inputClassName || inputs.input.medium,
            errors && errors[name] && inputs.inputError.medium,
          ]}
          inputMode={inputMode}
          pattern={pattern}
          disabled={disabled}
        />
      )}

      {textarea && (
        <textarea
          name={name}
          id={name}
          placeholder={placeholder}
          ref={validationObject ? register(validationObject) : register}
          css={[
            inputClassName || inputs.inputTextarea,
            errors && errors[name] && inputs.inputTextareaError,
          ]}
          disabled={disabled}
          defaultValue={defaultValue}
        />
      )}
      {type === 'checkbox' && (
        <label htmlFor={name} css={fields.label}>
          {label}
        </label>
      )}

      {sublabel && <p css={fields.label}>{sublabel}</p>}

      <ErrorMessage errors={errors} name={name}>
        {({ messages }) =>
          messages &&
          Object.entries(messages).map(([type]) => (
            <p css={fields.errorMsg} key={type}>
              {type === 'required' ? 'Obavezno polje' : errMsg}
            </p>
          ))
        }
      </ErrorMessage>
    </div>
  );
};
