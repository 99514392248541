import { useState, useCallback, useEffect } from 'react';

export const usePagination = <T extends any>(
  data: T[],
  itemsPerPage: number,
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPages = Math.ceil(data.length / itemsPerPage);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [currentPage]);

  const next = useCallback(() => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, maxPages));
  }, [maxPages]);

  const previous = useCallback(() => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  }, []);

  const selectPage = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      const { page } = event.currentTarget.dataset;
      if (!page) return;

      const pageNumber = Math.max(1, parseInt(page));
      setCurrentPage(Math.min(pageNumber, maxPages));
    },
    [maxPages],
  );

  const selectedData = useCallback(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    return data.slice(start, end);
  }, [currentPage, data, itemsPerPage]);

  return { currentPage, maxPages, next, previous, selectPage, selectedData };
};
