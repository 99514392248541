import firebase from 'firebase/app';

import { Profile } from './Profile';

export interface Contact extends Profile {
  id: string;
  title: string;
  message: string;
  createdAt: firebase.firestore.FieldValue;
}

export class Contact extends Profile {
  constructor(object?: Partial<Contact>) {
    super(object);

    if (object?.id) {
      this.id = object.id;
    }

    this.title = object?.title || '';
    this.message = object?.message || '';
    this.createdAt =
      object?.createdAt || firebase.firestore.FieldValue.serverTimestamp();
  }
}
