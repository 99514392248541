/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/core';
import React, { Fragment, useState, useEffect } from 'react';
import Image from 'react-shimmer';

import { dropzone, buttons, spacers } from 'styles';

interface Props {
  photos: string[];
  wrapperCss?: SerializedStyles | SerializedStyles[];
  mainPhotoWrapperCss?: SerializedStyles | SerializedStyles[];
  heroImageIndex?: number;
}

export const Gallery: React.FC<Props> = ({
  photos,
  wrapperCss,
  mainPhotoWrapperCss,
  heroImageIndex = 0,
}) => {
  const [currentHeroImageIndex, setCurrentHeroImageIndex] = useState(
    heroImageIndex,
  );
  const [state, setState] = useState(photos);

  useEffect(() => {
    setState(photos);
  }, [photos]);

  return (
    <div css={wrapperCss}>
      <div css={mainPhotoWrapperCss || dropzone.container}>
        <Image
          src={
            state[currentHeroImageIndex] ||
            require('assets/images/image-placeholder.png')
          }
          fallback={<div css={mainPhotoWrapperCss || dropzone.mainImg} />}
          errorFallback={() => (
            <img
              src={require('assets/images/image-placeholder.png')}
              alt="placeholder"
            />
          )}
        />
      </div>

      <div css={dropzone.thumbnailsWrapper}>
        {state.length > 1 &&
          state.map((photo, index) => (
            <Fragment key={index}>
              <button
                type="button"
                data-index={index.toString()}
                css={[
                  buttons.container,
                  dropzone.imgWrapper,
                  spacers.right.medium,
                ]}
                onClick={() => setCurrentHeroImageIndex(index)}
              >
                <div css={[dropzone.thumbnail]}>
                  <Image
                    src={photo}
                    fallback={<div css={[dropzone.thumbnail]} />}
                    errorFallback={() => (
                      <img
                        src={require('assets/images/image-placeholder.png')}
                        alt="placeholder"
                      />
                    )}
                  />
                </div>
              </button>
            </Fragment>
          ))}
      </div>
    </div>
  );
};
