/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { content } from 'styles';
import {
  PageHeader,
  Loading,
  FormError,
  EmptyListState,
  DashboardList,
  Pagination,
} from 'components';
import { RoutePath } from 'modules/navigation';
import { SearchActions } from 'modules/search';
import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { usePagination } from 'hooks';
import { Contact } from 'models';
import { ContactsThunks, ContactsSelectors } from 'modules/contacts/redux';

export const DashboardContacts: React.FC = () => {
  const { contacts, contactsAreChanging, error } = useSelector(
    (state: ApplicationState) => state.contacts,
  );
  const filteredContacts = useSelector((state: ApplicationState) =>
    ContactsSelectors.getContactsByQuery(state),
  );
  const dispatch = useDispatch<AppDispatch>();
  const {
    currentPage,
    maxPages,
    next,
    previous,
    selectPage,
    selectedData,
  } = usePagination<Contact>(filteredContacts, 16);

  useEffect(() => {
    dispatch(ContactsThunks.getAllContacts());
  }, [dispatch]);

  return (
    <section css={content.area}>
      <PageHeader onSearch={handleSearch} title="Kontakti" />

      <Loading isLoading={contactsAreChanging}>
        <FormError error={error} />
        <EmptyListState data={contacts} title="Trenutno nema kontakata" />
        {contacts.length > 0 && (
          <EmptyListState
            data={selectedData()}
            title="Nema rezultata za navedene filtre"
          />
        )}

        <DashboardList
          data={selectedData()}
          path={RoutePath.DashboardContacts}
          handleDelete={handleDelete}
        />

        <Pagination
          currentPage={currentPage}
          maxPages={maxPages}
          previous={previous}
          next={next}
          selectPage={selectPage}
        />
      </Loading>
    </section>
  );

  function handleSearch(query: string) {
    dispatch(SearchActions.querySearch(query));
  }

  function handleDelete(id?: string) {
    if (!id) return;

    dispatch(ContactsThunks.deleteContact(id));
  }
};
